import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import PLANNING_TYPES from '@fielded/fs-api/lib/shipment/tools/planning-types'

import { Button, ExpandableOptions, IconBadge, StatusCard, IndicatorColor } from '@fielded/shared-ui'
import { Arrow, MinusCircle, PayAsYouSell, PayOnDelivery } from '@fielded/shared-ui/src/icons'

import { formatDate } from '../../../van-shared/utils'
import { hasFeature } from '../../../van-shared/utils/features'

import { withApi } from '../../../common/ApiProvider'
import { ShipmentHeaderDate } from './ShipmentHeaderDate'

import ShipmentProductLines from './ShipmentDetailsProductLine'
import ShipmentRescheduleOption from './ShipmentRescheduleOption'

import {
  isShelflifePowered,
  shipmentTitle
} from '../common/utils'

import ShipmentStatusLabel from '../common/ShipmentStatusLabel'
import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'
import get from 'lodash/get'
const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL } = DIRECT_ORDER_TYPES

class Shipment extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      programName: null,
      shipment: props.shipment
    }
  }

  async componentDidMount () {
    const { shipment, api } = this.props
    if (shipment.programId) {
      const { name } = await api.program.get(shipment.programId)
      this.setState({ programName: name })
    }
  }

  onUpdateShipment = (newShipment) => {
    this.setState({
      shipment: newShipment
    })
  }

  render () {
    const { programName, shipment } = this.state
    if (!shipment) return null

    const {
      api,
      config,
      editPSMShipment,
      showRemoveShipmentLink,
      displayPlanningType,
      funder,
      isReturn,
      isCompleted,
      history
    } = this.props

    const {
      date,
      snapshotId,
      shipmentNo,
      products,
      driverName,
      routeId,
      funderId,
      patientName,
      patientId
    } = shipment

    const formattedDate = shipment.planningType === PLANNING_TYPES.PATIENT
      ? formatDate(date, 'shortWithTime')
      : formatDate(date, 'long')

    const productCount = products.length
    const checked = (Object.values(shipment.counts) || []).filter(product => product.checked)

    const percentage = (checked.length * 100) / productCount

    const showAdjustments = hasFeature(config, 'features.shipments.adjustments') && shipment.status === 'received' && shipment.changes.length > 0
    const listProductQuantities = hasFeature(config, 'features.shipments.listProductQuantities')
    const canFilterPackedShipment = hasFeature(config, 'features.shipments.allowFilterPackedShipments')
    const isShelflife = isShelflifePowered(config)
    const showDeliveryDetails = isShelflife // These items are not available in a PSM context and not interesting (we don't receive that from ONE)
    const countsList = Object.values(shipment.counts)
    const market = get(shipment, 'destination.state')

    let withODProducts, withPAYSProducts
    // If shipment has counts object, we want to see if any shipped products are with payment type OD or PAYS to show the icon.
    if (isShelflife && countsList.length > 0) {
      withODProducts = countsList.some((product) => product.paymentType === PAY_ON_DELIVERY)

      withPAYSProducts = countsList.some((product) => product.paymentType === PAY_AS_YOU_SELL)
    }

    let target = null
    if (!editPSMShipment && shipment.planningType === PLANNING_TYPES.C_R) {
      target = `/shipments/update-cr-quantities/${snapshotId}`
    } else if (!editPSMShipment) {
      target = `/shipments/pick-list/${snapshotId}`
    }

    if (shipment.linkNotAvailable) {
      target = null
    }

    return (
      <StatusCard
        className='shipment'
        target={target}
        history={history}
      >
        <ShipmentStatusLabel shipment={shipment} isReturn={isReturn} isCompleted={isCompleted} />
        <StatusCard.Header
          headerTitle={shipmentTitle(shipment, config, isReturn)}
          headerDate={
            <ShipmentHeaderDate
              formattedDate={formattedDate}
              previousScheduledDates={shipment.previousScheduledDates}
            />
          }
        />

        <StatusCard.Content
          contentLabel='Products:'
          contentText={
            <Fragment>
              {`${productCount} ${productCount !== 1 ? 'products' : 'product'}`}
              {withPAYSProducts && (
                <IconBadge
                  colorCode={PAY_AS_YOU_SELL}
                  icon={<PayAsYouSell />}
                  className='shipment__subs-icon'
                />
              )}
              {withODProducts && (
                <IconBadge
                  colorCode={PAY_ON_DELIVERY}
                  icon={<PayOnDelivery />}
                  className='shipment__subs-icon'
                />
              )}
            </Fragment>
          }
        />
        <StatusCard.Content>
          <div className='shipment__details'>
            <div>
              {programName && (
                <span className='shipment__meta'>
                  Program: {programName}
                </span>
              )}
              {displayPlanningType && shipment.planningType && (
                <span className='shipment__meta'>
                  Type: {shipment.planningType}
                </span>
              )}
              {shipmentNo != null && (
                <span className='shipment__meta'>ID: {shipmentNo}</span>
              )}
              {market && (
                <span className='shipment__meta'>
                  Market: {market}
                </span>)}
              {funder && (
                <span className='shipment__meta'>
                  Funder: {funder.name}
                </span>
              )}
              {showDeliveryDetails && <span className='shipment__meta'>Route: {routeId || funderId || 'N/A'}</span>}
              {showDeliveryDetails && driverName && <span className='shipment__meta'>FP: {driverName}</span>}
              {showDeliveryDetails && patientName && <span className='shipment__meta'>Patient name: {patientName}</span>}
              {showDeliveryDetails && patientId && <span className='shipment__meta'>Patient Id: {patientId}</span>}
              {showAdjustments &&
                <span className='shipment__meta'>Adjusted on {formatDate(shipment.changes[shipment.changes.length - 1].updatedAt, 'short')}</span>}
            </div>
            {listProductQuantities && <ExpandableOptions
              label='Product details'
              expandableChildren={<ShipmentProductLines products={products} counts={shipment.counts} />}
              expandWrapperClassName={'shipment__card-product-list'}
            />
            }
          </div>
        </StatusCard.Content>
        {canFilterPackedShipment && <StatusCard.Content
          contentLabel={`Progress: ${percentage ? Math.round(percentage) : 0}%`}
          contentText={
            <div
              className='shipment__meta'
              style={{
                height: '20px'
              }}
            >
              <IndicatorColor
                colorCode='ok'
                fillWidth={percentage || 0.0001} // indicator color accepting > 0 value so if it's 0 it will draw 100%
              />
            </div>
          }
        />}
        <StatusCard.Footer>
          {showRemoveShipmentLink &&
            <Fragment>
              <ShipmentRescheduleOption
                config={config}
                api={api}
                shipment={shipment}
                onUpdateShipment={this.onUpdateShipment}
              />
              <div className='vs-u-row-spaced vs-u-align-center'>
                <Button
                  component={Link}
                  to={`/shipments/pick-list/${snapshotId}/shipment-options/delete-shipment?backToOverview=true`}
                  colorVariant='danger'
                  fill='outline'
                  icon={<MinusCircle />}
                >
                  Delete
                </Button>
                {editPSMShipment &&
                  <Button
                    component={Link}
                    to={`/shipments/${snapshotId}/edit`}
                    colorVariant='brand'
                    icon={<Arrow direction='right' />}
                    iconSide='right'
                  >
                    Edit Shipment
                  </Button>
                }
              </div>
            </Fragment>
          }
        </StatusCard.Footer>
      </StatusCard>
    )
  }
}

Shipment.propTypes = {
  shipment: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  editShipments: PropTypes.bool,
  showRemoveShipmentLink: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isReturn: PropTypes.bool
}

export default withApi(Shipment)
