import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import keyBy from 'lodash/keyBy'
import flowRight from 'lodash/flowRight'

import { Edit } from '@fielded/shared-ui/src/icons'
import { getShipmentBatches } from '@fielded/fs-api/lib/shipment/utils/utils'

import { updateShipment } from '../shipments/shipments-reducer'
import { loadPickList } from '../pick-list/pick-list-reducer'
import { selectHasReceivedMasterData, selectProducts, fetchMasterData } from '../root/master-data-reducer'
import withConfig from '../../../van-shared/hoc/withConfig'
import withShipment from './../common/WithShipmentWrapper'
import { withApi } from './../../../common/ApiProvider'
import { withUser } from '../../../common/AuthenticationProvider'
import { displayWarningToast } from './utils'

import Count from '../count/Count'
import { shipmentTitle } from '../common/utils'

const AdjustmentContainer = ({
  reloadShipment,
  shipment,
  history,
  config,
  api,
  user,
  match
}) => {
  const dispatch = useDispatch()
  const hasReceivedMasterData = useSelector(selectHasReceivedMasterData)
  const products = useSelector(selectProducts)
  const [batches, setBatches] = useState([])
  const [updatedProductsById, setUpdatedProductsById] = useState({})

  const initialize = async () => {
    if (!hasReceivedMasterData) {
      await dispatch(fetchMasterData())
    }

    const shipmentIds = Object.keys(shipment.counts)
      .map(p => p.split(':manufacturer')[0])
      .filter(id => !products.allIds.includes(id))

    let processedProducts = { ...products }

    let missingProducts = []
    if (shipmentIds.length) {
      try {
        missingProducts = await api.product.getByIds(shipmentIds)
        if (missingProducts && missingProducts.length !== shipmentIds.length) {
          missingProducts = await api.product.getProductsViaIds(shipmentIds)
        }
      } catch (error) {
        displayWarningToast(error)
      }

      const productsByIds = keyBy(missingProducts, '_id')
      processedProducts = {
        ...processedProducts,
        allIds: [...processedProducts.allIds, ...shipmentIds],
        byId: {...processedProducts.byId, ...productsByIds}
      }
    }

    const batches = getShipmentBatches(shipment, processedProducts.byId)

    await dispatch(loadPickList(shipment, processedProducts, user))

    setBatches(batches)
    setUpdatedProductsById(processedProducts.byId)
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    const batches = getShipmentBatches(shipment, updatedProductsById)

    setBatches(batches)
  }, [shipment.counts])

  const handleCountUpdate = async (batchId, inputQuantity, checked, paymentType) => {
    const { snapshotId } = match.params
    const counts = {
      [batchId]: {
        checked,
        quantity: inputQuantity,
        paymentType
      }
    }

    await api.shipment.adjustment.draft(snapshotId, counts)

    // this is a prop from withShipment wrapper
    const updatedShipment = await reloadShipment(snapshotId)

    // the confirmation container still looks at `store.shipments`,
    // so this is to keep the shipment up to date
    // there as well for whichever point the user advances
    await dispatch(updateShipment(updatedShipment))
  }

  const handleProceed = async () => {
    // load picklist for confirmation screen from saved batches
    await dispatch(loadPickList(shipment, products))
    history.push(`/shipments/confirmation/${shipment.snapshotId}`)
  }

  return (
    <Count
      batches={batches}
      shipment={shipment}
      config={config}
      api={api}
      user={user}
      hasAdjustment
      title={[<span>{shipmentTitle(shipment, config)}</span>, <div className='shipment-header__label'><Edit width='13' height='17' fill='#4D2999' />Currently Editing</div>]}
      onProductUpdate={handleCountUpdate}
      onClickProceed={handleProceed}
    />
  )
}

const withHOCs = flowRight(
  withUser,
  withApi,
  withConfig
)

export default withHOCs(
  withShipment(
    AdjustmentContainer,
    { pendingAdjustments: true }
  )
)
