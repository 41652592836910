import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'

import { BackButton, Loading, PrintTemplate } from '@fielded/shared-ui'
import { triggerPrint } from '@fielded/shared-ui/src/components/Page'

import { withApi } from '../../../../../common/ApiProvider'
import withConfig from '../../../../../van-shared/hoc/withConfig'

import ShipmentContentsTablePSM from '../../../components/ShipmentContentsTablePSM'
import { getPlannedQuantities, makeDeliveryItems } from '../../../common/makeDeliveryItems'
import { batchIdToProductId } from '../../../common/utils'
import ShipmentValueSummary from '../ShipmentValueSummary'
import NoteHeading from '../NoteHeading'

const PSMPackageNote = ({
  api,
  match,
  config
}) => {
  const history = useHistory()
  const { snapshotId } = match.params
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [date, setDate] = useState('')
  const [delivery, setDelivery] = useState({})

  const initialize = async () => {
    try {
      const delivery = await api.shipment.findById(snapshotId)
      const productIds = Object.keys(delivery.counts).map(batchId => batchIdToProductId(batchId))
      const products = await api.product.getByIds(productIds)
      const productsById = keyBy(products, '_id')

      const plannedQuantities = getPlannedQuantities(delivery)
      const items = makeDeliveryItems({ shipment: delivery, productsById, plannedQuantities }) || []
      const date = get(delivery, 'snapshotDates.sent', delivery.date)

      setDelivery(delivery)
      setItems(items)
      setDate(date)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    if (!loading) {
      const printableDocumentName = `Package note - ${date}`
      triggerPrint(printableDocumentName)
    }
  }, [loading])

  if (loading) {
    return <Loading loadingText='Loading package note…' />
  }

  return (
    <div className='print page'>
      <header className='header--back'>
        <BackButton history={history}>go back</BackButton>
      </header>

      <PrintTemplate>
        <PrintTemplate.Section>
          <div className='delivery-note-print'>
            <div className='delivery-note-print__header'>
              <div className='delivery-note-print__heading'>
                <NoteHeading shipment={delivery} />
              </div>
              <div className='delivery-note-print__summary'>
                <ShipmentValueSummary
                  title={`Destination: ${delivery.destination.sdp || delivery.destination.id}`}
                  label='SKU packed'
                  total={items.length}
                />
              </div>

            </div>
            <section className='vs-u-margin-top-tripple'>
              <ShipmentContentsTablePSM
                items={items}
                type='package'
              />
            </section>
          </div>
        </PrintTemplate.Section>
      </PrintTemplate>
    </div>
  )
}

export default withConfig(withApi(PSMPackageNote))
