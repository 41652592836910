import React, { Component } from 'react'
import get from 'lodash/get'

import { PrintTemplate, SignatureDisplay } from '@fielded/shared-ui'

import { getPlannedQuantities, makeDeliveryItems } from '../subapps/shipments/common/makeDeliveryItems'
import { getLastSnapshotComment, getLastSnapshotSignature, getLastSnapshotSignedBy } from '../subapps/shipments/common/utils'
import { removeTime } from '../common/periods'
import withConfig from '../van-shared/hoc/withConfig'

import DeliveryStatementDeliveryInfo from './DeliveryStatementDeliveryInfo'
import ShipmentContentsTablePSM from '../subapps/shipments/components/ShipmentContentsTablePSM'

class DeliveryStatementPSM extends Component {
  render () {
    const {
      config,
      shipment,
      paymentType,
      location,
      productsById
    } = this.props

    const date = get(shipment, 'snapshotDates.received', shipment.date)

    const plannedQuantities = getPlannedQuantities(shipment)
    const items = makeDeliveryItems({
      shipment,
      report: {},
      productsById,
      includeReportItems: false,
      sortByName: true,
      plannedQuantities
    }) || []

    const signature = getLastSnapshotSignature(shipment)
    const signedBy = getLastSnapshotSignedBy(shipment)
    const comment = getLastSnapshotComment(shipment)

    const numItems = items.length

    const boxblock = (
      <DeliveryStatementDeliveryInfo
        numberOfItems={numItems}
        date={removeTime(date)}
        paymentType={paymentType}
      />
    )

    return (
      <PrintTemplate>
        <PrintTemplate.Header title='Delivery statement' config={config} />
        <PrintTemplate.Box
          left={boxblock}
          right={<div className='delivery-statement-info'><div><strong className='delivery-statement-info__header'>{location.fullName}</strong></div>{location.additionalData.physicalAddress}</div>}
        />
        <PrintTemplate.Section>
          <div className='print-template__delivery'>Delivery No. {shipment.shipmentNo}</div>
        </PrintTemplate.Section>
        <PrintTemplate.Section withOverflow>
          <ShipmentContentsTablePSM items={items} withIcon />
        </PrintTemplate.Section>
        <PrintTemplate.Section>
          Delivery confirmed on <strong>{removeTime(date)}</strong>
        </PrintTemplate.Section>
        {comment &&
        <PrintTemplate.Section>
          <strong>Comment:</strong> {comment}
        </PrintTemplate.Section>
        }
        <PrintTemplate.Section>
          {signature && (
            <SignatureDisplay
              signature={signature}
              name={signedBy}
            />
          )}
        </PrintTemplate.Section>
      </PrintTemplate>
    )
  }
}

export default withConfig(DeliveryStatementPSM)
