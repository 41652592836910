import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import { CheckmarkCircle, CloseCircle, Document } from '../../icons'

import DeemphasizedLink from '../DeemphasizedLink'

const defaultOnChange = () => null

class FileInputAdvanced extends Component {
  state = {
    invalidFile: false,
    errorMessage: 'Error uploading file'
  }

  clearError = (e) => {
    const { onClearValue } = this.props
    this.setState({ invalidFile: false })
    onClearValue(e)
  }

  validFile = ({ value, files, accept }) => {
    const { maxFileSize } = this.props
    let errors = {
      type: '',
      size: ''
    }
    let fileNames = []
    let fileEndings = []
    if (accept && accept.length > 0) {
      fileEndings = accept.split(',').map(s => s.trim())
    }

    for (const file of files) {
      fileNames.push(file.name)
      if (file.size > maxFileSize) {
        errors.size = 'invalid file size'
      }

      if (fileEndings.length > 0) {
        const fileType = file.type.split('/').pop()

        if (!fileEndings.includes(fileType)) {
          errors.type = 'invalid file type'
        }
      }
    }

    const isFileValid = !errors.type && !errors.size

    if (!isFileValid) {
      this.setState({
        errorMessage: `${errors.type} ${errors.size}`
      })
    }

    return isFileValid
  }

  handleChange = e => {
    if (!e.target.files[0]) return
    if (!this.validFile(e.target)) {
      return this.setState({ invalidFile: true })
    }

    const { onValueChange, onChange } = this.props
    if (typeof onValueChange === 'function') {
      const newValue = e.target.value
      const files = e.target.files
      onValueChange(newValue, files)
    }

    if (typeof onChange === 'function') {
      e.persist()
      onChange(e)
    }
    this.setState({ invalidFile: false })
  }

  render () {
    const { invalidFile, errorMessage } = this.state
    const {
      className,
      hasError,
      getInputRef,
      accept,
      acceptText,
      fileName,
      fieldName,
      date,
      disabled,
      multiple,
      maxFileSizeLabel
    } = this.props

    const errorExists = hasError || invalidFile
    return (
      <div
        className={classnames(
          'vs-file-input-advanced',
          {'vs-file-input-advanced--disabled': disabled},
          {'vs-file-input-advanced--filled': !!fileName},
          {'vs-file-input-advanced--error': errorExists},
          className
        )}
      >
        <input
          id={fieldName}
          className={classnames(
            'vs-file-input-advanced__input',
            {'vs-file-input-advanced__input--disabled': disabled},
            {'vs-u-visually-hidden': !!fileName || errorExists}
          )}
          type='file'
          accept={accept}
          ref={getInputRef}
          onChange={this.handleChange}
          disabled={disabled}
          multiple={multiple}
        />
        {!fileName && !errorExists && (
          <Fragment>
            <div className='vs-file-input-advanced__info'>
              <Document
                className='vs-file-input-advanced__icon'
              />
              <div>
                <label
                  htmlFor={fieldName}
                  className='vs-file-input-advanced__label'
                >
                  Upload file or drag and drop
                </label>
                <span className='vs-file-input-advanced__file-format'>
                  Accepted file format: {acceptText || accept || 'All file formats'}
                </span>
              </div>
            </div>
            <Button
              // We don't want the button to be reachable from keyboard as it has decorative function
              aria-hidden
              colorVariant='brand'
              fill='outline'
              disabled={disabled}
            >
              Choose file
            </Button>
            <span
              className='vs-file-input-advanced__file-size'
            >
              (max file size: {maxFileSizeLabel})
            </span>
          </Fragment>
        )}
        {fileName && !errorExists && (
          <Fragment>
            <div className='vs-file-input-advanced__info'>
              <span
                className='vs-file-input-advanced__icon vs-file-input-advanced__icon--filled'>
                <CheckmarkCircle />
              </span>
              <div>
                <div
                  className='vs-file-input-advanced__file-name'
                >
                  {fileName}
                </div>

                {date &&
                  <span className='vs-file-input-advanced__file-date'>
                    File uploaded on: {date}
                  </span>
                }
              </div>
            </div>
            <label
              className='vs-file-input-advanced__update'
              htmlFor={fieldName}
            >
              Update
            </label>
            <DeemphasizedLink
              onClick={this.clearError}
            >
              Remove
            </DeemphasizedLink>
          </Fragment>
        )}
        {errorExists && (
          <Fragment>
            <div className='vs-file-input-advanced__info'>
              <span className='vs-file-input-advanced__icon vs-file-input-advanced__icon--error'>
                <CloseCircle />
              </span>
              <div>
                <div
                  className='vs-file-input-advanced__error'
                >
                  {errorMessage}
                </div>
                <span className='vs-file-input-advanced__file-format'>
                  Accepted file format: {acceptText || accept || 'All file formats'}
                </span>
              </div>
            </div>
            <label
              className='vs-file-input-advanced__update'
              htmlFor={fieldName}
            >
              Try another file
            </label>
            <DeemphasizedLink
              onClick={this.clearError}
            >
              Clear
            </DeemphasizedLink>
          </Fragment>
        )}
      </div>
    )
  }
}

FileInputAdvanced.propTypes = {
  /**
   * An optional className to apply to the element
   */
  className: PropTypes.string,

  /**
   * An optional type to pass to the element
   */
  type: PropTypes.string,

  /**
   * The error state
   */
  hasError: PropTypes.bool,

  /**
   * Runs on input changes, returns the text value (not the event)
   */
  onValueChange: PropTypes.func,

  /**
   * Runs on input change, returns whole event
   */
  onChange: PropTypes.func,

  /**
   * use to ref the input field
   */
  getInputRef: PropTypes.func,

  /**
   * The input value
   */
  value: PropTypes.string,

  /**
   * The list of accepted formats
   */
  accept: PropTypes.string,

  /**
   * A human-readable list of accepted formats to display
   */
  acceptText: PropTypes.string,

  /**
   * Unique input identifier
   */
  fieldName: PropTypes.string,

  /**
   * A name of uploaded file
   */
  fileName: PropTypes.string,

  /**
   * Max file size
   */
  maxSize: PropTypes.string,

  /**
   * If the upload should be disabled
   */
  disabled: PropTypes.bool,

  /**
   * Upload date
   */
  date: PropTypes.string,

  /**
   * Max file size in bytes, default 20MB
   */
  maxFileSize: PropTypes.number,

  /**
   * Label for how large a file can be, eg: '20MB'
   */
  maxFileSizeLabel: PropTypes.string,
  /**
   * Determines if multiple items should be allowed to upload at once
   */
  multiple: PropTypes.bool
}

FileInputAdvanced.defaultProps = {
  className: '',
  hasError: false,
  onChange: defaultOnChange,
  onValueChange: null,
  getInputRef: () => null,
  // Set to '' because we want React to know this a controlled component
  value: '',
  accept: undefined,
  acceptText: undefined,
  // Max file size allowed by browsers, should be amended
  maxFileSize: 20000000,
  maxFileSizeLabel: '20MB',
  fieldName: 'fileInputAdvanced',
  fileName: '',
  multiple: false
}

export default FileInputAdvanced
