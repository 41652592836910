const get = require('lodash/get')
const { parse } = require('../../../tools/smart-id')
const createSnapshot = require('../../../shipment/shipment-create-snapshot')
const { SUBORDER_STATUS, PRIOR_STATUS_MAPPING } = require('../../constants')

exports.moveShipmentStatus = async function (state, {
  suborderId,
  status = SUBORDER_STATUS.received,
  priorStateSnapshot,
  shipmentCount,
  externalShipmentId,
  shipmentNumber
}) {
  const orderSnapshot = await state.dal.order.getSuborder(state, {suborderId})

  if (!priorStateSnapshot) {
    const shipmentSnapshots = await state.dal.order.getShipmentsOnOrder(state, orderSnapshot._id)

    const priorStatus = PRIOR_STATUS_MAPPING[status]
    priorStateSnapshot = shipmentSnapshots.find(snapshot => parse(snapshot._id).status === priorStatus && snapshot.type === 'snapshot')
  }

  const shipmentSnapshot = createShipmentSnapshot(orderSnapshot, priorStateSnapshot, status, shipmentCount, externalShipmentId, shipmentNumber)
  return createSnapshot(state, shipmentSnapshot)
}

function createShipmentSnapshot (orderSnapshot, shipmentSnapshot, status, shipmentCount, externalShipmentId, shipmentNumber) {
  const shipmentNo = shipmentNumber || getShipmentNumber(shipmentSnapshot)
  const routeId = get(orderSnapshot, 'routeId')
  const oneShipmentNumber = get(orderSnapshot, 'additionalData.shipmentNumber')
  return {
    origin: orderSnapshot.supplierId,
    destination: orderSnapshot.destinationId,
    // VAN store api expects YYYY-MM-DD
    date: orderSnapshot.createdAt.split('T')[0],
    shipmentTypeId: `allocated:${orderSnapshot.funderId}`,
    funderId: orderSnapshot.funderId,
    orderId: orderSnapshot._id,
    programId: orderSnapshot.programId,
    routeId,
    shipmentNo,
    status,
    counts: shipmentCount && Object.keys(shipmentCount).length > 0
      ? shipmentCount
      : getCounts(shipmentSnapshot),
    removePaymentType: true,
    doCheckSent: false,
    externalShipmentId: externalShipmentId || oneShipmentNumber
  }
}

function getShipmentNumber (newShipmentSnapshot = {}) {
  if (!newShipmentSnapshot._id) return null
  const {shipmentNo} = parse(newShipmentSnapshot._id)
  return shipmentNo
}

function getCounts (newShipmentSnapshot) {
  // ignore snapshot comment docs
  if (!newShipmentSnapshot) {
    return {}
  }
  return Object.keys(newShipmentSnapshot.counts)
    .reduce((acc, batchId) => {
      acc[batchId] = {
        quantity: newShipmentSnapshot.counts[batchId].quantity,
        checked: true
      }
      return acc
    }, {})
}
