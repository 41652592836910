import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CheckmarkCircle, ExclamationCircle } from '../../icons'

const StatusLabel = ({
  status,
  label,
  icon,
  tight,
  inactive,
  big,
  nowrap,
  noBackground,
  className
}) => (
  <span
    className={
      classnames(
        'vs-status-label',
        { 'vs-status-label--attention': status === 'attention' },
        { 'vs-status-label--success': status === 'success' },
        { 'vs-status-label--warning': status === 'warning' },
        { 'vs-status-label--info': status === 'info' },
        { 'vs-status-label--in-progress': status === 'progress' },
        { 'vs-status-label--tight': tight },
        { 'vs-status-label--inactive': inactive },
        { 'vs-status-label--size-big': big },
        { 'vs-status-label--no-bg': noBackground },
        { 'vs-status-label--nowrap': nowrap },
        className
      )
    }
  >
    {icon}
    {!icon && status === 'success' && <CheckmarkCircle />}
    {!icon && status === 'warning' && <ExclamationCircle />}
    {label}
  </span>
)

StatusLabel.propTypes = {
  /**
   * The status `success|warning|attention`
   */
  status: PropTypes.oneOf(['attention', 'success', 'warning', 'info', 'progress']),
  /**
   * The label to display
   */
  label: PropTypes.string.isRequired,
  /**
   * Icon if you want to override the standard "!"
   */
  icon: PropTypes.node,
  /**
   * Use less vertical padding
   */
  tight: PropTypes.bool,
  /**
   * Whether the label shold be inactive or not
   */
  inactive: PropTypes.bool,
  /**
   * Whether the label shold be big or not
   */
  big: PropTypes.bool,
  /**
   * To select version of label without the background
   */
  noBackground: PropTypes.bool,
  /**
   * Label text will not wrap no matter what
   */
  nowrap: PropTypes.bool
}

StatusLabel.defaultProps = {
  status: undefined,
  icon: undefined,
  tight: false,
  inactive: false,
  big: false,
  noBackground: false
}

export default StatusLabel
