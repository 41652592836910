import React, {createElement} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactSelect from 'react-select'
import VirtualizedSelect from 'react-virtualized-select'

import SelectTwoPartOption from './option-renderers/SelectTwoPartOption'
import TwoPartLabel from './option-renderers/TwoPartLabel'

// The vendor CSS import needs to happen in JS, if we attempt to
// import in the SCSS, when bundled in v-a-s the vendor CSS is not
// resolvable by webpack 🤷🏻‍♂️
import 'react-select/dist/react-select.css'

const StatelessSelectInput = props => {
  const {
    // we list the name to block it from ...otherProps because of a bug
    // in react-select which triggers when name prop is passed
    // https://github.com/fielded/van-orga/issues/1250
    name,
    // we also pluck defaultValue from ...otherProps, since we want
    // state to be passed instead so that the select value actually updates.
    // The select has shown to be a bit buggy with this.
    defaultValue,
    creatable,
    virtualized,
    className,
    hasError,
    onChange,
    onValueChange,
    getInputRef,
    ...otherProps
  } = props

  let selectComponent = ReactSelect
  if (creatable) {
    selectComponent = ReactSelect.Creatable
  }

  if (virtualized) {
    selectComponent = VirtualizedSelect
  }

  const selectProps = {
    className: classnames(
      'vs-select',
      { 'vs-select--creatable': creatable },
      { 'vs-select--has-error': hasError },
      'vs-form-field__select',
      { 'vs-form-field__select--has-error': hasError },
      className
    ),
    valueRenderer: (option) => <TwoPartLabel option={option} />,
    onChange (selectedOption) {
      let nextValue = selectedOption

      if (Array.isArray(selectedOption) && selectedOption.filter(Boolean).length) {
        nextValue = nextValue.map(option => option.value)
      } else if (selectedOption) {
        nextValue = nextValue.value
      }

      onChange(selectedOption)
      onValueChange(nextValue)
    },
    ref: getInputRef,
    ...otherProps
  }

  return createElement(
    selectComponent,
    selectProps
  )
}

StatelessSelectInput.displayName = 'SelectInput.Stateless'

class SelectInput extends React.Component {
  static Stateless = StatelessSelectInput

  constructor (props) {
    super(props)

    this.state = {
      value: props.defaultValue || props.value || '',
      inputValue: undefined
    }
  }

  handleValueChange = (value) => {
    const { onValueChange } = this.props
    onValueChange(value)
  }

  handleChange = (selectedOption) => {
    const { onChange } = this.props

    if (selectedOption !== this.state.value) {
      // Please note:
      // selectedOption can be null when the `x` (close) button is clicked
      this.setState({ value: selectedOption })
      onChange(selectedOption)
    }
  }

  handleInputChange = (inputValue) => {
    this.setState({inputValue})
  }

  render = () => {
    const {
      value,
      onChange,
      onValueChange,
      // the above keys need to be stripped from the props that are being passed
      // through in order to maintain the component's functionality
      ...otherProps
    } = this.props

    let dynamicProps = {
      optionRenderer: (option) => {
        return <TwoPartLabel option={option} />
      }
    }

    // TODO: extend onInputChange so we can have both highlight and creatable
    if (!this.props.creatable) {
      dynamicProps = {
        onInputChange: this.handleInputChange,
        optionRenderer: (option) => {
          return this.props.virtualized
            ? SelectTwoPartOption(option)
            : <TwoPartLabel option={option} inputValue={this.state.inputValue} />
        }
      }
    }

    return (
      <StatelessSelectInput
        value={this.state.value}
        onChange={this.handleChange}
        onValueChange={this.handleValueChange}
        {...dynamicProps}
        {...otherProps}
      />
    )
  }
}

SelectInput.propTypes = {
  /**
   * This will not be passed on the field because of a bug in react-select
   */
  name: PropTypes.string,

  /**
   * The default input value (one of the options or the value of one)
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),

  /**
   * The input value (one of the options or the value of one).
   * Used as defaultValue
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),

  /**
   * Determines if user can create new options
   */
  creatable: PropTypes.bool,

  /**
   * Determines if user can choose more options than one
   */
  multi: PropTypes.bool,

  /**
   * Uses https://github.com/bvaughn/react-virtualized-select/ for high numbers of options
   */
  virtualized: PropTypes.bool,

  /**
   * Classname(s) to pass to the form field
   */
  className: PropTypes.string,

  /**
   * Determines if presentation indicates an issue with the field
   */
  hasError: PropTypes.bool,

  /**
   * use to ref the react-select component
   */
  getInputRef: PropTypes.func,

  /**
   * Optional additional onChange handler (called after form onChange handler)
   */
  onChange: PropTypes.func,

  /**
   * Optional additional onValueChange handler (called after form onChange handler)
   * this is passing through the `react-select`'s value
   */
  onValueChange: PropTypes.func
}

SelectInput.defaultProps = {
  name: undefined,
  value: undefined,
  creatable: false,
  virtualized: false,
  multi: false,
  className: undefined,
  hasError: false,
  onChange: () => {},
  onValueChange: () => {},
  getInputRef: () => null
}

StatelessSelectInput.propTypes = SelectInput.propTypes

StatelessSelectInput.defaultProps = SelectInput.defaultProps

export default SelectInput
export { SelectTwoPartOption, TwoPartLabel }
