import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'

import { selectMasterData } from '../../root/master-data-reducer'
import { isReturnShipment } from '../../../../common/utils/shipment'
import { getPlannedQuantities, makeDeliveryItems } from '../../common/makeDeliveryItems'
import { getLastSnapshotSignature, getLastSnapshotSignedBy } from '../../common/utils'
import PSMDeliveryConfirmation from './PSMDeliveryConfirmation'

const PSMDeliveryConfirmationContainer = ({
  api,
  shipment,
  formRef,
  comment,
  onCommentChange,
  onProceedClick
}) => {
  const masterData = useSelector(selectMasterData)
  const productsById = get(masterData, 'products.byId', {})

  const [location, setLocation] = useState()
  const [items, setItems] = useState([])
  const [name, setName] = useState('')
  const [signature, setSignature] = useState('')
  const [loading, setLoading] = useState(true)

  let locationId = get(shipment, 'destination.id')
  if (isReturnShipment(null, shipment)) {
    locationId = get(shipment, 'origin.id')
  }

  const fetchData = async () => {
    try {
      const location = await api.location.get(locationId)

      const plannedQuantities = getPlannedQuantities(shipment)
      const items = makeDeliveryItems({ shipment, productsById, plannedQuantities }) || []
      const signature = getLastSnapshotSignature(shipment)
      const name = getLastSnapshotSignedBy(shipment)

      setLocation(location)
      setItems(items)
      setName(name)
      setSignature(signature)
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (loading) {
    return <Loading loadingText='Loading delivery note …' />
  }

  return (
    <PSMDeliveryConfirmation
      formRef={formRef}
      location={location}
      items={items}
      name={name}
      signature={signature}
      comment={comment}
      onCommentChange={onCommentChange}
      onProceed={onProceedClick}
    />
  )
}

export default PSMDeliveryConfirmationContainer
