const createShipmentSnapshots = function ({acceptedSnapshots, funderId, productsList = []}) {
  return acceptedSnapshots.map(
    orderSnapshot => createShipmentSnapshot({orderSnapshot, funderId, productsById: getProductsById(productsList)})
  )
}

function getProductsById (productsList) {
  return productsList
    .reduce((acc, product) => {
      acc[product._id] = product
      return acc
    }, {})
}

function createDetails (orderSnapshot, funderId) {
  return {
    origin: orderSnapshot.supplierId,
    destination: orderSnapshot.destinationId,
    // VAN store api expects YYYY-MM-DD
    date: orderSnapshot.createdAt.split('T')[0],
    overrideCreatedAt: orderSnapshot.createdAt,
    status: 'new',
    // this is so we can see allocation:{parentFunderId}
    // vs. funderId: actual location sub funder
    shipmentTypeId: `allocated:${funderId}`,
    funderId: orderSnapshot.funderId,
    programId: orderSnapshot.programId,
    orderId: orderSnapshot._id,
    totalAmount: orderSnapshot.totalAmount
  }
}

function createShipmentSnapshot ({orderSnapshot, funderId, productsById}) {
  const details = createDetails(orderSnapshot, funderId)
  const counts = createShipmentCounts({orderSnapshotProducts: orderSnapshot.products, productsById, useAmount: false})
  return Object.assign({}, details, { counts })
}

const createShipmentCounts = function ({orderSnapshotProducts, productsById, useAmount}) {
  return Object.keys(orderSnapshotProducts)
    .reduce((acc, productId) => {
      const productDetails = orderSnapshotProducts[productId]
      const {genericFactor = 1} = productsById[productId] || {}
      Object.keys(productDetails.batches).forEach(batchId => {
        const value = useAmount
          ? productDetails.batches[batchId].amount // This is for api updates
          : productDetails.batches[batchId].original
        const quantity = Math.ceil(value / genericFactor)
        acc[batchId] = {quantity}
      })

      return acc
    }, {})
}

module.exports = { createShipmentCounts, createShipmentSnapshots }
