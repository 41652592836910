import React, { Component } from 'react'
import get from 'lodash/get'
import isFinite from 'lodash/isFinite'

import { PriceDisplay, PrintTemplate, SignatureDisplay } from '@fielded/shared-ui'
import { getCurrencySymbol } from '@fielded/shared-ui/src/components/PriceDisplay'
import { PURCHASE, RESUPPLY, IMMEDIATE_PURCHASE } from '@fielded/fs-api/lib/shipment/tools/planning-types'

import { pluralize } from '../van-shared/utils/utils'
import { hasFeature } from '../van-shared/utils/features'

import DeliveryNoteTable from './DeliveryNoteTable'
import DeliveryStatementAdjustmentInfo from './DeliveryStatementAdjustmentInfo'
import DeliveryStatementDeliveryInfo from './DeliveryStatementDeliveryInfo'
import { makeDeliveryItems } from '../subapps/shipments/common/makeDeliveryItems'
import { getLastSnapshotSignature, getLastSnapshotSignedBy } from '../subapps/shipments/common/utils'
import { removeTime } from '../common/periods'
import { withUser } from '../common/AuthenticationProvider'
import withConfig from '../van-shared/hoc/withConfig'
import { DIRECT_ORDER_TYPES } from '@fielded/fs-api/lib/allocation/config'
import { userIsAuthorised } from '../van-shared/utils/auth'
import { PAYMENT_CHOICES } from '@fielded/fs-api/lib/order/constants'
const { PAY_AS_YOU_SELL, PAY_ON_DELIVERY } = DIRECT_ORDER_TYPES

const getTableHeaders = (currency, showVAT) => {
  return [
    { value: 'Code', name: 'code' },
    { value: 'Products', name: 'products' },
    { value: 'QUANTITY DELIVERED', name: 'quantity-delivered' },
    { value: `UNIT PRICE (${currency})`, name: 'unit-price' },
    showVAT && { value: `VAT (${currency})`, name: 'vat' },
    { value: `TOTAL (${currency})`, name: 'total' }
  ].filter(x => x)
}

const getTableRows = (data, showVAT, country, baseLedgerUrl) => {
  return data.filter(item => {
    const { isProductLine, isTotal } = item

    if (isProductLine || isTotal) {
      return false
    }

    return true
  }).filter(item => {
    const delivered = get(item, 'delivered', 0)
    return delivered !== 0
  }).map((item, idx) => {
    let code = get(item, 'code', {})

    if (item.adjustmentDate) {
      code = `*${code}`
    }

    // fields
    const name = get(item, 'name', '-')

    const delivered = get(item, 'delivered', 0)
    const price = get(item, 'price', 0)
    const vat = get(item, 'unitVat', 0)
    const total = get(item, 'deliveredValue', 0)

    return {
      key: `row.${idx}`,
      cols: [{
        name: 'code',
        value: code
      }, {
        name: 'products',
        url: baseLedgerUrl && `${baseLedgerUrl}/product:${item.code}`,
        value: name
      }, {
        name: 'Quantity delivered',
        value: delivered
      }, {
        name: 'Unit Price',
        value: price
      }, showVAT && {
        name: 'VAT',
        value: <PriceDisplay value={vat} country={country} />
      }, {
        name: 'Total',
        value: <PriceDisplay value={total} country={country} />
      }].filter(x => x),
      name
    }
  })
}

class DeliveryStatement extends Component {
  render () {
    const {
      config,
      shipment,
      paymentType,
      location,
      productsById,
      serviceId,
      user
    } = this.props

    const country = get(location, 'location.country')
    const currency = getCurrencySymbol({country})
    const showVAT = hasFeature(config.features, `showVAT.${country}`)
    const date = get(shipment, 'snapshotDates.received', shipment.date)
    const isExternalPackPoint = location.level === 'pack-point' && location.additionalData.classification === 'External Distribution Center'
    const procurementServiceFeeRate = location.additionalData.procurementServiceFeeRate

    const isDirectPurchase = [PURCHASE, IMMEDIATE_PURCHASE].includes(shipment.planningType)

    // Note: since https://github.com/fielded/fs-web/pull/1709
    // the "delivery statement" section only shows delivered or returned products
    // https://github.com/fielded/fs-web/pull/1709/files#diff-1e9a5447a7e56661e2d906f8b56972aa172603056230dc61f7b6bfb941cc06daR34
    const items = makeDeliveryItems({ shipment, report: {}, productsById, includeReportItems: false, sortByName: true })
    const adjustments = items.filter(items => !!items.adjustmentDate).length
    let adjustmentsNote = ''
    let adjustmentDate
    if (adjustments > 0) {
      adjustmentsNote = `Adjustment for ${adjustments} ${pluralize('product', adjustments)}`
      adjustmentDate = items.map(i => i.adjustmentDate).filter(x => x).sort().pop()
    }

    const deliveryTotal = items.reduce((sum, item) => {
      // incase delivered value is NaN, so we don't have total of NaN
      if (item.delivered) sum += item.deliveredValue || 0
      return sum
    }, 0)

    const deliveryTotalMinusVat = items.reduce((sum, item) => {
      if (item.delivered) {
        item.deliveredValueMinusVat = item.delivered * item.price
        sum += item.deliveredValueMinusVat || 0
      }
      return sum
    }, 0)

    const paidTotal = isDirectPurchase ? items.reduce((sum, item) => {
      return sum + item.orderedValue
    }, 0) : null

    const shouldShowRefund = isDirectPurchase && shipment.paymentChoice !== PAYMENT_CHOICES.on_delivery

    const refund = shouldShowRefund ? paidTotal - deliveryTotal : null

    const displayItems = items

    const signature = getLastSnapshotSignature(shipment)
    const signedBy = getLastSnapshotSignedBy(shipment)

    const headers = getTableHeaders(currency, showVAT)
    const baseLedgerUrl = serviceId && `/reporting/ledger/${serviceId}/${location._id}`
    const rows = getTableRows(displayItems, showVAT, country, baseLedgerUrl)

    const numItems = rows.length

    const locationDetails = get(location, 'location.state')
    const isDirectOrder = shipment.planningType === PURCHASE
    const isBasicOrder = shipment.planningType === IMMEDIATE_PURCHASE
    const isResupply = shipment.planningType === RESUPPLY

    // PAYG service fee
    const procurementServiceFeePAYG = deliveryTotalMinusVat * procurementServiceFeeRate
    const copyInfoTableNote = `* ${!isExternalPackPoint ? 'invoice sent separately' : 'Service fees will be included on your invoice and sent separately'}`
    const appName = config.name

    // This is needed only for Basic orders
    const deliveryFee = shipment.deliveryFee

    let boxblock = (
      <DeliveryStatementDeliveryInfo
        numberOfItems={numItems}
        date={removeTime(date)}
        paymentType={paymentType}
      />
    )

    if (adjustments) {
      boxblock = (
        <DeliveryStatementAdjustmentInfo
          adjustments={adjustments}
          adjustmentDate={adjustmentDate}
          shipmentNo={shipment.shipmentNo}
          paymentType={paymentType}
        />
      )
    }

    if (isDirectOrder) {
      boxblock = (
        <DeliveryStatementDeliveryInfo
          numberOfItems={numItems}
          date={removeTime(date)}
          immediatePayment
        />
      )
    }

    return (
      <PrintTemplate>
        <PrintTemplate.Header title='Delivery statement' config={config} />
        <PrintTemplate.Box
          left={boxblock}
          right={<div className='delivery-statement-info'><div><strong className='delivery-statement-info__header'>{location.fullName}</strong></div>{location.additionalData.physicalAddress}</div>}
        />
        {!adjustments && <PrintTemplate.Section>
          <div className='print-template__delivery'>Delivery No. {shipment.shipmentNo}</div>
        </PrintTemplate.Section>}
        {isResupply && <PrintTemplate.Section>
          <div className='print-template__delivery' ><strong>Note:</strong> Prices listed below are supplier buy prices</div>
        </PrintTemplate.Section>}
        {!!adjustments && (<PrintTemplate.Section>
          <strong>*Note: </strong>{adjustmentsNote}
        </PrintTemplate.Section>)}
        <PrintTemplate.Section withOverflow>
          <DeliveryNoteTable
            headers={headers}
            rows={rows}
            total={deliveryTotal}
            deliveryFee={deliveryFee}
            showVAT={showVAT}
            paymentType={paymentType}
            isDirectOrder={isDirectOrder}
            isBasicOrder={isBasicOrder}
            country={country}
            isExternalPackPoint={isExternalPackPoint}
            procurementServiceFeePAYG={procurementServiceFeePAYG}
            procurementServiceFeeRate={procurementServiceFeeRate}
            isAntara={userIsAuthorised(user, 'feature:can-view-source-ledger')}
          />
          {(isDirectOrder || (paymentType === PAY_ON_DELIVERY)) && (
            <div className='delivery-statement-info__table-note'>
              {copyInfoTableNote}
            </div>
          )}
        </PrintTemplate.Section>
        <PrintTemplate.Section withTopMargin={paymentType === PAY_AS_YOU_SELL}>
          Delivery confirmed on <strong>{removeTime(date)}</strong>
        </PrintTemplate.Section>
        {isFinite(refund) && refund > 0 && (
          <PrintTemplate.Section withTopMargin={paymentType === PAY_AS_YOU_SELL}>
            {'Some item(s) in this order were not fulfilled. A refund of '}
            <PriceDisplay
              value={refund}
              currencySymbol
              country={country}
              inColor
              bold
            />
            {` has been added to your ${appName} Wallet and will be automatically applied as credit to your next order.`}
          </PrintTemplate.Section>
        )}
        <PrintTemplate.Section>
          {signature && (
            <SignatureDisplay
              signature={signature}
              name={signedBy}
            />

          )}
        </PrintTemplate.Section>
        <PrintTemplate.Footer location={locationDetails} />
      </PrintTemplate>
    )
  }
}

export default withConfig(withUser(DeliveryStatement))
