const { validateParams } = require('../validate-params')
const shortid = require('shortid')

exports.createSnapshot = createSnapshot
function createSnapshot ({
  user,
  products,
  destinationId,
  timestamp,
  version = '1.0.0',
  programId,
  groupId,
  funderId,
  routeId,
  orderId,
  status,
  suborderId,
  supplierId,
  reports,
  commodityType,
  storageType,
  deliveryDate,
  orderType,
  doNotAddUpdateParams,
  appendLocationToId,
  patientName,
  patientId,
  deliveryFee,
  vat,
  totalAmount,
  orderAmount,
  paymentStatus,
  paymentTimestamp,
  paymentChoice
}) {
  validateParams('createSnapshot', arguments[0])
  suborderId = suborderId || shortid.generate()
  const idParams = {groupId, orderId, suborderId}
  if (appendLocationToId) {
    idParams.locationId = destinationId
  }
  const snapshot = {
    _id: createOrderId(idParams),
    version,
    destinationId,
    programId,
    status,
    type: 'orderSnapshot',
    orderId, // unique to order doc
    groupId, // same as orders created at the same time
    suborderId,
    createdAt: timestamp,
    createdBy: user.name,
    updatedAt: timestamp,
    updatedBy: user.name,
    isComplete: false,
    products: getProducts(products),
    reports,
    orderCreatedDate: timestamp // reference order created date from shipment doc.
  }
  if (supplierId) {
    snapshot.supplierId = supplierId
  }
  if (funderId) {
    snapshot.funderId = funderId
  }
  if (routeId) {
    snapshot.routeId = routeId
  }

  if (commodityType) {
    snapshot.commodityType = commodityType
  }
  if (storageType) {
    snapshot.storageType = storageType
  }
  if (deliveryDate) {
    snapshot.deliveryDate = deliveryDate
  }
  if (orderType) {
    snapshot.orderType = orderType
  }
  if (doNotAddUpdateParams) {
    delete snapshot.updatedAt
    delete snapshot.updatedBy
  }
  if (patientName) {
    snapshot.patientName = patientName
  }
  if (patientId) {
    snapshot.patientId = patientId
  }
  if (typeof (deliveryFee) === 'number') {
    snapshot.deliveryFee = deliveryFee
  }
  if (typeof (vat) === 'number') {
    snapshot.vat = vat
  }
  if (typeof (totalAmount) === 'number') {
    snapshot.totalAmount = totalAmount
  }

  if (typeof (orderAmount) === 'number') {
    snapshot.orderAmount = orderAmount
  }

  if (paymentStatus) {
    snapshot.paymentStatus = paymentStatus
  }

  if (paymentTimestamp) {
    snapshot.paymentTimestamp = paymentTimestamp
  }

  if (paymentChoice) {
    snapshot.paymentChoice = paymentChoice
  }

  return snapshot
}

function getProducts (products) {
  return Object.keys(products)
    .reduce((acc, key) => {
      acc[key] = Object.assign({}, products[key], {adjusted: products[key].original})
      return acc
    }, {})
}

exports.createOrderId = createOrderId
function createOrderId ({groupId, orderId, suborderId, locationId = null}) {
  let id = `type:orderSnapshot:groupId:${groupId}:orderId:${orderId}:suborderId:${suborderId}`
  if (locationId) {
    id = `${id}:location:${locationId}`
  }
  return id
}
