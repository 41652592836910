const { generateExcelSheet } = require('../utils/generate-excel-sheet')
const { warehouseCodeToVirtualId } = require('../order/tools')
const get = require('lodash/get')
const keyBy = require('lodash/keyBy')
const endOfDay = require('date-fns/end_of_day')
const startOfDay = require('date-fns/start_of_day')

const SHIPMENTEXPORTHEADERS = [
  'locationCode',
  'locationId',
  'state',
  'lga',
  'subOrderId',
  'funderId',
  'programId',
  'route'
]

const exportWareHouseFunderShipments = async function (state, {api, warehouse, funderId, startDate, endDate, programs, dryRun = false}) {
  if (!warehouse || !funderId || warehouse === '' || funderId === '' || !programs || programs.length === 0) {
    throw new Error('Missing warehouse or funder or program')
  }

  const userLocationId = state.user.location.id

  if (userLocationId !== 'national') {
    throw new Error('User should always be a national user')
  }

  // Get all locations
  const locations = await api.location.listAll({dontUseFilter: true, includeInactive: true})
  const locationsById = keyBy(locations, '_id')

  const warehousesIds = []

  programs.forEach(program => {
    const warehouseWithFunderId = warehouseCodeToVirtualId({
      warehouseCode: warehouse,
      funderId,
      programId: program
    })

    warehousesIds.push(warehouseWithFunderId)
  })

  const distinctWarehouseIds = [...new Set(warehousesIds)]

  // Get all shipments for that warehouse

  const shipmentPromises = distinctWarehouseIds.map(warehouseId =>
    // Format dates to account for last second of selected end date
    api.shipment.find({
      location: warehouseId,
      startdate: startOfDay(new Date(startDate)).toISOString(),
      enddate: endOfDay(new Date(endDate)).toISOString()
    })
  )

  // Wait for all promises to resolve
  const shipmentsArray = await Promise.all(shipmentPromises)

  const shipments = shipmentsArray.flat()

  // Filter for open shipments and by specified funder
  // Get all columns from filtered shipments
  const shipmentLocations = shipments
    .filter(shipment => {
      // We use .includes to cater for sub funders
      return shipment.status === 'new' && shipment.funderId.includes(funderId)
    })
    .reduce((acc, shipment) => {
      const destination = shipment.destination.id
      const programId = shipment.programId
      const location = get(locationsById, destination)
      const subOrderId = shipment.orderId.split('suborderId:')[1]
      acc.push([
        get(location, 'additionalData.warehouseCode', ''),
        location._id,
        get(location, 'location.state'),
        get(location, 'location.lga'),
        subOrderId,
        funderId.split('funder:')[1],
        programId
      ])

      return acc
    }, [SHIPMENTEXPORTHEADERS])

  if (dryRun) return shipmentLocations

  // Generate excel sheet
  return generateExcelSheet(shipmentLocations, 'Warehouse shipment export')
}

module.exports = { exportWareHouseFunderShipments }
