// Note: `createForRoutes` and `createForRoutesPreview` are not available
// as a raw method since they're not exported in `../index`.
// Instead they need to be accessed directly through the Order API.
const get = require('lodash/get')
const groupBy = require('lodash/groupBy')
const tools = require('../../tools')
const { getLocationsForRoutes } = require('../master-data')
const { createForecastAllocations } = require('../../allocations/create-forecast-allocations')
const {packPointRationing} = require('../../tools/pack-point-rationing')

exports.createForRoutes = createForRoutes
async function createForRoutes (
  state,
  {
    programId,
    dryRun = false,
    routes,
    deliveryDate,
    dryRunPackPointRation = false
  },
  mainApi
) {
  if (!programId || !routes || !deliveryDate) {
    throw new Error(`programId, routes, and deliveryDate are required params`)
  }

  const parentLocationId = state.user.location.id

  const ordersForDeliveryDate = await state.dal.order.getOrdersForDeliveryDate(state, {
    programId,
    deliveryDate,
    parentLocationId
  })

  const orderTypes = new Set(ordersForDeliveryDate.map(order => order.orderType))

  // we throw an error if a routine order exists for the same delivery date
  if (ordersForDeliveryDate.length && orderTypes.has('routine')) throw new Error(`Creation Failed. An order already exists for this delivery date ${deliveryDate}`)

  // if a topup order group exists for the same delivery date
  // we allow creation of two different orders
  let groupId

  // `activeLocations` as returned from this functions is actually considered "unfiltered" for now because it includes
  // defaulted locations, which needs to be extracted (next step)
  const { activeLocations: unfilteredActiveLocations, suspendedLocations } = await getLocationsForRoutes(
    state,
    parentLocationId,
    deliveryDate,
    routes,
    'routine'
  )

  // todo: could be moved to master-data#getLocationsForRoutes but could ruin where that func gets used elsewhere
  const activeLocations = [] // the *actual* active locations
  const defaultedLocations = []

  const queryParams = {
    txnType: 'invoice',
    overduePayment: true,
    locationIds: unfilteredActiveLocations.map(l => l._id)
  }

  const { results: bulkInvoices } = await mainApi.finances.getTransactionsBulk(queryParams)
  const invoicesPerLocation = unfilteredActiveLocations.map((location) => {
    const invoices = bulkInvoices.filter(invoice => invoice.locationFsid === location._id)
    return { location, invoices }
  })

  const { OVERDUE_INVOICES_LIMIT } = mainApi.invoices.rules

  const { results } = await mainApi.paymentPlans.getPaymentPlansBulk(invoicesPerLocation.map(group => group.location._id))
  const locationPaymentPlans = groupBy(results, 'locationFsid')
  for (const locationInvoiceGroup of invoicesPerLocation) {
    const paymentPlans = locationPaymentPlans[locationInvoiceGroup.location._id] || []
    const isOverDuePaymentPlan = mainApi.paymentPlans.tools.isOverduePaymentPlan(paymentPlans)
    const odInvoicesCount = locationInvoiceGroup.invoices.length
    if ((odInvoicesCount >= OVERDUE_INVOICES_LIMIT) || isOverDuePaymentPlan) {
      defaultedLocations.push(locationInvoiceGroup.location)
    } else {
      activeLocations.push(locationInvoiceGroup.location)
    }
  }
  // </todo ["could be moved..."]>

  if (activeLocations.length === 0) {
    throw new Error(`Unable to proceed, all locations under ${routes} are defaulting in payments`)
  }

  const subscriptionsByLocationId = {}

  for (let i = 0; i < activeLocations.length; i++) {
    const facilityId = activeLocations[i]._id
    const row = await mainApi.allocation.getConfiguration({facilityId})

    subscriptionsByLocationId[facilityId] = get(row, 'products', {})
  }
  const locationsWithSubscriptions = activeLocations.map(location => {
    const row = Object.assign({}, location, {subscriptions: {}})
    if (!subscriptionsByLocationId[location._id]) return row

    row.subscriptions = subscriptionsByLocationId[location._id]
    return row
  })

  const {allocations, warnings} = await createForecastAllocations(state, mainApi, {locations: locationsWithSubscriptions, deliveryDate})

  const territory = state.user.location.id

  const orderType = 'routine' // Orders for routes always are of type routine

  const allocationsWithSupplierId = await packPointRationing(state, mainApi, { allocations, locationsWithSubscriptions, deliveryDate, orderType, dryRun: dryRunPackPointRation })

  const snapshots = tools.createNewOrders({
    programId,
    allocations: allocationsWithSupplierId,
    user: state.user,
    deliveryDate,
    groupId,
    orderType,
    doNotAddUpdateParams: true
  })

  const status = 'new'

  if (dryRun) {
    const orderEntities = tools.docsToOrderEntities({docs: snapshots, status, withSnapshots: true})
    // TODO: add product master data
    const orders = tools.entitiesWithRelations({orderEntities, activeLocations})
    const allItemCount = tools.getUniqueProductCount(orders)
    const PODItemCount = tools.getUniqueProductCount(orders, true)
    // We need to deduct POD items as items showing up as PAYS have a few types of allocationType and it is hard to correctly determine which ones to use.
    const PAYSItemCount = allItemCount - PODItemCount
    return {
      routes,
      suspendedLocations,
      defaultedLocations,
      territory,
      locationsCount: tools.getUniqueLocationCount(orders),
      paysProductsCount: PAYSItemCount,
      PODProductsCount: PODItemCount,
      orders,
      warnings,
      snapshots,
      activeLocations
    }
  }

  const createdSnapshots = await state.dal.order.bulkDocs(state, snapshots)
  return tools.docsToOrderEntities({docs: createdSnapshots, status})
}

exports.createForRoutesPreview = createForRoutesPreview
async function createForRoutesPreview (state, params, mainApi) {
  return createForRoutes(state, Object.assign({}, params, {dryRun: true}), mainApi)
}

exports.createWithSnapshots = createWithSnapshots
async function createWithSnapshots (state, params) {
  const status = 'new'
  const {snapshots} = params
  const createdSnapshots = await state.dal.order.bulkDocs(state, snapshots)
  return tools.docsToOrderEntities({docs: createdSnapshots, status})
}
