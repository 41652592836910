const { EntityApi } = require('../common')
const PGAdapter = require('./../common/pg-adapter')
const { getOrderByClause } = require('../tools/sql-tools')
const { PricePGAdapter } = require('./price-pg-adapter')
const { PriceSuggestionPGAdapter, PriceSuggestionTierPGAdapter, PriceSuggestionSummaryPGAdapter } = require('./price-suggestions-pg-adapter')
const { PresentationCostBasisPGAdapter } = require('./presentation-cost-basis-pg-adapter')
const { PriceDailyPGAdapter } = require('./price-daily-pg-adapter')
const { PriceUpdatePGAdapter } = require('./price-update-pg-adapter')
const { PriceUpdateEntryPGAdapter } = require('./price-update-entry-pg-adapter')
const { PriceDataReplicationPGAdapter } = require('./price-data-replication')
const { PriceMarkupMultiplierPGAdapter } = require('./price-markup-multiplier-pg-adapter')

class PriceDatePGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    super(pgConnection, 'real.price_history', username, ['id', 'product', 'market', 'supplier', 'date', 'month', 'buyprice', 'sellprice'], 'id')
    this.logger = logger
  }

  async create ({date, sku}) {
    throw new Error('cannot be modified directly')
  }

  async delete () {
    throw new Error('cannot be modified directly')
  }

  async update () {
    throw new Error('cannot be modified directly')
  }

  async getList ({ ordering = this.idColumn, filter = {}, limit = 50, offset = 0 } = {}) {
    if (!filter.date) {
      return {results: [], count: 0, message: 'When called without a date, price date check returns nothing'}
    }
    const cte = `
      with
      recently_bought as (
        select
          distinct sku
        from
          real.deliveries
        where
          delivered > 0 and (date > $1::date - '2 weeks'::interval and date <= $1)
      )`
    const query = cte + `
      select
          p.product_id as id,
          p.product,
          p.market,
          p.supplier,
          ph.date,
          ph.month,
          ph.buyprice,
          ph.sellprice
      from
        avocado.vw_products p
          inner join recently_bought r on p.product_id = r.sku
          left join real.price_history ph on p.product_id = ph.sku and ph.date = $1
      where (market = $2 or $2 is null) and buyprice is null
      ${getOrderByClause(ordering, this.columns)}
      limit $3 offset $4
    `
    const values = [filter.date, filter.market || null, limit, offset]
    const { rows: results } = await this.pgConnection.query(query, values)
    const countResponse = await this.pgConnection.query(cte + `
        select
          count(p.product_id)
        from
          avocado.vw_products p
            inner join recently_bought r on p.product_id = r.sku
            left join real.price_history ph on p.product_id = ph.sku and ph.date = $1
        where (market = $2 or $2 is null)
    `, [filter.date, filter.market || null])
    return { count: Number(countResponse.rows[0].count), results }
  }
}

class PriceBulkUpdatePGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    super(pgConnection, 'real.price_history', username, [], null, {}, logger)
  }

  async updateMany ({sku, supplier, date, buyprice, discount}) {
    if (!sku || !supplier || !date) {
      throw new Error('bulkUpdate requires supplier, sku and date')
    }

    // upsert the supplied buyprice on the supplied date
    // for all skus with the same product name and supplier as the supplied sku
    // todo: could this be loaded from file?
    const query = `
      insert into ${this.tableName} (
        id,
        sku,
        date,
        month,
        buyprice,
        sellprice,
        discount
      )
      select
          uuid_generate_v4() as id,
          sku,
          date,
          date_trunc('month', date) as month,
          $4 as buyprice,
          null as sellprice,
          $5 as discount
      from (
        select
          p2.product_id as sku,
          $3::date as date
        from
          avocado.vw_products p1
          left join avocado.vw_products p2 on
            lower(replace(p1.product,' ','')) = lower(replace(p2.product,' ',''))
      where
        p1.product_id = $1 and lower(replace(p2.supplier,' ','')) = lower(replace($2,' ',''))
      ) as u
      on conflict (sku,date)
      do
      update set buyprice = EXCLUDED.buyprice;`
    const response = await this.pgConnection.query(query, [sku, supplier, date, buyprice, discount])
    return { id: sku, count: Number(response.rowCount) }
  }
}

class PriceApi extends EntityApi {
  constructor (state, pgConnection, agaveAdapter, logger) {
    const { user = {} } = state
    const username = user.name
    const opts = {pgConnection, username, logger}
    const adapter = new PricePGAdapter({...opts})

    // hack: sometimes the api is instantiated without a pgconnection, e.g. on the client
    // creating a pgadapter without a pgconnection will fail, but so will creating an entityapi
    // without an adapter so for that we just pass an empty object to get them to shut up
    super(adapter)

    if (pgConnection) {
      this.bulkUpdate = new PriceBulkUpdatePGAdapter({...opts})
      this.priceDaily = new PriceDailyPGAdapter({...opts})
      this.presentationCostBasis = new PresentationCostBasisPGAdapter({...opts})
      this.priceDate = new PriceDatePGAdapter({...opts})
      this.suggestion = new PriceSuggestionPGAdapter({...opts})
      this.suggestion.tiers = new PriceSuggestionTierPGAdapter({...opts})
      this.suggestion.summaries = new PriceSuggestionSummaryPGAdapter({...opts})
      const priceUpdateEntryAdapter = new PriceUpdateEntryPGAdapter({...opts})
      this.priceUpdate = new PriceUpdatePGAdapter({...opts, priceUpdateEntryAdapter})
      this.priceDataReplication = new PriceDataReplicationPGAdapter({...opts})
      this.markupMultiplier = new PriceMarkupMultiplierPGAdapter({...opts})
    }
    this.agaveAdapter = agaveAdapter
  }

  getProductsPrices (skus) {
    return this.agaveAdapter.post('price/get_products_prices', {skus})
  }
}

module.exports = PriceApi
