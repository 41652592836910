module.exports = find
module.exports.findById = findById
module.exports.findByIds = findByIds

const get = require('lodash/get')
const { parse } = require('../tools/smart-id')
const { getRequest } = require('../order/api/read/get-request')
const toShipmentId = require('./tools/to-shipment-id')
const toDocIdProperties = require('./tools/to-doc-id-properties')
const toLocationProperties = require('./tools/to-location-properties')
const docToVanRecord = require('./tools/doc-to-van-record')
const statuses = require('../van-data-models/van-status-model').vanStatuses
const docsToShipmentRecords = require('./tools/docs-to-shipment-records')
const offline = require('./tools/offline')
const draftAdjustmentId = require('./tools/draft-adjustment')
const { translateTerritoryAlias, productAliasesByMarket } = require('../product/tools')

const pluckDocs = result => result.rows.map((row) => row.doc)
const pluckIds = result => result.rows.map(row => row.id)

const isStatusValid = status => statuses.includes(status)

async function attachSnapshotDiff (state, shipmentCounts, snapshotCounts, service) {
  // Get product ids for counts
  const productIds = [...new Set([...Object.keys(shipmentCounts), ...Object.keys(snapshotCounts)])]
    .map(batchId => batchId.split(':manufacturer')[0])

  const products = await state.dal.product.getByIds(state, productIds)
  const productsForTranslation = productAliasesByMarket(products)

  // Convert counts to correct market alias
  const convertCounts = counts => {
    return Object.keys(counts).reduce((acc, batchId) => {
      const originalProductId = batchId.split(':manufacturer')[0]
      const convertedProductId = translateTerritoryAlias(originalProductId, productsForTranslation, service)
      const newBatchId = batchId.replace(originalProductId, convertedProductId)

      acc[newBatchId] = counts[batchId]
      return acc
    }, {})
  }

  shipmentCounts = convertCounts(shipmentCounts)
  snapshotCounts = convertCounts(snapshotCounts)

  const counts = {}

  // Get necessary details
  for (let batchId of [...new Set([...Object.keys(shipmentCounts), ...Object.keys(snapshotCounts)])]) {
    const shipmentCount = get(shipmentCounts, batchId, { quantity: 0 })
    const snapshotQty = get(snapshotCounts, `${batchId}.quantity`, 0)
    const diff = shipmentCount.quantity - snapshotQty
    const paymentType = get(snapshotCounts, `${batchId}.paymentType`) || get(shipmentCounts, `${batchId}.paymentType`)
    counts[batchId] = {
      ...shipmentCount,
      paymentType,
      difference: diff === 0 ? false : diff,
      ordered: snapshotQty
    }
  }

  return counts
}

async function findShipmentById (state, id, options) {
  const shipmentId = toShipmentId(id)
  const locationId = state.user.location.id

  const shipmentRows = await state.dal.shipment.getByPrefix(state, shipmentId)
  const docs = pluckDocs(shipmentRows)

  if (options.pendingAdjustments) {
    const adjustmentId = draftAdjustmentId(id)
    const pendingAdjustment = await state.dal.shipment.getById(state, adjustmentId, { catch404: true })
    if (pendingAdjustment) {
      docs.push(pendingAdjustment)
    }
  }

  const filtered = docs.filter(doc => {
    const { status } = docToVanRecord(doc, locationId)
    return isStatusValid(status)
  })

  const [shipment] = docsToShipmentRecords(locationId, filtered, options)

  if (!shipment) {
    return []
  }

  // Find the original order and include any differences between ordered/delivered
  const { orderId: orderIdFromDocs } = docs.find(d => d.orderId) || {}
  const orderId = parse(orderIdFromDocs || '').orderId
  if (options.includeOrder && orderId) {
    const orderAsShipmentSnapshot = await getRequest(state, {
      orderId,
      asShipments: true,
      useOriginal: true
    })
    if (orderAsShipmentSnapshot) {
      shipment.counts = await attachSnapshotDiff(state, shipment.counts, orderAsShipmentSnapshot.counts, options.userLocationService)
      shipment.deliveryFee = orderAsShipmentSnapshot.deliveryFee
      shipment.orderDeliveryDate = orderAsShipmentSnapshot.orderDeliveryDate
      shipment.orderCreatedDate = orderAsShipmentSnapshot.createdAt
    }
  }

  return [shipment]
}

async function findById (state, id, options = {}) {
  const shipments = await findShipmentById(state, id, options)
  if (shipments.length !== 1) {
    const error = new Error(`Shipments-find: unexpected result for "${id}", found: ${shipments.length}, expected 1`)
    error.status = 404
    throw error
  }

  return shipments[0]
}

function getLocation (state, params) {
  if (params && params.location) {
    return typeof params.location === 'string'
      ? params.location : params.location.id
  }

  return state.user.location.id
}

function transformResponse (docs, locationId, options) {
  const filteredDocs = docs.filter(doc => {
    // This is so invalid statuses get filtered out
    // from design doc based responses as well
    let { status } = toDocIdProperties(doc._id)
    return isStatusValid(status)
  })

  return docsToShipmentRecords(locationId, filteredDocs, options)
}

function responseToDocs (response) {
  return pluckDocs(response)
    .filter(x => x) // skip errors
}

function findShipmentsFast (state, locationId, locationIsGeoLocation, startdate, enddate) {
  /* istanbul ignore if */
  if (!state.shipmentsDb.query) {
    const error = new Error('mapreduce not available')
    error.status = 404
    return Promise.reject(error)
  }

  let findFunction = state.dal.shipment.getByLocation
  if (locationIsGeoLocation) {
    findFunction = state.dal.shipment.getByGeoLocation
  }

  if (!startdate && !enddate) {
    // NOTE:
    // when called without start/end date,
    // IF this location is offline available
    // (as given in id dispenser range)
    // this search will be offline only
    return findFunction(state, locationId, {
      include_docs: true
    })
      .then(responseToDocs)
  }

  // This one will try to retreive docs,
  // throw offline error if not available
  return findFunction(state, locationId, {
    startdate: startdate,
    enddate: enddate,
    include_docs: false
  })
    .then(res => {
      // Filter out which shipments have events within this span

      // 1. Create a set of ids that have components in this time span
      const shipmentIds = new Set()
      res.rows
        .filter(row => {
          const datekey = row.key[1]

          if (startdate && datekey < startdate) {
            return false
          }

          if (enddate && datekey > enddate) {
            return false
          }

          return true
        })
        .forEach(row => {
          const parts = row.id.split(':status:')
          if (parts.length === 2) {
            shipmentIds.add(parts[0])
          }
        })

      // 2. Get the complete documents for all those snapshots/changes/services
      //    in case an individual shipment has one doc in our range and some outside of it,
      //    this makes sure to include all of its ids
      const ids = res.rows
        .map(row => row.id)
        .filter(id => {
          return shipmentIds.has(id.split(':status:')[0])
        })

      return state.dal.shipment.getByKeys(state, {
        keys: ids,
        include_docs: true
      })
    })
    .then(responseToDocs)
}

function findShipmentsOldSchool (state, locationId, locationIsGeoLocation, startdate, enddate) {
  console.warn('fs-api: Using the slow shipment find implementation, needs pouchdb-mapreduce + design doc to be faster')

  /*
   * locationIsGeoLocation is kind of a shelf life feature
   * it can (and should!) be adapted to online offline
   * but it's slightly tricky to do that. Probably we'll end up
   * setting up an endpoint to call for that purpose.
   */
  if (state.onlineOffline) {
    throw new Error('Shipments in GeoLocation is not yet available to online-offline users')
  }

  // This is intentionally kept out of the dal ,
  // until locationIsGeoLocation is done for online-offline
  return state.shipmentsDb.allDocs({
    startkey: 'origin:',
    endkey: 'origin:\ufff0',
    include_docs: false
  })
    .then(pluckIds)
    .then(ids => {
      // filter docs by location provided in params or user location
      const filteredIds = ids.filter(id => {
        let {
          origin,
          destination,
          status
        } = toDocIdProperties(id)

        const hasValidStatus = isStatusValid(status)
        if (!hasValidStatus) {
          return false
        }
        if (origin) {
          const originId = toLocationProperties(origin).id
          if (originId === locationId ||
             (locationIsGeoLocation && originId.startsWith(locationId))) {
            return true
          }
        }
        if (destination) {
          const destinationId = toLocationProperties(destination).id
          if (destinationId === locationId ||
             (locationIsGeoLocation && destinationId.startsWith(locationId))) {
            return true
          }
        }
        return false
      })
      return state.shipmentsDb.allDocs({
        keys: filteredIds,
        include_docs: true
      })
    })
    .then(response => {
      const docs = responseToDocs(response)

      // If we don't filter by date, just return
      if (!startdate && !enddate) {
        return docs
      }

      // Filter only shipments that have
      // events between startkey and endkey

      // 1. Create a set of docs that matches
      //    this date range
      const shipmentIds = new Set()
      docs
        .filter(doc => {
          const datekey = doc.createdAt

          if (startdate && datekey < startdate) {
            return false
          }

          if (enddate && datekey > enddate) {
            return false
          }

          return true
        })
        .forEach(doc => {
          const parts = doc._id.split(':status:')
          if (parts.length === 2) {
            shipmentIds.add(parts[0])
          }
        })

      // 2. Return all snapshot, surveys etc that
      //    have docs that are touching touching this date range
      return docs
        .filter(doc => {
          return shipmentIds.has(doc._id.split(':status:')[0])
        })
    })
}

function find (state, params, options = {}) {
  // for SL, we use userAsDistributor to make all shipment
  // with different origin to behave as if they are coming
  // from the user location
  const docToShipmentOptions = {
    useLocationAsOrigin: options.userAsDistributor,
    pendingAdjustments: options.pendingAdjustments,
    includeOrder: options.includeOrder,
    user: state.user,
    checkSnapShotLocation: options.checkSnapShotLocation,
    userLocationService: options.userLocationService
  }

  if (typeof params === 'string') {
    // Find a single shipment with a specific id passed as a string
    return findShipmentById(state, params, docToShipmentOptions)
  }

  if (options.includeOrder) {
    throw new Error('Shipment: Order Data is only available when getting shipment by id')
  }

  if (options.pendingAdjustments) {
    throw new Error('Shipment: Pending Adjustments are only available when getting shipment by id')
  }

  if (typeof params === 'object' && params.shipmentNo) {
    return Promise.reject(new Error('shipment.find by properties is deprecated'))
  }
  const locationId = getLocation(state, params)

  const {
    locationIsGeoLocation,
    startdate,
    enddate
  } = params || {}

  // 1. try to find the shipments via design doc
  return findShipmentsFast(state, locationId, locationIsGeoLocation, startdate, enddate)
  // 2. that failed :/ let's go the old way
    .catch(err => {
      if (err.status === offline.OFFLINE_ERROR) {
        throw err
      }

      // To make this DAL work for PSM, we need to interpret this as
      // 'we're not syninc the DB' and therefore give an empty response back
      // that way PSM can pull ledger balances, shipments etc
      // even if they don't sync the db
      const missingDesignDoc = err.docId === '_design/store-api' && err.status === 404
      if (missingDesignDoc && state.onlineOffline) {
        console.log('shipments design doc not found, if this app is not using shipments, that is ok')
        return []
      }

      console.log('Error using the shipments find view:', err)
      return findShipmentsOldSchool(state, locationId, locationIsGeoLocation, startdate, enddate)
    })
    .then(res => transformResponse(res, locationId, docToShipmentOptions))
}

function findByIds (state, locationId, keys) {
  return state.dal.shipment.getByKeys(state, {
    keys,
    include_docs: true
  })
    .then(responseToDocs)
    .then(res => transformResponse(res, locationId, { }))
}
