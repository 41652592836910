import get from 'lodash/get'
import {
  setActive,
  setComplete,
  syncError
} from '../root/reducers/common/sync'
import { uuid } from 'pouchdb-utils'
import Checkpointer from 'pouchdb-checkpointer'
import generateReplicationId from 'pouchdb-generate-replication-id'

// Upload or download replication events
export function monitorReplicationEvents (upOrDownFeed, name, direction, dispatch) {
  upOrDownFeed
    .on('active', () => dispatch(setActive(name, direction)))
    .on('denied', error => dispatch(syncError(name, direction, error, 'monitorReplicationEvents denied')))
    .on('error', error => dispatch(syncError(name, direction, error, 'monitorReplicationEvents error')))
    .on('paused', error => {
      if (!error) {
        dispatch(setComplete(name, direction))
      } else {
        dispatch(syncError(name, direction, error, 'monitorReplicationEvents paused'))
      }
    })
}

// Spoof an upload checkpoint to avoid a large revs_diff on live replication.
export async function maybeSetUploadCheckpoint (remoteDb, localDb) {
  const uploadRepId = await generateReplicationId(localDb, remoteDb, {})
  let promise
  try {
    promise = await localDb.get(uploadRepId)
  } catch (e) {
    const { update_seq } = await localDb.info() // eslint-disable-line
    promise = writeCheckpoint(localDb, remoteDb, uploadRepId, update_seq, { writeSourceCheckpoint: true, writeTargetCheckpoint: true })
  }
  return promise
}

export function writeCheckpoint (firstDb, secondDb, replicationId, seq_no, options) { // eslint-disable-line
  const checkpointer = new Checkpointer(firstDb, secondDb, replicationId, {}, options)
  const session = uuid()
  return checkpointer.writeCheckpoint(seq_no, session) // eslint-disable-line
}

export async function saveDocsLocally (name, localDb, rows, dispatch) {
  const errorDocs = []
  let docs = []
  if (get(rows, '[0]._id')) {
    // response via _find:
    docs = rows
  } else {
    rows.forEach(row => {
      // no row.doc is filtering out _deleteds
      if (row.error || !row.doc) {
        errorDocs.push(row)
      } else {
        docs.push(row.doc)
      }
    })
  }

  if (errorDocs.length) {
    const errorMessage = 'saveDocsLocally received missing ids. Configured CouchDB URL and idDndpoint URL may point to different CouchDBs'
    dispatch(syncError(name, 'download', errorDocs, errorMessage))
  }
  if (docs.length) {
    try {
      await saveDocsInBatches(localDb, docs)
    } catch (e) {
      dispatch(syncError(name, 'download', e, 'saveDocsLocally errored on writing bulk docs'))
    }
  }
}

export async function saveDocsInBatches (db, docs, batchSize = 500) {
  const interations = Math.ceil(docs.length / batchSize) // e.g. 11 / 5 = 2.2 = 3
  let left
  let right
  let batch
  for (let i = 0; i < interations; i++) {
    left = i * batchSize
    right = batchSize * (i + 1)
    batch = docs.slice(left, right)
    await db.bulkDocs({ docs: batch, new_edits: false })
  }
}

export const useOnlineOffline = user => user.roles && user.roles.includes('feature:online-offline')

export async function getCouchDbInfo (remoteDb, name, dispatch) {
  try {
    return await remoteDb.info()
  } catch (error) {
    const message = `Field error fetching initial info() on db: ${name}`
    dispatch(syncError(name, 'download', error, message))
    throw new Error(message)
  }
}
