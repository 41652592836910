import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { CountBadge } from '@fielded/shared-ui'

import { selectProductSelection } from '../reducers/retailer/orderPlacement'
import { selectSubscribeSelection } from '../reducers/retailer/subscriptions'

function NavigationLink ({
  selected,
  to,
  icon,
  label,
  location,
  isRetailer,
  showBadgeLabel
}) {
  const productSelection = useSelector(selectProductSelection)
  const subscribeSelection = useSelector(selectSubscribeSelection)

  const productSelectionCount = isRetailer && Object.keys(productSelection).length
  const subscribeSelectionCount = isRetailer && Object.keys(subscribeSelection).length

  const count = productSelectionCount || subscribeSelectionCount
  const isProductsRoute = location && location.pathname === to // path
  const showIndicator = !!count && showBadgeLabel && !isProductsRoute

  const badgeLabelComponent = showIndicator && (
    <CountBadge
      className='bottom-nav-link__badge-label vs-u-row-centered'
      colorCode='accent'
      count={count}
    />
  )

  return (
    <Link
      to={to}
      className={classnames(
        'bottom-nav-link',
        {'bottom-nav-link--selected': selected})
      }
    >
      <div className='bottom-nav-link__icon'>
        {icon}
      </div>
      <div className='bottom-nav-link__label'>
        {label}
      </div>
      {badgeLabelComponent}
    </Link>
  )
}

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  isRetailer: PropTypes.bool,
  showBadgeLabel: PropTypes.bool
}

NavigationLink.defaultProps = {
  selected: false
}

export default NavigationLink
