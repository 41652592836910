import React, { useState } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { Link, useHistory } from 'react-router-dom'

import { Button, Checkbox, FilterInput, OptionsGroup, Page, StatusPill, Text, TextInput } from '@fielded/shared-ui'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'
import { filterByProp } from '@fielded/shared-ui/src/components/FilterInput'
import { IMMEDIATE_PURCHASE, PURCHASE } from '@fielded/fs-api/lib/shipment/tools/planning-types'
import { PAYMENT_STATUSES, PAYMENT_CHOICES } from '@fielded/fs-api/lib/order/constants'
import { getPrice } from '@fielded/fs-api/lib/product/tools'
import { getServiceForLocationId } from '@fielded/fs-api/lib/service/tools/territory-lookup'
import { SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'

import { getNavigationItem } from '../../../common/utils/navigationInfo'

import ProgressButton from '../common/ProgressButton'
import ShipmentHeader from '../common/ShipmentHeader'
import CountProduct from './CountProduct'
import { isDistribution as isTypeDistribution, isPSMDriver } from '../common/utils'

import { hasProductQuantityDiscrepancies } from '../common/makeDeliveryItems'
import { userIsAuthorised } from '../../../van-shared/utils/auth'
import { isPsm } from '../../reports/common/utils'
import {getFractionChecked} from '../../../common/utils/shipment'
import { OrderValueDifferentMessage } from '../components/OrderValueDifferentMessage'
import CountWarningModal from './CountWarningModal'

const sortingOptions = [
  {
    label: 'Alphabetically',
    value: 'alphabetical'
  },
  {
    label: 'By category',
    value: 'category'
  }
]

const filterProducts = (products, filterValue) => {
  if (!filterValue || filterValue === '') return products
  return filterByProp(products, filterValue, 'name')
}

const Count = ({
  batches,
  shipment,
  config,
  hasAdjustment,
  title,
  isReturnShipment,
  onProductUpdate,
  onToggleAll,
  onClickProceed,
  user,
  api,
  useProductTranslation,
  hasSelectedAllItems,
  deliveryItems = []
}) => {
  const history = useHistory()
  const [productFilterValue, setProductFilterValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  // I am keeping the 'alphabetical' order as a default value.
  // When I tried having the items mapped by category, I run into a race condition. The list of batches at first seemed to be undecorated and without a product information (additionalData.category) and the categories list was not created correctly.
  // If anyone wants to change it, it needs to be investigated why the product list is shown this way first as using loading state did not work for me in this case/
  const [sortingOption, setSortingOption] = useState('alphabetical')

  const sortedProducts = sortBy(batches, 'name')
  const filteredProducts = filterProducts(sortedProducts, productFilterValue)
  const fractionChecked = getFractionChecked(batches)
  const isDistribution = isTypeDistribution(shipment.statusType)
  const comment = get(shipment.comments, '0.comment')
  const country = get(shipment, 'origin.country')
  const locationId = get(shipment, 'origin.id')
  const state = get(shipment, 'origin.state')
  const userState = get(user, 'location.state')
  const isPurchase = [PURCHASE, IMMEDIATE_PURCHASE].includes(shipment.planningType)
  const isFPUser = userIsAuthorised(user, 'feature:userRole:fp')
  const isPSMUser = isPsm(config)
  const status = shipment.status

  // Do not show ledger link if the origin of the shipment and the user are not in the same state
  const showLedgerLink = (state === userState) && !isFPUser

  const wasPrepaid = shipment.paymentStatus === PAYMENT_STATUSES.paid
  const isToBePaidOnDelivery = shipment.paymentStatus === PAYMENT_STATUSES.unpaid && shipment.paymentChoice === PAYMENT_CHOICES.on_delivery
  const paidTotalValue = wasPrepaid ? shipment.totalAmount : 0

  const adjustedTotalValue = batches.reduce((sum, currentItem) => {
    const { quantity: adjustedQuantity } = currentItem
    const price = getPrice(currentItem.prices, shipment.createdAt) || Number(currentItem.unitPrice)
    const productValue = adjustedQuantity * price

    const hasVat = currentItem.vats && currentItem.vats.length > 0
    const vatMultiplier = hasVat ? currentItem.vats[0].vat / 100 : 0
    const productVatValue = productValue * vatMultiplier
    return sum + productValue + productVatValue
  }, 0)

  const categories = [...new Set(filteredProducts.map(product => get(product, 'additionalData.category')))].sort()

  const serviceId = useProductTranslation && getServiceForLocationId(locationId).service

  const productsPerCategory = filteredProducts.reduce((acc, product) => {
    const category = get(product, 'additionalData.category')
    if (category) {
      acc[category] ? acc[category].push(product) : acc[category] = [product]
    }
    return acc
  }, {})

  const onProceedToProductConfirmation = () => {
    if (!isPSMUser) {
      return onClickProceed()
    }

    const hasQuantityDiscrepancies = hasProductQuantityDiscrepancies(shipment, deliveryItems)
    if (hasQuantityDiscrepancies) {
      setIsModalOpen(hasQuantityDiscrepancies)
    } else {
      onClickProceed()
    }
  }

  const onCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Page>
      <ShipmentHeader
        breadcrumbItems={[getNavigationItem({item: 'shipments', config})]}
        shipment={shipment}
        config={config}
        title={title}
        hasShipmentDetails={isDistribution}
        isEditable={!isPSMUser}
      />
      {isPSMUser && (
        <Page.Panel narrow alignLeft reduceBottomMargin>
          <Page.Panel.Section>
            <StatusPill
              className={`delivery-location__status--${status}`}
              label='Status'
              value={capitalize(SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[status])}
            />
          </Page.Panel.Section>
        </Page.Panel>
      )}

      {hasAdjustment && <Page.Intro>
        <p className='count__edit-text'>You are currently editing the recorded quantity for this delivery.</p>
        <p>Uncheck the mark to make changes to previous values.</p>
      </Page.Intro>}

      <Page.Panel withMargin spread>
        {!isPSMUser && (
          <OptionsGroup
            className='vs-u-row vs-u-justify-end'
            withInlineLabel
            label='Sort items:'
            options={sortingOptions}
            defaultValue={sortingOption}
            onValueChange={(selected) => setSortingOption(selected)}
          />
        )}
        {isPurchase && (
          <OrderValueDifferentMessage
            country={country}
            paidTotal={paidTotalValue}
            currentTotal={adjustedTotalValue}
            isToBePaidOnDelivery={isToBePaidOnDelivery}
          />
        )}
        <Page.Panel.Section>
          <FilterInput
            placeholder='Find product …'
            value={productFilterValue}
            onValueChange={setProductFilterValue}
          />
        </Page.Panel.Section>
        {isPSMDriver(user) && (
          <Page.Panel.Section>
            <Checkbox.Stateless
              label='Select all'
              onChange={() => onToggleAll()}
              checked={hasSelectedAllItems}
            />
          </Page.Panel.Section>
        )}
        <Page.Panel.Section>
          <section className={classnames(
            'count',
            {'count--adjustments': hasAdjustment},
            {'count--with-sections': sortingOption === 'category'}
          )}>
            {sortingOption === 'category' && categories.map(category => {
              return (
                <div>
                  <Text.Heading
                    className='count__category-header'
                    size='small'
                  >
                    {category}
                  </Text.Heading>
                  {productsPerCategory[category].map(product => {
                    return (
                      <div
                        className='count__card-slot'
                        key={product.id}
                      >
                        <CountProduct
                          {...product}
                          isChecked={product.checked}
                          onToggleChecked={onProductUpdate}
                          hasAdjustment={hasAdjustment}
                          history={history}
                          api={api}
                          user={user}
                          locationId={locationId}
                          shipment={shipment}
                          originalTotal={shipment.collection ? get(shipment, `collection.${product.id}.quantity`, 0) : product.total}
                          unitPrice={product.unitPrice}
                          country={country}
                          translatedProductId={product._id}
                          serviceId={serviceId}
                          showLedgerLink={showLedgerLink}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}

            {sortingOption === 'alphabetical' && filteredProducts.map(product => {
              return (
                <div
                  className='count__card-slot'
                  key={product.id}
                >
                  <CountProduct
                    {...product}
                    isChecked={product.checked}
                    onToggleChecked={onProductUpdate}
                    hasAdjustment={hasAdjustment}
                    history={history}
                    api={api}
                    user={user}
                    locationId={locationId}
                    shipment={shipment}
                    originalTotal={shipment.collection ? get(shipment, `collection.${product.id}.quantity`, 0) : product.total}
                    unitPrice={product.unitPrice}
                    country={country}
                    translatedProductId={product._id}
                    serviceId={serviceId}
                    showLedgerLink={showLedgerLink}
                    isPSMUser={isPSMUser}
                  />
                </div>
              )
            })}
          </section>
        </Page.Panel.Section>
        {!filteredProducts.length && (
          <Page.Panel.Section>
              No products found {productFilterValue && `matching "${productFilterValue}"`}
          </Page.Panel.Section>
        )}
        {(comment && isReturnShipment) &&
        <Page.Panel.Section>
          <span>FP Comment</span>
          <TextInput
            className='confirmation-commment__input'
            name='comment'
            value={comment}
            readOnly
            rows={5}
          />
        </Page.Panel.Section>
        }
        <Page.Panel.Section>
          {isPurchase && (
            <OrderValueDifferentMessage
              country={country}
              paidTotal={paidTotalValue}
              currentTotal={adjustedTotalValue}
              isDetailed
              isToBePaidOnDelivery={isToBePaidOnDelivery}
            />
          )}
        </Page.Panel.Section>
      </Page.Panel>
      {hasAdjustment && (
        <div className='count-footer'>
          <Page.Footer>
            <Button
              onClick={onClickProceed}
              colorVariant={fractionChecked.checked === fractionChecked.total ? 'brand' : 'neutral'}
              fill='full'
            >Save adjustments</Button>
            <Button
              to={`/shipments/count/${shipment.snapshotId}`}
              component={Link}
            >Cancel and go back
            </Button>
          </Page.Footer>
        </div>
      )}
      {!hasAdjustment && (
        <Page.Footer layout='centered'>
          <ProgressButton
            onClick={onProceedToProductConfirmation}
            isComplete={fractionChecked.checked === fractionChecked.total}
            progressText={`${fractionChecked.checked} of ${fractionChecked.total}`}
            progressSupportingText='products delivered'
            submitLabelText='Proceed'
          />
        </Page.Footer>
      )}
      <CountWarningModal
        isModalOpen={isModalOpen}
        onClickProceed={onClickProceed}
        onCloseModal={onCloseModal}
      />
    </Page>
  )
}

export default Count
