const { DIRECT_ORDER_TYPES } = require('../../../allocation/config')

exports.getUniqueProductCount = getUniqueProductCount
function getUniqueProductCount (orders, isPOD) {
  const productSet = new Set()
  orders.forEach(order => {
    Object.entries(order.products).forEach(([productId, productData]) => {
      // We want to have an option to only see the POD items as well
      if (!isPOD || (isPOD && productData.allocationType === DIRECT_ORDER_TYPES.PAY_ON_DELIVERY)) {
        productSet.add(productId)
      }
    })
  })
  return productSet.size
}

exports.getUniqueLocationCount = getUniqueLocationCount
function getUniqueLocationCount (orders) {
  const locationSet = new Set(orders.map(order => order.destinationId))
  return locationSet.size
}

exports.getProperRouteIdentifier = getProperRouteIdentifier
function getProperRouteIdentifier (prop) {
  let routeToCheck = prop.routeId || ''

  if (routeToCheck.includes('funder:')) {
    routeToCheck = prop.routeId.replace('funder:', 'route:')
  }

  return routeToCheck
}
