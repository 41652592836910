const XLSX = require('@sheet/core')
const { EntityApi } = require('../common')
const { reportingPeriodToDate } = require('../tools')
const { startOfMonth, format, addMonths } = require('date-fns')
const ExternalForecastAdapter = require('./external-forecast-adapter')
const { subTZ, addTZ } = require('../report/tools/tz')
const { PSM_WAREHOUSE_DEFINITIONS } = require('../order/tools/read/warehouse-code-to-virtual')

class ExternalForecastRestAdapter {
  constructor (restAdapter) {
    this.path = 'external_forecast'
    if (restAdapter && restAdapter.extend) {
      restAdapter.extend(this, this.path)
      this.restAdapter = restAdapter
    }
  }

  async delete (body) {
    const payload = { method: 'delete', body: JSON.stringify(body) }
    return this.restAdapter.fetcher(this.path, payload)
  }

  async getWarehouses ({ productId, startDate, endDate }) {
    return this.restAdapter.get(`${this.path}/warehouses`, { productId, startDate, endDate })
  }
}

class ExternalForecastApi extends EntityApi {
  constructor (
    state,
    logger,
    pgConnection,
    agaveAdapter,
    productApi
  ) {
    const tableName = 'avocado.data_external_forecast'
    const columns = [ 'id', 'created_at', 'updated_at', 'location_fsid', 'product_sku', 'consumption', 'month' ]
    const idField = 'id'
    const opts = { strictColumns: true, addUuid: true }

    const { user = {} } = state

    const adapter = !pgConnection
      ? new ExternalForecastRestAdapter(agaveAdapter)
      : new ExternalForecastAdapter(pgConnection, tableName, user.name, columns, idField, logger, opts)

    super(adapter)

    this.state = state
    this.productApi = productApi
    this.pgConnection = pgConnection
  }

  trimSpacesFromKeys (obj) {
    if (typeof obj !== 'object' || obj === null) {
      // If the input is not an object or is null, return as is
      return obj
    }

    const result = {}

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.trim() // Trim leading and trailing spaces from the key
        result[newKey] = obj[key]
      }
    }

    return result
  }

  formatConsumption (numberAsText) {
    const formattedText = numberAsText
      .trim()
      .replace(/,/g, '')
    const num = parseFloat(formattedText)
    return isNaN(num) ? null : num
  }

  async bulkUpdate (data) {
    if (this.pgConnection) {
      this.adapter.upsert(
        data,
        { conflictIdColumns: ['location_fsid', 'product_sku', 'month'] }
      )
    } else {
      this.adapter.create(data)
    }
  }

  firstDayOfMonth (date) {
    const UTCDate = subTZ(new Date(date))
    const firstDayDateTime = startOfMonth(UTCDate)
    return format(firstDayDateTime, 'YYYY-MM-DD')
  }

  /**
 * Returns a map of product SKUs to consumption for the given location and month range.
 *
 * @param {string} locationId - The location ID.
 * @param {string} startDate - The start date.
 * @param {string} endDate - The end date.
 * @returns {Object} A map of product SKUs to monthly consumption values for the given location and month range.
 */
  async getConsumptions ({locationId, startDate, endDate}) {
    if (this.pgConnection) {
      const firstMonthDate = this.firstDayOfMonth(startDate)
      const lastMonthDate = this.firstDayOfMonth(endDate)

      const filter = {
        month: { startMonth: firstMonthDate, endMonth: lastMonthDate }
      }
      if (locationId) {
        filter.location_fsid = locationId
      }

      // We first fetch all rows for the given location_fsid and month range
      const dbResponse = await this.adapter.getList({
        filter,
        limit: Number.MAX_SAFE_INTEGER
      })

      // We now build and return a map of product_sku to consumption for the given month range
      return dbResponse.results.reduce((acc, r) => {
        const normalizedMonthDate = addTZ(r.month).toJSON()
        const productId = [`product:${r.product_sku.toLowerCase()}`]
        acc[productId] = acc[productId] || {}
        acc[productId][r.location_fsid] = acc[productId][r.location_fsid] || {}
        acc[productId][r.location_fsid][normalizedMonthDate] = r.consumption
        return acc
      }, {})
    } else {
      const params = {locationId, startDate, endDate}
      if (!params.locationId) {
        delete params.locationId
      }
      return this.adapter.list(params)
    }
  }

  // This as backend-ony method i.e. requiring adapter to be an instance of PGAdapter
  /**
   * Returns the list of warehouses that we have a forecast for
   *
   * @returns {Array} the list of warehouses
   */
  async getWarehouses ({productId, startDate, endDate} = {}) {
    if (this.pgConnection) {
      const startMonthDate = this.firstDayOfMonth(startDate)
      const endMonthDate = this.firstDayOfMonth(endDate)

      return this.adapter.listProductWarehouses({
        productId,
        startDate: startMonthDate,
        endDate: endMonthDate,
        limit: Number.MAX_SAFE_INTEGER
      })
    } else {
      // we don't have a PGAdapter but we have an agaveAdapter
      return this.adapter.getWarehouses({ productId, startDate, endDate })
    }
  }

  // This as backend-ony method i.e. requiring adapter to be an instance of PGAdapter
  /**
   * Delete the forecasts matching the provided filter conditons
   *
   * @param {Object} params - The filter conditions (startDate, endDate, programId)
   */
  async deleteForecasts ({ programId, startDate, endDate }) {
    return this.adapter.delete({
      programId,
      startDate: this.firstDayOfMonth(startDate),
      endDate: this.firstDayOfMonth(endDate)
    })
  }

  // This is a frontend-only method
  async prepareUpload (formData, uploadData) {
    const formDataByKey = {}
    for (const pair of formData) {
      formDataByKey[pair[0]] = pair[1]
    }

    const {date: deliveryDate, reportingCycle, program} = uploadData

    const fileData = await new Promise((resolve, reject) => {
      const fr = new window.FileReader()
      fr.onload = function () {
        resolve(fr.result)
      }

      fr.onerror = function () {
        reject(fr)
      }

      fr.readAsArrayBuffer(formDataByKey.forecastData)
    })

    const buffer = Buffer.from(fileData)

    const workbook = XLSX.read(buffer, {type: 'buffer'})
    const [firstSheetName] = workbook.SheetNames
    const firstSheet = workbook.Sheets[firstSheetName]

    const firstMonthConsumptionColumnName = firstSheet['D1'].v
    const lastMonthConsumptionColumnName = firstSheet['E1'].v

    const jsonRecords = XLSX.utils.sheet_to_json(firstSheet)

    const productsInProgram = await this.productApi.listForPrograms([program], {getProductObjects: false})
    const productsInProgramSet = new Set(productsInProgram)

    const locationIds = new Set(
      Object.keys(PSM_WAREHOUSE_DEFINITIONS).map(warehouseCode => PSM_WAREHOUSE_DEFINITIONS[warehouseCode]._id)
    )

    const dbRows = jsonRecords.map(r => {
      const cleanRecord = this.trimSpacesFromKeys(r)
      const productSKU = cleanRecord['Product Code'].trim()

      if (!productsInProgramSet.has(`product:${productSKU.toLowerCase()}`)) {
        const programName = program.replace(/^program:/, '')
        throw new Error(`Upload failed, some uploaded products (e.g. ${productSKU}) do not belong to the selected '${programName}' program. Please review your product list`)
      }

      const commonRecordValues = {
        location_fsid: cleanRecord.Warehouse.trim(),
        product_sku: productSKU,
        scale_factor: 1
      }

      if (!locationIds.has(commonRecordValues.location_fsid)) {
        throw new Error(`Upload failed, some uploaded warehouses (e.g. ${commonRecordValues.location_fsid}) are not valid. Please review your warehouses list and check instructions`)
      }

      const firstMonthRecord = {
        ...commonRecordValues,
        month: reportingPeriodToDate(uploadData.reportingCycle),
        consumption: this.formatConsumption(cleanRecord[firstMonthConsumptionColumnName])
      }

      const secondMonthRecord = {
        ...commonRecordValues,
        month: format(addMonths(firstMonthRecord.month, 1), 'YYYY-MM-DD'),
        consumption: this.formatConsumption(cleanRecord[lastMonthConsumptionColumnName])
      }

      return [firstMonthRecord, secondMonthRecord]
    }).flat()

    return {
      deliveryDate,
      reportingCycle,
      program,
      dbRows
    }
  }
}

module.exports = ExternalForecastApi
