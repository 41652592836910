const get = require('lodash/get')
const pick = require('lodash/pick')
const shortid = require('shortid')
const { EntityApi } = require('../common')
const { wrapEntityApi } = require('../utils/wrap-api')
const apiMethods = require('./api/')
const reserveStockMethods = require('./reserve-stock/')
const generateOrderNotesMethods = require('./order-notes')
const tools = require('./tools')
const { exportLMD, exportLMDData } = require('./export-lmd')
const OrderDataAdapter = require('./data-access/pouchdb-adapter/order-pouch-adapter')
const {
  createForRoutes: createForRoutesRaw,
  createForRoutesPreview: createForRoutesPreviewRaw,
  createWithSnapshots: createWithSnapshotsRaw
} = require('./api/write/create-for-routes')

const {
  createResupplySnapshots,
  resupplyImport
} = require('./api/write/resupply-import')

const {
  createResupplyPlan
} = require('./api/write/create-resupply-plan')
const createFollowUpOrder = require('./api/write/create-follow-up-order')

const {getGroupDetails, getLedgerForSupplier} = require('./api/read/get-group-details')
const {getHeldStock} = require('./api/read/get-held-stock')
const {confirmGroup} = require('./api/write/confirm-group')
const {createForLocation} = require('./api/write/create-for-location')
const {getLocationOrderDetails} = require('./api/read/get-location-order-details')
const {updateOrdersOnDestination} = require('./api/write/update-orders-on-destination')
const {bulkUpdateSupplierSubOrder} = require('./api/write/bulk-update-supplier-suborder')
const {getOrder, getGroup} = require('./api/read/get')
const {createRequest} = require('./api/write/create-request')
const {listRequests} = require('./api/read/list-requests')
const {listOpenOrders} = require('./api/read/list-open-orders')
const { switchSupplier } = require('./api/write/switch-supplier')
const { updateSubOrderWithRoute } = require('./api/write/update-suborder-route')

const { getAllSuppliersBatch } = require('./api/read/get-all-suppliers-batch')
const { getSupplierIdsForLocation } = require('./api/read/get-supplier-ids-for-location')
const { advanceShipmentSnapshot } = require('./api/write/advance-shipment-snapshot')
const { createExternalShipmentSnapshots } = require('./api/write/create-external-shipment-snapshots')
const { SupplyPlanPGAdapter } = require('./data-access/supply-plan-pg-adapter')
const { createSnapshot } = require('./tools/write/snapshot-create')
const { getFunderOnProgram } = require('./tools/utils')
const DataOrderAdapter = require('./data-access/data-order-pg-adapter')
const { SUPPLY_PLAN_STATUS, ORDER_TYPES } = require('./constants')

// NB this doesn't work in standalone outside of the sc constructor.
// just avoiding re-writing method names here that are in the /api.
const rawMethods = Object.assign({}, apiMethods, reserveStockMethods, generateOrderNotesMethods, {exportLMD, exportLMDData, getAllSuppliersBatch, getSupplierIdsForLocation, tools})

class OrderApi extends EntityApi {
  constructor (state, restAdapter, agaveAdapter, logger, pgConnection) {
    const { user, orderDb } = state
    const adapter = new OrderDataAdapter(user, orderDb)
    super(adapter)

    if (pgConnection) {
      this.supplyPlanPGAdapter = new SupplyPlanPGAdapter(logger, pgConnection, user.name)
      this.orderAdapter = new DataOrderAdapter(pgConnection, user.name, logger)
    }

    this.agaveAdapter = agaveAdapter

    // TODO: remove this when all raw methods have been ported
    Object.assign(this, wrapEntityApi(rawMethods, state))
    this.state = state
  }

  async get (params) {
    return getOrder(this.state, params)
  }

  async createForRoutes (params) {
    return createForRoutesRaw(this.state, params, this.mainApi)
  }
  async createResupplySnapshots (params, parseCsv = true) {
    return createResupplySnapshots(this.state, params, parseCsv)
  }

  async resupplyImport (params) {
    return resupplyImport(this.state, params, this.mainApi)
  }

  async createForRoutesPreview (params) {
    return createForRoutesPreviewRaw(this.state, params, this.mainApi)
  }

  async createWithSnapshots (params) {
    return createWithSnapshotsRaw(this.state, params, this.mainApi)
  }

  async getGroupDetails (groupId) {
    return getGroupDetails(this.state, this.mainApi, groupId)
  }

  async getHeldStock (params) { // { locationId, filterAvailable }
    if (this.agaveAdapter) {
      return this.agaveAdapter.get('stock/supplier-stock', params)
    }
    return getHeldStock(this.state, this.mainApi, params)
  }

  async getLedgerForSupplier (params) {
    return getLedgerForSupplier(this.state, this.mainApi, params)
  }

  async confirmGroup (params, cachedGroup) {
    if (!cachedGroup) {
      cachedGroup = await this.getGroupDetails(params.groupId)
    }
    return confirmGroup(this.state, this.mainApi, params, cachedGroup)
  }

  async getLocationOrderDetails (...params) {
    return getLocationOrderDetails(this.state, this.mainApi, ...params)
  }

  async createForLocation (...params) {
    return createForLocation(this.state, this.mainApi, ...params)
  }

  async updateOrdersOnDestination (...params) {
    return updateOrdersOnDestination(this.state, this.mainApi, ...params)
  }

  async bulkUpdateSupplierSubOrder (...params) {
    return bulkUpdateSupplierSubOrder(this.state, this.mainApi, ...params)
  }

  async createRequest (...params) {
    return createRequest(this.state, this.mainApi, ...params)
  }

  async listRequests (...params) {
    return listRequests(this.state, this.mainApi, ...params)
  }

  async listOpenOrders (...params) {
    return listOpenOrders(this.state, this.mainApi, ...params)
  }

  async switchSupplier (...params) {
    return switchSupplier(this.state, this.mainApi, ...params)
  }

  async updateSubOrderWithRoute (...params) {
    return updateSubOrderWithRoute(this.state, this.mainApi, ...params)
  }

  async createResupplyPlan (...params) {
    return createResupplyPlan(this.state, this.mainApi, ...params)
  }

  async createFollowUpOrder (...params) {
    return createFollowUpOrder(this.state, this.mainApi, ...params)
  }

  async getAllSuppliersBatch (...params) {
    return getAllSuppliersBatch(this.state, ...params)
  }

  async getSupplierIdsForLocation (...params) {
    return getSupplierIdsForLocation(this.state, ...params)
  }

  async advanceShipmentSnapshot (...params) {
    return advanceShipmentSnapshot(this.state, this.mainApi, ...params)
  }

  async createExternalShipmentSnapshots (...params) {
    return createExternalShipmentSnapshots(this.state, this.mainApi, ...params)
  }

  async getSupplyPlan (params) {
    return this.agaveAdapter
      ? this.agaveAdapter.get(`order/supply-plan/${params.groupId}`)
      : this.supplyPlanPGAdapter.getOne(params.groupId)
  }

  async updateSupplyPlan (params) {
    const { groupId } = params
    if (this.agaveAdapter) {
      return this.agaveAdapter.update(`order/supply-plan/${groupId}`, params)
    }
    return this.supplyPlanPGAdapter.update({order_group_id: groupId, ...params})
  }

  async approveSupplyPlan (params) {
    const { groupId } = params
    const supplyPlan = await this.getSupplyPlan({ groupId })
    const transfers = await this.setSupplyPlanTransfersStatus({ groupId, status: 'accepted' })
    const updateResult = await this.updateSupplyPlan({
      ...supplyPlan,
      groupId,
      status: SUPPLY_PLAN_STATUS.APPROVED
    })
    return { ...transfers, ...updateResult }
  }

  async submitSupplyPlan (params) {
    return this.updateSupplyPlan({...params, status: SUPPLY_PLAN_STATUS.PLANNED})
  }

  async createSupplyPlan (params) {
    if (this.agaveAdapter) {
      return this.agaveAdapter.post('order/supply-plan', params)
    } else {
      const { products, removePrevious, ...supplyPlanParams } = params
      // Remove any previous supply plans that conflict, if needed
      if (removePrevious) {
        const deleteFilter = pick(supplyPlanParams, ['start_date', 'end_date', 'program_id'])
        await this.supplyPlanPGAdapter.deleteWhere({filter: deleteFilter})
      }

      // Create parent, wait for it
      const supplyPlan = await this.supplyPlanPGAdapter.create({...supplyPlanParams, order_group_id: shortid.generate()})
      // Create children, if any, based on the parent supply plan
      return this.supplyPlanPGAdapter.createProductLines(supplyPlan.id, products) // params.products in the form [p.product_sku, p.scale_factor]]
        .then(products => { return {...supplyPlan, products} })
    }
  }

  async deleteSupplyPlan (params) {
    const { groupId } = params
    if (this.agaveAdapter) {
      return this.agaveAdapter.delete('order/supply-plan', groupId)
    } else {
      await this.supplyPlanPGAdapter.deleteWhere({filter: { order_group_id: groupId }})
      await this.setSupplyPlanTransfersStatus({ groupId, status: 'cancelled' })
    }
  }

  async createSupplyPlanProduct ({supplyPlanId, productSku, scalingFactor}) {
    if (this.agaveAdapter) {
      return this.agaveAdapter.post(
        `order/supply-plan/${supplyPlanId}/product`,
        { productSku, scalingFactor }
      )
    }
    if (this.supplyPlanPGAdapter) {
      return this.supplyPlanPGAdapter.createProductLine(supplyPlanId, {
        product_sku: productSku,
        scale_factor: scalingFactor
      })
    }
  }

  async listSupplyPlans (params) {
    return this.agaveAdapter
      ? this.agaveAdapter.list('order/supply-plan', params)
      : this.supplyPlanPGAdapter.getList(params)
  }

  async createSupplyPlanTransfer ({groupId, programId, destinationId, supplierId, products, deliveryDate}) {
    const funderId = getFunderOnProgram(destinationId, programId)

    const snapshot = createSnapshot({
      groupId,
      orderId: shortid.generate(),
      suborderId: shortid.generate(),
      user: this.state.user,
      programId,
      supplierId,
      destinationId,
      funderId,
      orderType: ORDER_TYPES.interwarehouse,
      products,
      deliveryDate,
      status: 'new',
      timestamp: new Date().toJSON()
    })

    await this.state.dal.order.bulkDocs(this.state, [snapshot])
    return tools.docsToOrderEntity({docs: [snapshot], status: 'new', withSnapshots: true})
  }

  async setSupplyPlanTransfersStatus ({ groupId, status }) {
    try {
      const group = await getGroup(this.state, { groupId, withSnapshots: true })
      const {orders} = group
      const latestSnapshotsToUpdate = orders
        .map(order => {
          return get(order, ['snapshotsByStatus', order.status, '0'], null)
        })
        .filter(snapshot => snapshot !== null)
      return Promise.all(
        latestSnapshotsToUpdate.map((order) => {
          const snapshot = {
            parentSnapshot: { ...order, status },
            products: order.products,
            user: this.state.user,
            timestamp: new Date().toJSON()
          }
          return this.state.dal.order.update(
            this.state,
            tools.updateSnapshot(snapshot)
          )
        })
      )
    } catch (error) {
      console.error('Error accepting supply plan transfers:', error)
      throw error // Re-throw the error after logging it
    }
  }

  async getOrder (params) {
    if (this.agaveAdapter) {
      return this.orderAdapter.getOrder(`order/${params.orderId}`)
    }
    return []
  }

  async listOrdersExport (params) {
    if (this.agaveAdapter) {
      return this.agaveAdapter.post('order/export', params)
    } else {
      return this.orderAdapter.listOrdersExport(params)
    }
  }

  async createSLBulkImportSnapshots (data, {date} = {}) {
    // const { products } = await this.mainApi.product.listForLocations([location._id])
    const locations = await this.mainApi.location.listAll()
    const result = tools.createSLBulkImportSnapshots(this.mainApi, data, this.state.user, locations)
    return result
  }
}

module.exports = rawMethods
module.exports.OrderApi = OrderApi
