const get = require('lodash/get')
const { daysToMonths } = require('./psm-thresholds')

const forecastToLevels = (forecast, timeUnit = 'day') => {
  const products = Object.keys(forecast.dailyDemand).reduce((products, productId) => {
    const dailyDemand = forecast.dailyDemand[productId]
    const safetyStock = forecast.safetyStock[productId]
    const supplyPlan = get(forecast.supplyPlans.products, productId, forecast.supplyPlans.default)

    const safetyStockDays = dailyDemand === 0 ? 0 : safetyStock / dailyDemand
    const reOrderDays = safetyStockDays + (supplyPlan.leadTimeDays || 0)
    const maxDays = safetyStockDays + supplyPlan.supplyPeriodDays

    let min = safetyStock
    let reOrder = reOrderDays * dailyDemand
    let max = maxDays * dailyDemand

    let monthlyDemand
    if (timeUnit === 'month') {
      monthlyDemand = dailyDemand * 365 / 12
      min = daysToMonths(safetyStockDays) * monthlyDemand
      reOrder = daysToMonths(reOrderDays) * monthlyDemand
      max = daysToMonths(maxDays) * monthlyDemand
    }

    products[productId] = {
      min: {
        days: safetyStockDays,
        forecast: Math.ceil(min),
        [timeUnit === 'month' && 'months']: daysToMonths(safetyStockDays)
      },
      reOrder: {
        days: reOrderDays,
        forecast: Math.ceil(reOrder),
        [timeUnit === 'month' && 'months']: daysToMonths(reOrderDays)
      },
      max: {
        days: maxDays,
        forecast: Math.ceil(max),
        [timeUnit === 'month' && 'months']: daysToMonths(maxDays)
      },
      dailyForecast: dailyDemand,
      [timeUnit === 'month' && 'monthlyForecast']: monthlyDemand
    }

    return products
  }, {})

  return ({ products })
}

module.exports = forecastToLevels
