const get = require('lodash/get')
const { getByIds: getLocationsByIds } = require('../../../location')
const { getProductAliasMasterData } = require('../master-data')
const { list: listFunders } = require('./../../../funders')
const { list: listRoutes } = require('./../../../routes')
const tools = require('../../tools')
const { time, log } = require('../../tools/logging')

exports.getSuborder = getSuborder
async function getSuborder (
  state,
  {suborderId, alias = 'one', date = new Date().toJSON(), logging = false, useGenericParent = false, raw = false}
) {
  const timeOrder = time()
  const doc = await state.dal.order.getSuborder(state, {suborderId})
  log('got order', logging, timeOrder)
  if (!doc) {
    const err = new Error(`No accepted suborder with id "${suborderId}" found.`)
    err.status = 404
    throw err
  }

  if (raw) return doc

  const locationIds = [doc.destinationId, doc.supplierId]

  const locationEntities = await getLocationsByIds(state, locationIds, date)

  const warehouseCodeMap = locationIds
    .reduce((acc, locationId, index) => {
      const locationEntity = locationEntities.find(location => location._id === locationId) || {}
      acc[locationId] = get(locationEntity, 'additionalData.warehouseCode')
      return acc
    }, {})

  const timeMasterData = time()
  const batches = Object.values(doc.products).flatMap(product => Object.keys(product.batches || {}))
  const productMasterData = await getProductAliasMasterData(state, {productIds: Object.keys(doc.products), batches})
  const funders = await listFunders(state)
  const routes = await listRoutes(state)

  log('got order master data', logging, timeMasterData)
  return tools.getSuborder({doc, alias, warehouseCodeMap, funders, routes, productMasterData, useGenericParent})
}

exports.getOrder = getOrder
async function getOrder (state, {orderId, withSnapshots = false, useAcceptedStatus = false}) {
  tools.validateParams('get', arguments[1])
  const locationId = state.user.location.id
  const snapshots = await state.dal.order.get(state, {orderId})
  // `updatedAt` is sometimes the number of ms since epoch
  for (const s of snapshots) {
    if (typeof s.updatedAt === 'number') {
      s.updatedAt = new Date(s.updatedAt).toJSON()
    }
  }
  const status = tools.getUserStatus(state.user)
  if (snapshots.length === 0) {
    return null
  }
  return tools.docsToOrderEntity({docs: snapshots, withSnapshots, locationId, status, useAcceptedStatus})
}

exports.getGroup = getGroup
async function getGroup (state, {groupId, useAcceptedStatus = false, withSnapshots = false}) {
  tools.validateParams('getGroup', arguments[1])
  const snapshots = await state.dal.order.getGroup(state, {groupId, useAcceptedStatus})
  const status = tools.getUserStatus(state.user)
  const locationId = state.user.location.id
  const orders = tools.docsToOrderEntities({
    docs: snapshots, withSnapshots, status, locationId, useAcceptedStatus
  })
  // TODO: next two lines can be merged into a tools.getGroupInfo
  const programId = orders.length ? orders[0].programId : null
  const {status: groupStatus, statusDisplayName, isComplete, orderType} = tools.getGroupStatus(orders)
  return {orders, status: groupStatus, statusDisplayName, programId, isComplete, orderType}
}

exports.getOrderFromLocationId = getOrderFromLocationId
async function getOrderFromLocationId (state, { groupId, locationId }) {
  if (!groupId || !locationId) {
    throw Error('Missing required parameters')
  }
  const snapshots = await state.dal.order.getGroup(state, {groupId})
  return snapshots.filter(order => order.destinationId === locationId)
}

exports.getShipmentsOnOrder = getShipmentsOnOrder
async function getShipmentsOnOrder (state, orderId) {
  const shipments = await state.dal.order.getShipmentsOnOrder(state, orderId)
  return shipments
}
