/* global alert */
import React, { lazy, Suspense, Fragment, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, Redirect, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'
import { isBefore, isSameDay } from 'date-fns'

import { Backdrop, Loading, Toast } from '@fielded/shared-ui'
import withOffline from '@fielded/shared-ui/src/common/offline'
import { isLocationSubscriptionBased } from '@fielded/fs-api/lib/location/tools'
import { MEMBERSHIPS } from '@fielded/shared-ui/src/utils/membership-type-constants'

import ShipmentsApp from '../subapps/shipments/root/Root'
import VanStockCount from '../subapps/reports/root/Root'
import InformationApp from '../subapps/information'
import { isSystemDateOff } from '../utils/system-date-off'
import { getPendingContracts } from '../utils/contracts.js'
import { withApi } from '../common/ApiProvider'
import { withAuth } from '../common/AuthenticationProvider'
import ErrorBoundary from '../common/ErrorBoundary'
import { hasOwnDatabase } from '../common/utils/user-db-sync'
import { isLeadBeforeSync, isLeadUser } from '../common/utils'
import { userIsAuthorised } from '../van-shared/utils/auth'
import { checkNotifications } from '../utils/notifications'

import MainMenu from './MainMenu/MainMenu'
import BottomNavigation from './BottomNavigation/BottomNavigation'
import PrivateRoute from '../login/PrivateRoute'
import HomeContainer from '../home/HomeContainer'
import ProfileContainer from '../subapps/settings/containers/ProfileContainer'
import ProfileDocumentContainer from '../subapps/settings/containers/ProfileDocumentContainer'
import withConfig from '../van-shared/hoc/withConfig'
import { hasFeature } from '../van-shared/utils/features'
import OfflineLoadingError from '../common/OfflineLoadingError'
import { cfLogUserDetail } from '../common/cf-adapter'
import { initSync } from '../sync'
import CurrentEnvironmentBanner from './CurrentEnvironmentBanner/CurrentEnvironmentBanner'
import AccountBlockedBanner from './AccountBlockedBanner/AccountBlockedBanner'
import MembershipBanner from './MembershipBanner/MembershipBanner'

import { selectTimestampToNextSessionCall, selectUserSession, timestampUpdate } from './reducers/common/userSession'
import { AUTH_ERROR, syncSelection } from './reducers/common/sync'
import { getLocation } from './reducers/common/location'
import { selectFeatures, updateOnboarding } from './reducers/common/featureOnboarding'
import { getFinances } from './reducers/retailer/finances/'
import { canCallEndpoint } from '../common/utils/redux-timestamp-call'
import { isShelflife } from '../van-shared/utils/utils'
import { USER_ROUTES } from '../common/utils/get-user-routes'
import AdminLoginBanner from './AdminLoginBanner/AdminLoginBanner'

const FsAlerts = lazy(() => import(/* webpackChunkName: "feature-lazy-alerts" */ '../subapps/alerts/root/Root'))
const FsSettings = lazy(() => import(/* webpackChunkName: "feature-lazy-settings" */ '../subapps/settings/root/Root'))
const FacilityProducts = lazy(() => import(/* webpackChunkName: "feature-lazy-facility-products" */ '../subapps/facility-products/root/Root'))
const VanAnalytics = lazy(() => import(/* webpackChunkName: "feature-lazy-analytics" */ '../subapps//analytics/root/Root'))
const OrdersApp = lazy(() => import(/* webpackChunkName: "feature-lazy-orders" */ '../subapps/orders'))
const RetailerApp = lazy(() => import(/* webpackChunkName: "feature-lazy-retailer" */ '../subapps/retailer/root/Root'))
const NotificationsApp = lazy(() => import(/* webpackChunkName: "feature-lazy-notifications" */ '../subapps/notifications'))
const LongHaulOrdersApp = lazy(() => import(/* webpackChunkName: "feature-lazy-longhaul" */ '../subapps/longhaul-orders'))

const Alerts = ({history, showCountSyncBanner}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <FsAlerts
      rootHistory={history}
      showCountSyncBanner={showCountSyncBanner}
      routerParams={{basename: '/alerts'}}
    />
  </Suspense>

const Analytics = ({history}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <VanAnalytics
      rootHistory={history}
      routerParams={{basename: '/analytics'}}
    />
  </Suspense>

const Shipments = ({history, isSyncFinished, percentComplete}) => // eslint-disable-line react/prop-types
  <ShipmentsApp
    rootHistory={history}
    routerParams={{basename: '/shipments'}}
    isSyncFinished={isSyncFinished}
    percentComplete={percentComplete}
  />

const Reporting = ({history, showCountSyncBanner, showShipmentSyncBanner, isSyncFinished, percentComplete}) => // eslint-disable-line react/prop-types
  <VanStockCount
    rootHistory={history}
    showCountSyncBanner={showCountSyncBanner}
    showShipmentSyncBanner={showShipmentSyncBanner}
    routerParams={{basename: '/reporting'}}
    isSyncFinished={isSyncFinished}
    percentComplete={percentComplete}
  />

const Orders = ({history}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <OrdersApp
      rootHistory={history}
    />
  </Suspense>

const Settings = ({history}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <FsSettings
      rootHistory={history}
      routerParams={{basename: '/settings'}}
    />
  </Suspense>

const RetailerProducts = ({history}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <FacilityProducts
      rootHistory={history}
      routerParams={{basename: '/products'}}
    />
  </Suspense>

const Retailer = ({history}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <RetailerApp
      rootHistory={history}
      routerParams={{basename: '/retailer'}}
    />
  </Suspense>

const Notifications = ({history}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <NotificationsApp />
  </Suspense>

const LongHaulOrders = ({history}) => // eslint-disable-line react/prop-types
  <Suspense fallback={<Loading />}>
    <LongHaulOrdersApp />
  </Suspense>

const checkHomeRedirectRoute = (isRetailer, isLead, isPatientUser) => {
  if (isRetailer) {
    return '/retailer/home'
  }

  if (isLead) {
    return '/retailer/lead'
  }

  if (isPatientUser) {
    return '/retailer/patient-dashboard'
  }
}

const PrivateRoutes = (props) => {
  const {
    api,
    user,
    config,
    history,
    location,
    loggedIn,
    handleSessionExpired,
    refetchUser,
    persistor,
    isOnline
  } = props
  const dispatch = useDispatch()
  const sync = useSelector(syncSelection)
  const onboardingFeatures = useSelector(selectFeatures)
  const timestampToNextUserSessionCall = useSelector(selectTimestampToNextSessionCall)
  const userSessionState = useSelector(selectUserSession)

  const [showNav, setShowNav] = useState(true)
  const [showBottomNav, setShowBottomNav] = useState(true)
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [collapsedNav, setCollapsedNav] = useState(false)
  const [globalToasts, setGlobalToasts] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [retailerInfoLoaded, setRetailerInfoLoaded] = useState(false)
  const [loadedError, setLoadedError] = useState()
  const [showAccountBlockedModal, setShowAccountBlockedModal] = useState(false)
  const [topOffset, setTopOffset] = useState(0)
  const [signupIsComplete, setSignupIsComplete] = useState(false)
  const [homeRedirectRoute, setHomeRedirectRoute] = useState()
  const [isBasicUser, setIsBasicUser] = useState(false)
  const [isRetailer, setIsRetailer] = useState(false)
  const [isLead, setIsLead] = useState(false)
  const [userWithDB, setUserWithDB] = useState(false)
  const [canViewSourceLedger, setCanViewSourceLedger] = useState(false)
  const [hasSubscriptionBasedService, setHasSubscriptionBasedService] = useState(true)
  const [contracts, setContracts] = useState({
    pendingContracts: 0,
    outdatedContracts: []
  })
  const [syncingContainersCanBeAccessed, setSyncingContainersCanBeAccessed] = useState(false)
  const [syncingContainersSyncFinished, setSyncingContainersSyncFinished] = useState(false)
  const [syncingOnLogin, setSyncingOnLogin] = useState(true)

  const bannersDiv = useRef()
  const locationId = get(user, 'location.id')
  const isShelflifeUser = isShelflife(config)

  const onShowNav = () => {
    setShowNav(true)
  }

  const onhideNav = () => {
    setShowNav(false)
  }

  const onShowBottomNav = () => {
    setShowBottomNav(true)
  }

  const onHideBottomNav = (event) => {
    setShowBottomNav(false)
  }

  const onToggleNavCollapsed = () => {
    setCollapsedNav(!collapsedNav)
  }

  const onOpenNavCollapsed = () => {
    if (collapsedNav) {
      setCollapsedNav(false)
    }
  }

  const onOpenModal = () => {
    setShowAccountBlockedModal(true)
  }

  const onCloseModal = () => {
    setShowAccountBlockedModal(false)
  }

  const onAddGlobalToast = (event) => {
    const { detail, timeStamp } = event
    const newToast = detail
    newToast.timeStamp = timeStamp

    // TODO: ensure that the same message does not get added multiple times?
    setGlobalToasts(prevToasts => ([...prevToasts, newToast]))
  }

  const onRemoveGlobalToast = (removeIndex) => {
    const newGlobalToasts = globalToasts.filter((toast, index) => index !== removeIndex)
    setGlobalToasts(newGlobalToasts)
  }

  const onShowMobileSidebar = (isShown) => {
    // this prevent scrolling the document while the sidebard is shown
    document.body.style.position = isShown ? 'fixed' : ''
    setShowMobileNav(isShown)
  }

  const onHandleMobileSidebarEvent = (event) => {
    onShowMobileSidebar(event.detail.show)
  }

  const onUpdatePendingContracts = (event) => {
    const { pendingContracts } = event.detail

    setContracts({
      pendingContracts: pendingContracts.pending,
      outdatedContracts: pendingContracts.outdated
    })
  }

  const onSubmitMembershipApplication = () => {
    setSignupIsComplete(true)
  }

  const onUpdateOnboarding = () => {
    const featureList = Object.keys(onboardingFeatures)
    const newDate = new Date()

    featureList.forEach(feature => {
      const { hasViewed, lastDayForOnboarding: lastDay } = onboardingFeatures[feature]
      const showOnboarding = isBefore(newDate, lastDay) || isSameDay(newDate, lastDay)
      dispatch(updateOnboarding({ showOnboarding, hasViewed, type: feature }))
    })
  }

  const wrappedDispatch = (action) => {
    // ~~~ HORRIBLE HACK ALERT ~~~
    // `van-offline` will dispatch an `AUTH_ERROR` action when
    // PouchDB cannot authenticate against the given CouchDB.
    // As we have removed the redux dependency for handling authentication
    // no reducer will ever handle this action which is why we wrap the passed
    // dispatch to only selectively relay actions and handle `AUTH_ERROR` actions
    // in a different manner.
    switch (action.type) {
      case AUTH_ERROR: {
        return handleSessionExpired(user, { config, history })
      }
      default:
        // this will call a reducer in the sync-reducer file
        // based on the action type
        dispatch(action)
    }
  }

  const initialize = async () => {
    const { remoteDbUrl, databases } = config

    if (!loggedIn) return

    // We are setting this before fetching the data to make the menu responsive
    window.addEventListener('show-mobile-sidebar', onHandleMobileSidebarEvent)
    window.addEventListener('van-show-nav', onShowNav)
    window.addEventListener('van-hide-nav', onhideNav)
    window.addEventListener('van-show-bottom-nav', onShowBottomNav)
    window.addEventListener('van-hide-bottom-nav', onHideBottomNav)

    let userLocation
    try {
      // This "setSyncingOnLogin" is a bit of a hack. If we see that there is an array of dbs to sync, we know someone just logged in. We need it to establich if the "syncingContainersCanBeAccessed" could potentially be set to true
      await initSync(user, remoteDbUrl, databases, wrappedDispatch).then((dbs) => setSyncingOnLogin(Array.isArray(dbs)))
      onUpdateOnboarding()
      if (locationId) {
        const response = await dispatch(getLocation({ api, locationId, user, forceRefresh: true }))
        if (response.payload.length && response.payload[0]) {
          const [location] = response.payload
          userLocation = location
        }
      }
      setLoaded(true)
    } catch (loadedError) {
      if (!isLeadBeforeSync(user)) {
        setLoadedError(loadedError)
        console.error('Error initializing', loadedError)
      }
    }

    try {
      // Try setting up notifications if we have a user
      // this can be done multiple times and will just circle back if they are turned off
      checkNotifications(user, config, api)
    } catch (e) {
      // Can't really do much, let the app work
      console.log('FCM Notifications error', e)
    }

    try {
      cfLogUserDetail(user, userLocation)
    } catch (e) {
      // Can't really do much, let the app work
    }

    try {
      const systemTimeMismatch = await isSystemDateOff(remoteDbUrl)
      if (systemTimeMismatch) {
        const message = `Please adjust the time of your device to the current date and time to proceed.`
        alert(message)
      }
    } catch (e) {
      // If we can't reach the server to ask for time, there's an error but what would we do with that?
    }

    const pendingContracts = await getPendingContracts(user, api, user.location.id)
    setContracts({
      pendingContracts: pendingContracts.pending,
      outdatedContracts: pendingContracts.outdated
    })

    let hasSubscriptionBasedService = true
    let isBasicUser = false

    const isLead = isLeadUser(user, userLocation)
    const userWithDB = hasOwnDatabase(user)
    const isRetailer = userWithDB && userIsAuthorised(user, 'feature:userRole:pharmacyUser') && !isLead
    const canViewSourceLedger = userIsAuthorised(user, 'feature:can-view-source-ledger')
    const userCanViewFinances = userIsAuthorised(user, 'feature:view-finances')
    const homeRedirectRoute = checkHomeRedirectRoute(isRetailer, isLead, canViewSourceLedger)

    setHomeRedirectRoute(homeRedirectRoute)
    setIsRetailer(isRetailer)
    setIsLead(isLead)
    setRetailerInfoLoaded(true)
    setUserWithDB(userWithDB)
    setCanViewSourceLedger(canViewSourceLedger)

    if (userCanViewFinances && userLocation && (isRetailer || isLead)) {
      isBasicUser = userLocation.membership === MEMBERSHIPS.BASIC
      hasSubscriptionBasedService = isLocationSubscriptionBased(userLocation)

      await dispatch(getFinances({ api, locationId, forceRefresh: true }))
    }

    let signupIsComplete

    if (isLead) {
      try {
        const response = await api.signup.get()
        const signupData = response.data
        signupIsComplete = signupData && signupData.isComplete === 'true'
      } catch (error) {
        console.error(error)
      }
    }

    setRetailerInfoLoaded(true)
    setIsBasicUser(isBasicUser)
    setIsRetailer(isRetailer)
    setIsLead(isLead)
    setHasSubscriptionBasedService(hasSubscriptionBasedService)
    setSignupIsComplete(signupIsComplete)

    window.addEventListener('add-global-toast', onAddGlobalToast)
    window.addEventListener('contract-signed', onUpdatePendingContracts)
    window.addEventListener('submit-membership-form', onSubmitMembershipApplication)
  }

  const cleanup = () => {
    window.removeEventListener('van-show-nav', onShowNav)
    window.removeEventListener('van-hide-nav', onhideNav)
    window.addEventListener('van-show-bottom-nav', onShowBottomNav)
    window.addEventListener('van-hide-bottom-nav', onHideBottomNav)
    window.removeEventListener('add-global-toast', onAddGlobalToast)
    window.removeEventListener('show-mobile-sidebar', onHandleMobileSidebarEvent)
    window.removeEventListener('contract-signed', onUpdatePendingContracts)
    window.removeEventListener('submit-membership-form', onSubmitMembershipApplication)
  }

  useEffect(() => {
    initialize()
    return () => cleanup()
  }, [])

  useEffect(() => {
    onShowMobileSidebar(false)
  }, [location.pathname])

  const refetchUserInformation = async () => {
    // We are checking if enough time has passed since the last user update
    const callCheckActive = canCallEndpoint(timestampToNextUserSessionCall)
    // This userSessionState check should be removed in few weeks after most users had a chance to log out/log in and repopulate their state
    if (callCheckActive && userSessionState) {
      // If more than 24h has passed we will refetch user object and set timestamp to be in next 24h
      dispatch(timestampUpdate())
      await refetchUser(history)
    }
  }

  useEffect(() => {
    // For now we only want to reftech the user details if user is a SL retailer.
    if (!config || !user || !isRetailer || !isOnline) return
    refetchUserInformation()
  }, [location.pathname])

  useEffect(() => {
    const element = bannersDiv.current

    if (!element) return

    // eslint-disable-next-line no-undef
    const observer = new ResizeObserver(() => {
      setTopOffset(Number(element.clientHeight))
    })

    observer.observe(element)
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect()
    }
  }, [])

  const { stockBackgroundUpdate, shipmentBackgroundUpdate, upToDate, percentComplete, initialSyncFinished } = sync
  const { features } = config
  const hasStockCounts = hasFeature(features, 'stockCount') && (userIsAuthorised(user, 'feature:stock-reporting') || userIsAuthorised(user, 'feature:read-only-reports'))
  const hasShipments = hasFeature(features, 'shipments') && userIsAuthorised(user, 'feature:shipments')

  const hasNoSyncUpdates = upToDate && ((hasShipments && !shipmentBackgroundUpdate) || !hasShipments) && ((hasStockCounts && !stockBackgroundUpdate) || !hasStockCounts)
  const isSyncFinished = isShelflifeUser || !isOnline || syncingContainersSyncFinished ? true : hasNoSyncUpdates
  const syncHasStarted = percentComplete > 0 && percentComplete !== 1

  useEffect(() => {
    if (!syncingContainersCanBeAccessed) {
      // There seems to be an issue where if you enter the shipments tab before the syncing is started, the whole app breaks. This prevents it from happening.
      // initialSyncFinished was added for the case when the user closes the tab and goes back to open one of the containers. If we don't add it and the syncing was previously completed, the syncHasStarted never sets to true.
      if (syncHasStarted || (initialSyncFinished && !syncingOnLogin)) {
        setSyncingContainersCanBeAccessed(true)
      }
    }
  }, [syncHasStarted, initialSyncFinished, syncingOnLogin])

  useEffect(() => {
    if (syncingContainersCanBeAccessed && isSyncFinished) {
      setSyncingContainersSyncFinished(true)
    }
  }, [syncingContainersCanBeAccessed, isSyncFinished])

  if (!loggedIn || (loadedError && loadedError.status === 401)) {
    return <Redirect to={{
      pathname: '/login',
      state: {from: location.pathname}
    }} />
  }

  const showBottomNavigation = showBottomNav && (isRetailer || isLead || canViewSourceLedger)
  const showEnviromentBanner = hasFeature(config.features, 'showEnvBanner')

  const showCountSyncBanner = hasFeature(config.features, 'showCountSyncBanner') && stockBackgroundUpdate
  const showShipmentSyncBanner = hasFeature(config.features, 'showShipmentSyncBanner') && shipmentBackgroundUpdate
  const hasContainerSyncCompletedAndLoaded = syncingContainersCanBeAccessed && loaded
  const isLoaded = isShelflifeUser ? hasContainerSyncCompletedAndLoaded : syncingContainersCanBeAccessed

  const { pendingContracts, outdatedContracts } = contracts
  const {
    ALERTS,
    ANALYTICS,
    INFORMATION,
    NOTIFICATIONS,
    ORDERS,
    LONGHAUL,
    PRODUCTS,
    PROFILE,
    REPORTING,
    RETAILER,
    SETTINGS,
    SHIPMENTS
  } = USER_ROUTES
  return (
    <Fragment>
      <div className='shell__banners vs-u-hide-on-print' ref={bannersDiv}>
        {showEnviromentBanner && <CurrentEnvironmentBanner environment={config.env} />}
        {user && user.loggedInAsAdmin && <AdminLoginBanner adminName={user.loggedInAsAdmin} userName={user.name} /> }
        {isRetailer && (
          <AccountBlockedBanner
            onOpenModal={onOpenModal}
            onCloseModal={onCloseModal}
            showModal={showAccountBlockedModal}
          />
        )}

        {isLead && (
          <MembershipBanner
            signupIsComplete={signupIsComplete}
            location={location}
          />
        )}
      </div>

      <div
        style={{'--vs-app-top-offset': `${topOffset}px`}}
        className={classnames(
          'shell',
          {'shell--sidebar-expanded': !collapsedNav},
          {'shell--sidebar-collapsed': collapsedNav},
          {'shell--immersive-mode': !showNav},
          {'shell--bottom-nav-visible': showBottomNavigation}
        )}
      >
        <section className='shell__toasts'>
          {globalToasts && globalToasts.map((toast, index) => (
            <div
              key={`shelltoast${toast.timeStamp}`}
            >
              <Toast
                {...toast}
                onDismiss={() => onRemoveGlobalToast(index)}
              />
            </div>
          ))}
        </section>

        {showBottomNavigation && (
          <BottomNavigation
            user={user}
            isLeadUser={isLead}
            userWithDB={userWithDB}
            isRetailer={isRetailer}
          />
        )}

        {showNav && (
          // shell__sidebar--show controls the hide/show behaviour on mobile
          <div className={classnames(
            'shell__sidebar', {'shell__sidebar--show': showMobileNav}
          )}>
            <MainMenu
              location={location}
              user={user}
              isCollapsed={collapsedNav}
              onToggleCollapsed={onToggleNavCollapsed}
              onOpenNavCollapsed={onOpenNavCollapsed}
              pendingContracts={pendingContracts}
              outdatedContracts={outdatedContracts}
              hasSubscriptionBasedService={hasSubscriptionBasedService}
              isBasicUser={isBasicUser}
              isLeadUser={isLead}
              isRetailer={isRetailer}
              userWithDB={userWithDB}
              persistor={persistor}
            />
          </div>
        )}

        {showMobileNav && (
          <Backdrop
            className='shell__backdrop'
            onClose={() => onShowMobileSidebar(false)}
          />
        )}

        <section className='shell__content'>
          {/* ErrorBoundary _after_ the nav bar renders so the user has a hope of logging out to clear data and try again. */}
          {/* We still ErrorBoundary at the top level Root component in case we error above this switch. */}

          {loadedError ? <OfflineLoadingError error={loadedError} /> : (
            <ErrorBoundary>
              <Switch>
                <PrivateRoute exact path='/' component={loaded && retailerInfoLoaded && !homeRedirectRoute ? HomeContainer : Loading} location={location}>
                  {homeRedirectRoute && <Redirect to={homeRedirectRoute} />}
                </PrivateRoute>
                {config.features.termsAndConditions && config.features.termsAndConditions.documents.map(
                  ({ title, path, component }) =>
                    <PrivateRoute
                      path={path}
                      key={path}
                      component={component}
                      componentProps={{
                        location,
                        title,
                        appName: config.name,
                        template: isShelflifeUser ? 'SL' : ''
                      }}
                    />
                )}
                <PrivateRoute path={ALERTS} component={loaded ? Alerts : Loading} location={location} componentProps={{showCountSyncBanner}} />
                <PrivateRoute path={ANALYTICS} component={loaded ? Analytics : Loading} location={location} />
                <PrivateRoute path={ORDERS} component={loaded ? Orders : Loading} />
                <PrivateRoute path={REPORTING} component={isLoaded ? Reporting : Loading} location={location} componentProps={{showCountSyncBanner, showShipmentSyncBanner, isSyncFinished, percentComplete}} />
                <PrivateRoute path={SETTINGS} component={loaded ? Settings : Loading} location={location} />
                <PrivateRoute path={SHIPMENTS} component={isLoaded ? Shipments : Loading} location={location} componentProps={{isSyncFinished, percentComplete}} />
                <PrivateRoute path={RETAILER} component={loaded ? Retailer : Loading} location={location} />
                <PrivateRoute path={PRODUCTS} component={loaded ? RetailerProducts : Loading} location={location} />
                <PrivateRoute path={`${PROFILE}/:documentSlug`} component={loaded ? ProfileDocumentContainer : Loading} location={location} />
                <PrivateRoute path={PROFILE} component={loaded ? ProfileContainer : Loading} location={location} history={history} />
                <PrivateRoute path={INFORMATION} component={loaded ? InformationApp : Loading} />
                <PrivateRoute path={NOTIFICATIONS} component={loaded ? Notifications : Loading} />
                <PrivateRoute path={LONGHAUL} component={loaded ? LongHaulOrders : Loading} />
              </Switch>
            </ErrorBoundary>
          )}
        </section>
      </div>
    </Fragment>
  )
}

PrivateRoutes.propTypes = {
  loggedIn: PropTypes.bool,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  persistor: PropTypes.object
}

PrivateRoutes.defaultProps = {
  loggedIn: false
}

const withHOCs = flowRight(
  withApi,
  withRouter,
  withConfig,
  withOffline
)

export default (withAuth('user', 'loggedIn', 'handleSessionExpired', 'refetchUser')(withHOCs(PrivateRoutes)))
