const updateSubOrderWithRoute = async function (state, {subOrderId, routeId}) {
  let subOrder = await state.dal.order.getSuborder(state, {suborderId: subOrderId, raw: true})
  if (!subOrder) {
    const err = new Error(
      `No suborder with id "${subOrderId}" was found. Cannot continue.`
    )
    throw err
  }
  const updatedOrderDoc = {
    ...subOrder,
    routeId
  }

  const updatedSubOrder = await state.dal.order.update(state, updatedOrderDoc)

  return updatedSubOrder
}

module.exports = { updateSubOrderWithRoute }
