import fastOrderPlacementSlice from './fastOrderPlacementSlice'

export {
  productRemove,
  productUpdate,
  selectCartId,
  selectProductSelection,
  selectViewType,
  selectionClear,
  viewTypeSet
} from './fastOrderPlacementSlice'

export default fastOrderPlacementSlice
