const chunk = require('lodash/chunk')

const removeNonQboSupportedChars = (name) => (
  // https://quickbooks.intuit.com/learn-support/en-us/help-article/account-management/acceptable-characters-quickbooks-online/L3CiHlD9J_US_en_US
  // That article does not mention the % sign, but it does seem to work
  // and it's mentioned elsewhere that items do support some special chars
  // but not which ones...
  name.replace(/[^ A-Za-z0-9,.?@%!#'~*();+_-]/g, '').trim()
)

const itemNameToKey = (name) => (
  removeNonQboSupportedChars(name).toLowerCase()
)

class ItemsMap {
  constructor () {
    this.items = new Map()
  }
  set (name, { accountId, hsCode }) {
    this.items.set(
      itemNameToKey(name),
      { name: removeNonQboSupportedChars(name), accountId, hsCode }
    )
  }
  getNames () {
    return [...this.items.values()].map(v => v.name)
  }
  getAccountId (name) {
    const key = itemNameToKey(name)
    if (this.items.has(key)) {
      return this.items.get(key).accountId
    }
  }
  getHsCode (name) {
    const key = itemNameToKey(name)
    if (this.items.has(key)) {
      return this.items.get(key).hsCode
    }
  }
}

class QuickbooksItems {
  constructor (items = []) {
    this.items = new Map()
    this.add(...items)
  }
  get (name) {
    return this.items.get(itemNameToKey(name))
  }
  getMissingNames (names) {
    const missing = []
    for (const name of names) {
      if (!this.items.has(itemNameToKey(name))) {
        missing.push(name)
      }
    }
    return missing
  }
  getMissingHsCode () {
    const noSku = []
    for (const item of this.items.values()) {
      if (item.Sku == null || item.Sku === '') {
        noSku.push(item)
      }
    }
    return noSku
  }
  add (...items) {
    for (const item of items) {
      this.items.set(itemNameToKey(item.Name), item)
    }
  }
}

async function updateQuickbooksItems (quickbooks, { itemsMap, companyCode }) {
  const itemNames = itemsMap.getNames()
  const items = new QuickbooksItems()
  const maxQueryResults = 150
  const itemNameBatches = chunk(itemNames, maxQueryResults)
  for (const names of itemNameBatches) {
    const quotedNames = names.map(n => `'${n.replace(/'/g, '\\\'')}'`)
    const listStr = `(${quotedNames.join(',')})`
    const queryResult = await quickbooks.entities.query.send({
      companyCode,
      type: 'Item',
      where: `FullyQualifiedName in ${listStr}`,
      maxResults: maxQueryResults
    })
    items.add(...queryResult)
  }

  // create missing items
  const missingNames = items.getMissingNames(itemNames)
  const createOps = missingNames.map(name => {
    const accountId = itemsMap.getAccountId(name)
    const item = {
      Name: name,
      // Use type 'Service', because we don't track inventory currently
      Type: 'Service',
      IncomeAccountRef: {
        value: accountId
      }
    }
    const hsCode = itemsMap.getHsCode(name)
    if (hsCode) {
      // Hack to get the HS code to be displayed on the invoice
      item.Sku = hsCode
    }
    return {
      id: name,
      operation: 'create',
      type: 'Item',
      value: item
    }
  })
  const createdResult = await quickbooks.entities.batch.send({ companyCode, ops: createOps })
  const createdItems = createdResult.map(r => r.value)
  items.add(...createdItems)

  // update items without HS-code
  const itemsWithoutHsCode = items.getMissingHsCode().filter(
    i => Boolean(itemsMap.getHsCode(i.Name))
  )
  const updateOps = itemsWithoutHsCode.map(item => ({
    id: item.Name,
    operation: 'update',
    type: 'Item',
    value: {
      ...item,
      Sku: itemsMap.getHsCode(item.Name)
    }
  }))
  const updatedResult = await quickbooks.entities.batch.send({ companyCode, ops: updateOps })
  const updatedItems = updatedResult.map(r => r.value)
  items.add(...updatedItems)
  return items
}

module.exports = {
  ItemsMap,
  updateQuickbooksItems
}
