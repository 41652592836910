import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import { getUserReducers } from './get-user-reducers'

export const getPersistedReducer = (user) => {
  const { reducers, persistConfig } = getUserReducers(user)
  const reducer = combineReducers(reducers)
  const persistedReducer = persistReducer(persistConfig, reducer)
  return persistedReducer
}
