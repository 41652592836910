const { EntityApi } = require('../common')
const PGAdapter = require('./../common/pg-adapter')
const getMonthlySupplyQuery = require('./monthly-supply-query')

class SupplierApi extends EntityApi {
  constructor (state, pgConnection, agaveAdapter) {
    const { user = {} } = state
    const username = user.name
    const columns = ['supplier']
    super((pgConnection && new PGAdapter(pgConnection, 'avocado.vw_products', username, columns)) || {})
    this.pgConnection = pgConnection
    this.agaveAdapter = agaveAdapter
  }

  fetchMonthlySupply (params) {
    return this.agaveAdapter.get(`supplier/supply/monthly`, {
      ...params
    })
  }

  async getOne (id) {
    const query = `
      select
        distinct supplier as id
      from
        avocado.vw_products
      where supplier = $1::text`

    const response = await this.pgConnection.query(query, [id])
    // todo: utility function to wrap these
    return response && response.rows && response.rows[0]
  }

  async getList ({search, filter}) {
    let query = `
      select
        distinct supplier as id
      from
        avocado.vw_products`
    // todo: figure out a more consistent way to do filters here
    // use a view?
    if (filter.product_id) {
      // QUESTION: Do we still need this logic?
      query = `
        select
          distinct p2.supplier as id
        from
          avocado.vw_products p1 left join avocado.vw_products p2
            on lower(replace(p1.product,' ','')) = lower(replace(p2.product,' ',''))
        where
          p1.product_id=$1::text`
    }

    const response = await this.pgConnection.query(query, [filter.product_id])
    // todo: utility function to wrap these
    return response && { count: (response.rows || []).length, results: response.rows }
  }

  async getMonthlySupply (props) {
    const { query, values } = getMonthlySupplyQuery(props)
    const { rows } = await this.pgConnection.query(query, values)
    return rows
  }
}

module.exports = SupplierApi
