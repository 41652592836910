const keyBy = require('lodash/keyBy')
const {decorateOrder, replaceSubFunders} = require('../rest-api-utils')

// listOrdersForUser is used in the app internally
// while list is used in the REST api externally
exports.list = list
function list (
  {docs, alias, status, warehouseCodeMap, productMasterData, funders = [], useGenericParent, routes = []}
) {
  const fundersById = keyBy(funders, '_id')
  const routesById = keyBy(routes, '_id')

  return docs
    .map(doc => replaceSubFunders(doc, fundersById))
    .map(doc => decorateOrder({
      doc,
      alias,
      warehouseCodeMap,
      productMasterData,
      useGenericParent,
      routesById
    }))
}
