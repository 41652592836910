const get = require('lodash/get')
const { getByIds: getLocationsByIds } = require('../../../location')
const { getProductAliasMasterData } = require('../master-data')
const tools = require('../../tools')
const { list: listFunders } = require('./../../../funders')
const { list: listRoutes } = require('./../../../routes')

const CHECKPOINT_DOC_ID = '_local/van:indicators:backup-orders'

// This is written for the REST API's GET /orders
// for the ONE integration. It's not used in the UI.
exports.list = async function (state, params = {}) {
  const {
    alias = 'one',
    status = 'accepted',
    date = new Date().toJSON(),
    limit = 50,
    geolocationId,
    programId,
    funderId,
    closedStatus,
    useGenericParent
  } = params
  let { since } = params
  if (alias === 'one' && status !== 'accepted') {
    // FYI this is because aliases use master data batches
    // and orders do not store batches until the accpted step
    throw new Error('Error: orders with non-accepted status cannot convert product aliases')
  }

  // If we do not pass since, get it from the checkpoint doc.
  // This defeats scaling issues with the orders api, cause processing the
  // entire changes feed is expensive and not needed since we're rotating the
  // whole database anyways after each other period.
  // Rotation is done via backup-orders.js migration data-ops script. Ensure to
  // run it with `--writeCheckpoint` flag, which will take care of writing the
  // approprate checkpoint used here:
  if (!since) {
    try {
      const { end_seq: seq } = await state.orderDb.get(CHECKPOINT_DOC_ID)
      if (seq) {
        since = seq
      }
    } catch (e) {
      console.log('failed to get start sequence', e)
    }
  }

  const {docs, sequence, pending} = await state.dal.order.listChanges(state, {
    geolocationId, since, limit, programId, status, funderId, closedStatus
  })
  const supplierIds = Array.from(new Set(docs.map(d => d.supplierId).filter(x => x)))
  const locationIds = docs.map(d => d.destinationId).concat(supplierIds)
  const locationEntities = await getLocationsByIds(state, locationIds, date)
  const funders = await listFunders(state)
  const routes = await listRoutes(state)

  const warehouseCodeMap = locationIds
    .reduce((acc, locationId, index) => {
      const locationEntity = locationEntities.find(location => location._id === locationId) || {}
      acc[locationId] = get(locationEntity, 'additionalData.warehouseCode')
      return acc
    }, {})

  const productMasterData = alias === 'one' || useGenericParent === true
    ? await getProductAliasMasterData(state)
    : []

  const orders = tools.list({docs, alias, status, warehouseCodeMap, productMasterData, funders, useGenericParent, routes})
  return {orders, sequence, pending}
}
