import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { isEqual, isAfter, subDays, isValid } from 'date-fns'

import { Button } from '@fielded/shared-ui'
import { Clock } from '@fielded/shared-ui/src/icons'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'

import { formatDate, dateFormats, parseDate } from '../../../van-shared/utils/utils'
import { hasFeature } from '../../../van-shared/utils/features'

const ShipmentRescheduleOption = ({
  config,
  api,
  shipment,
  onUpdateShipment
}) => {
  const [calendarVisible, setCalendarVisibility] = useState(false)
  const [tempDate, setTempDate] = useState()
  const [dateInvalid, setDateInvalid] = useState(false)

  // allow users to reschedule up till day of shipment
  const showRescheduleButton = hasFeature(config, 'features.shipments.rescheduleShipment') && isAfter(shipment.date, subDays(new Date(), 1))

  const toggleRescheduleShipment = () => {
    setCalendarVisibility(true)
  }

  const validateDate = (date) => {
    const yesterday = subDays(new Date(), 1)
    return isAfter(date, yesterday) && isValid(new Date(date))
  }

  const submitNewArrival = async () => {
    // If we are trying to set date to same one as previously or date was not selected, we just hide the input
    if (!tempDate || isEqual(tempDate, shipment.date)) {
      return setCalendarVisibility(false)
    }

    try {
      toast({
        title: `Rescheduling shipment #${shipment.shipmentNo} to ${formatDate(tempDate, 'long')}`,
        type: 'info'
      })

      const updatedShipment = await api.shipment.reschedule(shipment.id, tempDate)

      toast({
        title: `Shipment #${updatedShipment.shipmentNo} rescheduled to ${formatDate(updatedShipment.date, 'long')}`,
        type: 'success'
      })

      const newShipmentObject = {
        ...shipment,
        date: updatedShipment.date,
        previousScheduledDates: updatedShipment.previousScheduledDates
      }
      onUpdateShipment(newShipmentObject)
    } catch (e) {
      toast({
        title: 'Error when rescheduling shipment, please try again.',
        type: 'warning'
      })
    } finally {
      setCalendarVisibility(false)
      setTempDate()
    }
  }

  return (
    <div className='vs-u-row vs-u-align-center vs-u-gap vs-u-margin-bottom'>
      {calendarVisible && (
        <Fragment>
          <DayPickerInput
            value={formatDate((tempDate || shipment.date), 'long')}
            onDayChange={date => {
              if (date) {
                date = date.toISOString()
              }

              const isValidDate = validateDate(date)
              setTempDate(date)
              setDateInvalid(!isValidDate)
            }}
            formatDate={date => formatDate(date, 'long')}
            parseDate={parseDate}
            format={dateFormats.long}
            dayPickerProps={{
              modifiers: {
                disabled: { before: new Date() }
              }
            }}
          />
          <Button
            colorVariant='dark'
            fill='outline'
            size='regular'
            onClick={submitNewArrival}
            disabled={tempDate && (isEqual(tempDate, shipment.date) || dateInvalid)}
          >
            Submit
          </Button>
          <Button
            colorVariant='dark'
            fill='outline'
            size='regular'
            onClick={() => setCalendarVisibility(false)}
          >
            Cancel
          </Button>
        </Fragment>
      )}
      {showRescheduleButton && !calendarVisible &&
        <Button
          colorVariant='dark'
          fill='outline'
          icon={<Clock />}
          size='regular'
          onClick={toggleRescheduleShipment}
        >
          Reschedule
        </Button>
      }
    </div>
  )
}

ShipmentRescheduleOption.propTypes = {
  shipment: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  onUpdateShipment: PropTypes.func
}

export default ShipmentRescheduleOption
