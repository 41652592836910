import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { DocumentHeader, Form, Page, Text } from '@fielded/shared-ui'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'

import { isValidEmail, isValidPhoneNumber } from '../../../../van-shared/utils/utils'
import DocumentText from './DocumentText'

class FPAgreement extends Component {
  onSubmit = async (fields) => {
    if (this._loading) {
      return false
    }

    this._loading = true

    try {
      const { history, signDocument } = this.props
      await signDocument({
        ...fields
      })

      toast({
        title: 'Agreement Signed',
        type: 'success',
        autoDismiss: false,
        ctaProps: ({
          label: 'Back to profile',
          onClick: () => history.goBack()
        })
      })
    } catch (e) {
      toast({
        title: 'Error signing the document',
        type: 'warning',
        children: (
          <div>Message: {e.message}</div>
        )
      })
    }

    this._loading = false
  }

  render () {
    const { documentVersionId, signEvent, history } = this.props
    const isCompleted = !!signEvent
    const status = isCompleted ? 'completed' : 'notCompleted'

    return (
      <Form onSubmit={!isCompleted ? this.onSubmit : () => {}}>
        <Page.Panel withBackground spread>
          <DocumentHeader name='Field Intelligence Fulfillment Partner Master Service Agreement' status={status} />
          <Page.Panel.Section className='sl-print-document__content'>
            <DocumentText documentVersionId={documentVersionId} />
          </Page.Panel.Section>

          <Page.Panel.Section>
            <Form.Row>
              <div>
                <Text.Heading size='small'>To FP:</Text.Heading>
                <Form.Field
                  labelText='First Name'
                  fieldName='driverFirstName'
                >
                  <Form.Field.Text
                    defaultValue={get(signEvent, 'data.driverFirstName')}
                    disabled={isCompleted}
                    required
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
                <Form.Field
                  labelText='Last Name'
                  fieldName='driverLastName'
                >
                  <Form.Field.Text
                    defaultValue={get(signEvent, 'data.driverLastName')}
                    disabled={isCompleted}
                    required
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>

                <Form.Field
                  labelText='Email'
                  fieldName='driverEmail'
                >
                  <Form.Field.Text
                    defaultValue={get(signEvent, 'data.driverEmail')}
                    disabled={isCompleted}
                    required
                    validate={(value) => {
                      if (!isValidEmail(value)) {
                        return Promise.resolve('Please enter a valid email address')
                      }
                    }}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
                <Form.Field
                  labelText='Phone #'
                  fieldName='driverPhone'
                >
                  <Form.Field.Text
                    defaultValue={get(signEvent, 'data.driverPhone')}
                    disabled={isCompleted}
                    required
                    validate={(value) => {
                      if (!isValidPhoneNumber(value)) {
                        return Promise.resolve('Please enter a valid phone number')
                      }
                    }}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>

                <Form.Field
                  labelText='Signature'
                  fieldName='driverSignature'
                >
                  <Form.Field.Signature
                    defaultValue={get(signEvent, 'data.driverSignature')}
                    required='Please sign the agreement'
                    disabled={isCompleted}
                  />
                  <Form.Field.ValidationErrors />
                </Form.Field>
              </div>
              <div>
                {/* sorry this div is just for layout, was a bit lazy */}
              </div>
            </Form.Row>
          </Page.Panel.Section>
        </Page.Panel>
        {!isCompleted &&
        <Page.Footer className='vs-u-hide-on-print'>
          <Form.ValidationErrors alignRight />
          <Form.Actions nowrap>
            <Form.Button>
              Confirm
            </Form.Button>
            <Form.Button
              variant='deemphasized'
              alignOpposite
              onClick={history.goBack}
            >
              Cancel and discard
            </Form.Button>
          </Form.Actions>
        </Page.Footer>
        }
      </Form>
    )
  }
}

FPAgreement.propTypes = {
  /**
   * Version of the document `${documentId}:${locationId}:${version}`.
   * `locationId` can be a state (if for any reason there is an specific
   * document version for that state) or a country.
   */
  documentVersionId: PropTypes.string.required
}

export default FPAgreement
