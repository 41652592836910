import React, { Component, Fragment } from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import FSApi from '@fielded/fs-api'
import { getLocalPouchDB, getRemotePouchDB } from '../sync'

import { Loading } from '@fielded/shared-ui'

import { logNotification } from '../utils/googleAnalytics'
import withConfig from '../van-shared/hoc/withConfig'
import AuthenticationProvider, { withAuth } from './../common/AuthenticationProvider'
import ApiProvider from './../common/ApiProvider'
import initSentry, { setUserInSentry } from '../common/init-sentry'
import { cfLogInit, cfLogSignIn } from '../common/cf-adapter'
import { addIntercom, bootIntercomAfterLogin } from '../common/init-intercom'
import prepareFetch from '../common/prepare-fetch'
import { hasOwnDatabase } from '../common/utils/user-db-sync'
import * as loginApi from '../login/login-api'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { configureAppStore } from './store'
import { getPersistedReducer } from './reducer'

class Root extends Component {
  state = {
    ready: false
  }

  constructor (props) {
    super(props)
    document.body.className = this.props.config.theme
  }

  loadGoogleAnalytics (config) {
    if (!config.googleAnalytics) {
      return
    }

    const { g4Id } = config.googleAnalytics

    const gaScript1 = document.createElement('script')
    gaScript1.setAttribute('async', 'true')
    gaScript1.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${g4Id}`)

    const gaScript2 = document.createElement('script')
    let gaString = `window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', '${g4Id}');`

    gaScript2.innerText = gaString
    document.head.appendChild(gaScript1)
    document.head.appendChild(gaScript2)

    // Looks for a `notification_background` querystring and logs
    // that we have opened a notification, otherwise noop
    logNotification(window.location)
  }

  loadFavicon (config) {
    // The only way I managed to make sure correct favicon shows up in Chrome
    const oldFavicon = document.querySelector("link[rel~='icon']")
    const link = document.createElement('link')
    const faviconUrl = `${window.location.origin}/${config.theme}/favicon.ico`
    link.rel = 'icon'
    link.href = faviconUrl
    if (oldFavicon) {
      document.head.removeChild(oldFavicon)
    }
    document.head.appendChild(link)
  }

  async componentDidMount () {
    const { config, user } = this.props
    this.loadFavicon(config)
    initSentry(config, user)
    this.setState({ ready: true })
    this.loadGoogleAnalytics(config)
    addIntercom(config)
  }

  componentDidUpdate (prevProps) {
    const { config, user } = this.props
    if (user) {
      cfLogInit({config, user})
    }
    if (!prevProps.user && user) {
      cfLogSignIn(user)
      setUserInSentry(user)
      bootIntercomAfterLogin(config, user)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    // It is important to guard this component from performing unwanted re-renders
    // as this would delete the application state stored in the redux store.
    // The component should only ever render when the login state changes.
    if (nextState.ready !== this.state.ready) {
      return true
    }
    if (nextProps.loggedIn !== this.props.loggedIn) {
      return true
    }
    return false
  }

  render () {
    const { config, user } = this.props
    if (!this.state.ready) {
      return <Loading />
    }
    const PouchDB = getLocalPouchDB(user, config.performanceLogging)
    const RemotePouchDB = getRemotePouchDB(config.remoteDbUrl, {}, config.performanceLogging)
    const orderDbName = get(config, 'features.orders.orderDBName', 'order')
    const alwaysUseOnlineOffline = get(
      config, 'features.sync.alwaysUseOnlineOffline', false
    )

    if (alwaysUseOnlineOffline && user) {
      const existingRoles = get(user, 'roles', [])
      const usesDatabasePerUser = hasOwnDatabase(user)
      console.log('has own database', usesDatabasePerUser)

      // Pharmacy users should not be set to online-offline
      // this might change if we add id dispensing for them, but at the moment they dont have that
      if (!usesDatabasePerUser) {
        const roles = [...new Set(existingRoles.concat('feature:online-offline'))]

        // intentionally mutating the user object so initSync and the ID dispensers
        // see the changed roles.
        user.roles = roles
      }
    }
    // This is expected to be empty for PSM
    const djangoAuthToken = user && user.djangoAuthToken
    const restApiUrl = djangoAuthToken
      ? config.restTokenApiUrl
      : config.restApiUrl
    const apiOpts = {
      user: user,
      // Orders db is online-only to start.
      // Also used by online-offline
      RemotePouchDB,
      // but sc DB should still be local.
      PouchDB,
      fetch: prepareFetch(this.props.config.indicatorsEndpoint),
      // Unlike the api parts that use the fetch param,
      // the rest adapter to django expects a fetch that's not configured.
      // The backend here uses isomorphic-fetch
      isomorphicFetch: global.window.fetch,
      orderDbName,
      restApiUrl,
      agaveApiUrl: this.props.config.agaveApiUrl,
      djangoAuthToken,
      // Let shelflife users (for now, might apply to PSM later)
      // take some shortcuts when reading shipments
      prefilteredPouch: alwaysUseOnlineOffline,
      paystackWebConfigs: config.paystack
    }
    let api
    if (user && user.name) {
      api = new FSApi(apiOpts)
      // our settings subapp is the only place that uses this fully remote API on
      // the frontend (it came before we'd gotten very far in data access layers
      // that knew about remote). If you need to make a any remote calls, probably
      // better to look at using a remote DB in the data access layer in the API.

      const remoteApiOpts = Object.assign({}, apiOpts, {PouchDB: apiOpts.RemotePouchDB, prefilteredPouch: false})
      const remoteApi = new FSApi(remoteApiOpts)

      Object.assign(api, { remote: remoteApi })
    }

    const persistedReducer = getPersistedReducer(user)

    const store = configureAppStore(
      persistedReducer, config, {}, { user: user, api }
    )
    const persistor = persistStore(store)

    return (
      <Fragment>
        <Helmet>
          <link rel='manifest' href={`${window.location.origin}/${config.theme}/manifest.json`} />
        </Helmet>
        <ApiProvider api={api}>
          <ReduxProvider store={store}>
            <PersistGate persistor={persistor}>
              <BrowserRouter>
                <Routes persistor={persistor} />
              </BrowserRouter>
            </PersistGate>
          </ReduxProvider>
        </ApiProvider>
      </Fragment>
    )
  }
}

const RootWithAuth = withAuth('user', 'loggedIn')(Root)

export default withConfig((props) => (
  <AuthenticationProvider
    loginApi={loginApi}
  >
    <RootWithAuth {...props} />
  </AuthenticationProvider>
))
