const defaultQuery = `
with
helds as (
  select
    p.market,
    p.product,
    sum(greatest(h.current_opening,0)) as current_opening
  from summaries.v_latest_held h
  left join avocado.vw_products p on h.sku = p.product_id
  left join avocado.data_market m on p.market = m.product_market_code
  group by 1,2
),

growth as (
  select
    f.market,
    count(distinct location) as locations
  from summaries.forecast_4_weeks f
  left join avocado.data_location dl on f.location = dl.name_of_pharmacy
  where supply_chain_level = 'sdp'
  group by 1
),

growth_expectations as (
  values ('', 0) -- add expected added locations per market here if desired
),

forecasts as (
  select
    p.market,
    p.product,
    f.sku,
  array_agg(distinct sku) as skus, -- to check which skus got included here
  sum(w1_forecast) as w1_forecast,
    sum(safety_stock) as safety_stock,
    sum(latest_inventory) as latest_inventory,
    sum(latest_sl_inventory) as latest_sl_inventory,
    sum(expected_to_sell) as expected_to_sell,
    avg(latest_buyprice) as latest_buyprice,
    (coalesce(sum(ge.adds) / sum(g.locations), 0) + 1) as growth_factor,
    sum(total_to_buy) as base_total_to_buy,
    ceil(
      sum(total_to_buy)
      * case when avg(p.phase) = 1 then 1.30 else 1.0 end -- HACK: phase 1 products get 30% because they're sold on cash also
  * (coalesce(sum(ge.adds) / sum(g.locations), 0) + 1) -- growth
  ) as total_to_buy
  from summaries.forecast_4_weeks f
  left join avocado.vw_products p on f.sku = p.product_id
  left join growth g on f.market = g.market
  left join growth_expectations ge (market, adds) on f.market = ge.market
  where lower(p.visibility_1) <> 'discontinued'
  group by 1,2,3
),

suppliers as (
  select
  distinct
  market,
    fsid as supplier_id,
    code
  from avocado.data_location
  where supply_chain_level = 'supplier'
),

vw as (
  select
    f.market,
    f.sku,
    f.product,
    pr.supplier,
    greatest(0, total_to_buy - coalesce(current_opening,0)) as total_to_buy_after_held, -- minus held
    s.supplier_id,
    m.aliases
  from forecasts f
  left join helds h on f.product = h.product and f.market = h.market
  left join avocado.vw_products pr on f.sku = pr.product_id
  left join suppliers s on pr.supplier = s.code
  left join "avocado"."data_market" "m" ON f.market ilike any(m.aliases)
)

select * from vw WHERE total_to_buy_after_held > 0
`

const getQuery = (props) => {
  const { market } = props
  let query = defaultQuery
  const values = []
  if (market) {
    query = `${query} and $1 ilike any(aliases)`
    values.push(market)
  }
  return {
    query,
    values
  }
}

module.exports = getQuery
