const XLSX = require('@sheet/core')
const SHEET_NAME = 'Warehouse shipment export'
const keyBy = require('lodash/keyBy')
const get = require('lodash/get')
const { getAll: getServices } = require('../../../service')

exports.bulkImport = async function (state, {api, bufferData, startDate, endDate, config, dryRun = false}) {
  // This bulk import is currently only used by PSM
  // 1. We read the sheet and get data for each route
  const workbook = XLSX.read(bufferData, {type: 'array'})
  const allocationSheet = workbook.Sheets[SHEET_NAME]
  if (!allocationSheet) {
    throw new Error(`Worksheet '${SHEET_NAME}' not found`)
  }

  const rows = XLSX.utils.sheet_to_json(allocationSheet)

  // Check for the presence of suborderid in all rows
  const missingSuborderIds = []
  rows.forEach((row, index) => {
    if (!row.subOrderId) {
      missingSuborderIds.push(row.locationCode)
    }
  })

  if (missingSuborderIds.length > 0) {
    throw new Error(` ${missingSuborderIds.join(', ')} missing subOrderId`)
  }

  // Get services for program
  const distinctPrograms = [...new Set(rows.map(row => row.programId))]

  const programServices = await distinctPrograms.reduce(async (accPromise, programId) => {
    // Wait for the accumulator promise to resolve
    const acc = await accPromise

    // Get services for the current programId
    const services = await getServices(state, programId)

    // Extract service IDs
    const serviceIds = services.map(service => service.id)

    // Assign serviceIds to the corresponding programId
    acc[programId] = serviceIds

    return acc
  }, Promise.resolve({}))

  // Get each distinct route with all programIds
  // Create a map to group programIds by route
  const routeMap = new Map()

  // Iterate over rows and group programIds by route
  rows.forEach(row => {
    const { route, programId } = row

    if (routeMap.has(route)) {
      const existingProgramIds = routeMap.get(route)

      // Check if programId is already in the array, if not, add it
      if (!existingProgramIds.includes(programId)) {
        existingProgramIds.push(programId)
      }
    } else {
      // If route doesn't exist, create a new entry with programId in an array
      routeMap.set(route, [programId])
    }
  })

  // Convert the map to an array of objects with distinct routes and their programIds
  const distinctRoutes = Array.from(routeMap, ([route, programIds]) => ({
    route,
    programIds
  }))

  // Save those routes to the DB
  const routesToSave = distinctRoutes.map(row => {
    return {
      name: row.route,
      startDate,
      endDate,
      useUUID: true,
      programs: row.programIds.reduce((acc, programId) => {
        // Populate the programs object with programId as the key
        acc[programId] = {
          services: programServices[programId]
        }
        return acc
      }, {})
    }
  })

  const savedRoutes = []

  for (let route of routesToSave) {
    const savedRoute = await api.routes.save(route, config)
    savedRoutes.push(savedRoute)
  }

  const routesByName = keyBy(savedRoutes, 'name')
  const transfromedRows = rows.map(row => {
    return {
      ...row,
      startDate,
      endDate,
      routeId: get(routesByName[row.route], '_id')
    }
  })

  if (dryRun) return transfromedRows

  // Send those routes and locations to an SQS queue
  await api.routes.bulkImportLocationRoutes(transfromedRows)
}
