import React, { Component, Fragment } from 'react'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'

import { Loading, PriceDisplay, PrintTemplate, SubscriptionTypeLabel, Text, TotalAmount } from '@fielded/shared-ui'
import { triggerPrint } from '@fielded/shared-ui/src/components/Page'
import { PayAsYouSell } from '@fielded/shared-ui/src/icons'
import { DIRECT_ORDER_TYPES, DIRECT_ORDER_TYPES_HUMANIZED } from '@fielded/fs-api/lib/allocation/config'

import { withApi } from '../../../../../common/ApiProvider'
import { hasFeature } from '../../../../../van-shared/utils/features'

import ShipmentContentsTable from '../../../components/ShipmentContentsTable'
import ShipmentPrescriptionContentsList from '../../../components/ShipmentPrescriptionContentsList'
import { getPlannedQuantities, makeDeliveryItems } from '../../../common/makeDeliveryItems'
import { getTotalPackedItemVAT } from '../../../common/utils'
import ShipmentValueSummary from '../ShipmentValueSummary'
import NoteHeading from '../NoteHeading'

const { PAY_ON_DELIVERY, PAY_AS_YOU_SELL, PURCHASE, IMMEDIATE_PURCHASE } = DIRECT_ORDER_TYPES
const LOADING_NOT_STARTED = 'not-started'
const LOADING_IN_PROGRESS = 'in-progress'
const LOADING_COMPLETE = 'complete'

class SLPackageNote extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: LOADING_NOT_STARTED,
      items: null
    }
  }

  getNumber = num => num || 0

  getTotal = (items, addVAT) => {
    return items.reduce((sum, item) => {
      const lineTotalVat = getTotalPackedItemVAT(addVAT, item)
      sum += lineTotalVat + (this.getNumber(item.packed) * this.getNumber(item.price))
      return sum
    }, 0)
  }

  async componentDidMount () {
    const { loading } = this.state
    if (loading !== LOADING_NOT_STARTED) {
      return
    }

    this.setState({ loading: LOADING_IN_PROGRESS })

    const {
      shipment,
      productsById,
      api,
      config
    } = this.props

    const locationId = shipment.destination.id

    let location = await api.location.get(locationId)
    if (!location) {
      const locations = await api.location.getLocationsViaIds([locationId])
      location = locations[0]
    }
    const showVAT = hasFeature(config.features, `showVAT.${get(location, 'location.country')}`)

    // To get the quantities from sent snapshot we need to translate the batches in case the market of operator and retailer is different
    const plannedQuantities = getPlannedQuantities(shipment)
    const translatedPlannedQuantities = await api.shipment.bulkTranslateShipmentProducts(shipment, location, productsById, plannedQuantities.counts)
    plannedQuantities.counts = translatedPlannedQuantities

    // If the items were packed, but not delivered, we will be missing product information. We need to fetch it.
    const missingIds = Object.keys(plannedQuantities.counts)
      .map(p => p.split(':manufacturer')[0])
      .filter(id => !productsById[id])

    let missingProducts = []
    let updatedProductsById = productsById
    if (missingIds.length) {
      missingProducts = await api.product.getProductsViaIds(missingIds)
      try {
        missingProducts = await api.product.getByIds(missingIds)
        if (missingProducts && missingProducts.length !== missingIds.length) {
          missingProducts = await api.product.getProductsViaIds(missingIds)
        }
      } catch (error) {
        console.error(error)
      }
      const missingProductsByIds = keyBy(missingProducts, '_id')
      updatedProductsById = {...productsById, ...missingProductsByIds}
    }

    const items = makeDeliveryItems({ shipment, productsById: updatedProductsById, plannedQuantities, customCountsObject: plannedQuantities.counts }) || []
    const hasPrescriptions = items.some(item => item.prescription)

    const POD = items.filter((item) => item.subscriptionType === PAY_ON_DELIVERY)
    const PAYS = items.filter((item) => item.subscriptionType === PAY_AS_YOU_SELL)
    const purchases = items.filter((item) => item.subscriptionType === PURCHASE)
    const immediatePurchase = items.filter((item) => item.subscriptionType === IMMEDIATE_PURCHASE)

    const packageTotal = this.getTotal(items, showVAT)
    const totalPAYS = this.getTotal(PAYS, showVAT)
    const totalPOD = this.getTotal(POD, showVAT)
    const totalPurchases = this.getTotal(purchases, showVAT)
    const totalImmediatePurchase = this.getTotal(immediatePurchase, showVAT)

    const date = get(shipment, 'snapshotDates.sent', shipment.date)

    const printableDocumentName = `Package note - ${location.name} - ${date}`

    this.setState({
      location,
      loading: LOADING_COMPLETE,
      packageTotal,
      POD,
      totalPOD,
      PAYS,
      totalPAYS,
      purchases,
      totalPurchases,
      immediatePurchase,
      totalImmediatePurchase,
      showVAT,
      hasPrescriptions,
      items,
      date
    }, triggerPrint(printableDocumentName))
  }

  render () {
    const {
      shipment
    } = this.props

    const {
      loading,
      location,
      packageTotal,
      POD,
      totalPOD,
      PAYS,
      totalPAYS,
      purchases,
      totalPurchases,
      immediatePurchase,
      totalImmediatePurchase,
      showVAT,
      hasPrescriptions,
      items
    } = this.state

    if (loading !== LOADING_COMPLETE) {
      return <Loading loadingText='Loading package note …' />
    }

    const shipmentCountry = shipment.destination.country

    return (
      <PrintTemplate>
        <PrintTemplate.Section>
          <div className='delivery-note-print'>
            <div className='delivery-note-print__header'>
              <div className='delivery-note-print__heading'>
                <NoteHeading shipment={shipment} />
              </div>
              <div className='delivery-note-print__summary'>
                <ShipmentValueSummary
                  title={location.fullName}
                  label='Packed Total Value'
                  total={packageTotal}
                  country={shipmentCountry}
                />
              </div>

            </div>
            {PAYS.length > 0 && (
              <section className='vs-u-margin-top-tripple'>
                <SubscriptionTypeLabel
                  subscriptionType={DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL}
                />
                <ShipmentContentsTable
                  className='vs-u-margin-top'
                  items={PAYS}
                  type='package'
                  shipmentCountry={shipmentCountry}
                  includeVat={showVAT}
                />
                <div className='sl-package-note__total-pays'>
                  <span className='sl-package-note__total-text'>
                    <PayAsYouSell />
                    <Text size='xsmall'>
                      Total value of {DIRECT_ORDER_TYPES_HUMANIZED[PAY_AS_YOU_SELL]} products delivered:
                    </Text>
                  </span>
                  <PriceDisplay
                    value={totalPAYS}
                    country={shipmentCountry}
                    currencySymbol
                    bold
                  />
                </div>
              </section>
            )}
            {POD.length > 0 && (
              <section className='vs-u-margin-top-tripple'>
                <SubscriptionTypeLabel
                  subscriptionType={DIRECT_ORDER_TYPES.PAY_ON_DELIVERY}
                />
                <ShipmentContentsTable
                  className='vs-u-margin-top'
                  items={POD}
                  type='package'
                  shipmentCountry={shipmentCountry}
                  includeVat={showVAT}
                />
                <TotalAmount
                  value={totalPOD}
                  countryId={shipmentCountry}
                  label='Sub-total to pay'
                  isTight
                />
              </section>
            )}
            {purchases.length > 0 && (
              <section className='vs-u-margin-top-tripple'>
                <ShipmentContentsTable
                  className='vs-u-margin-top'
                  items={purchases}
                  type='package'
                  shipmentCountry={shipmentCountry}
                  includeVat={showVAT}
                />
                <TotalAmount
                  value={totalPurchases}
                  countryId={shipmentCountry}
                  label='Sub-total to pay'
                  isTight
                />
              </section>
            )}
            {immediatePurchase.length > 0 && (
              <section className='vs-u-margin-top-tripple'>
                <ShipmentContentsTable
                  className='vs-u-margin-top'
                  items={immediatePurchase}
                  type='package'
                  shipmentCountry={shipmentCountry}
                  includeVat={showVAT}
                />
                <TotalAmount
                  value={totalImmediatePurchase}
                  countryId={shipmentCountry}
                  label='Sub-total to pay'
                  isTight
                />
                {!!shipment.deliveryFee && (
                  <Fragment>
                    <TotalAmount
                      value={shipment.deliveryFee}
                      countryId={shipmentCountry}
                      label='Delivery fee'
                      isTight
                    />
                    <TotalAmount
                      value={totalImmediatePurchase + shipment.deliveryFee}
                      countryId={shipmentCountry}
                      label='Total to pay'
                      isTight
                    />
                  </Fragment>
                )}
              </section>
            )}
            {hasPrescriptions && (
              <section className='vs-u-margin-top-tripple'>
                <ShipmentPrescriptionContentsList
                  items={items}
                />
              </section>
            )}
          </div>
        </PrintTemplate.Section>
      </PrintTemplate>
    )
  }
}

export default withApi(SLPackageNote)
