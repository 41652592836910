const get = require('lodash/get')
const { getByIds: findLocationsById, listChildren: listLocationChildren, listRelated: listRelatedLocations, get: getLocationDoc } = require('./../../location')
const { listPrograms } = require('./../../program/api')
const { list: listBatches } = require('./../../batch')
const { listForPrograms: listProductsForPrograms, getByIds: getProductsByIds, listAll: listAllProducts } = require('./../../product')
const { listReportFields } = require('./../../service')
const mdTools = require('./../../tools')
const {someServiceIdentifiersSupported} = require('./../../location/tools')
const { getSupplierIdsForLocation } = require('../api/read/get-supplier-ids-for-location')

exports.getUserStateLocationId = getUserStateLocationId
function getUserStateLocationId (state) {
  // We get the location id of the state, which should
  // work for both planner and operator users.
  return state.user.location.id.split(':sdp:')[0]
}

exports.getLocations = getLocations
async function getLocations (state, programId, parentLocationId, date = new Date().toJSON()) {
  const locations = await listLocationChildren(state, parentLocationId, {deep: true, date})
  return mdTools.filterLocationsByProgram(locations, programId)
}

// TODO: merge all the things
exports.getLocationsForRoutes = getLocationsForRoutes
async function getLocationsForRoutes (
  state, parentLocationId, date = new Date().toJSON(), routesFilter, orderType
) {
  // Not applying funders filter here so we can get all locations, including suppliers,
  // in one call
  const allLocations = await listRelatedLocations(state, parentLocationId, {deep: true, date})
  const locationsOnRoutes = allLocations
    .filter(location => someServiceIdentifiersSupported(location, routesFilter))

  const isOrdersActive = location => {
    const active = get(location, 'additionalData.active')
    const orderStatus = get(location, 'additionalData.ordersStatus')
    const ordersActive = orderStatus !== 'suspended'
    return active && ordersActive
  }
  const activeLocations = locationsOnRoutes.filter(isOrdersActive)
  if (activeLocations.length === 0) throw new Error('No active locations in this route')
  const suspendedLocations = locationsOnRoutes.filter(location => !isOrdersActive(location))
  const options = {
    planType: orderType
  }

  const location = await getLocationDoc(state, activeLocations.map(d => d._id)[0])
  const supplierIds = await getSupplierIdsForLocation(state, location, options)
  const suppliers = await findLocationsById(state, supplierIds, date)

  return {activeLocations, suspendedLocations, allLocations, suppliers}
}

// So creating orders can get suppliers as well as SDPs
exports.getRelatedLocations = getRelatedLocations
async function getRelatedLocations (state, programId, date = new Date().toJSON()) {
  const locations = await listRelatedLocations(state, undefined, {deep: true, date})
  return mdTools.filterLocationsByProgram(locations, programId)
}

exports.getLocationsById = getLocationsById
async function getLocationsById (state, programId, locationIds, date = new Date().toJSON()) {
  const locations = await findLocationsById(state, locationIds, date)
  return mdTools.filterLocationsByProgram(locations, programId)
}

exports.getPrograms = getPrograms
function getPrograms (state) {
  return listPrograms(state, true)
}

// TODO: master data API program.get(id)
exports.getProgram = getProgram
async function getProgram (state, programId) {
  const programs = await listPrograms(state, true)
  return programs.find(({id}) => id === programId)
}

// onSDPServices is to create orders without the products only
// reported on at the warehouse
exports.getProducts = getProducts
async function getProducts (state, programId, {onSDPServices} = {}) {
  const allProducts = await listProductsForPrograms(state, [programId])
  const realProducts = mdTools.product.filterByReal(allProducts)
  return onSDPServices
    ? realProducts.filter(hasSDPService)
    : realProducts
}

function hasSDPService ({services}) {
  return services && services.find(service => !service.id.includes('service:warehouse'))
}

// groupId is optional
exports.getProductsOnOrders = getProductsOnOrders
async function getProductsOnOrders (state, orders, groupId) {
  const productIdsSet = new Set()
  orders
    .filter(order => !groupId || (order.groupId === groupId))
    .forEach(order =>
      Object.keys(order.products).forEach(
        productId => productIdsSet.add(productId)
      )
    )
  const allProducts = await getProductsByIds(state, [...productIdsSet])
  return mdTools.product.filterByReal(allProducts)
}

exports.getAllLocations = getAllLocations
function getAllLocations (state, date = new Date().toJSON()) {
  const parentLocationId = getUserStateLocationId(state)
  return listLocationChildren(
    state, parentLocationId, {deep: true, date}
  )
}

exports.getFields = getFields
async function getFields (state, services) {
  const fieldDefinitions = await Promise.all(
    services.map((service) => listReportFields(state, service.id))
  )
  return fieldDefinitions.reduce((acc, fields, index) => {
    acc[services[index].id] = fields
    return acc
  }, {})
}

exports.getProductAliasMasterData = getProductAliasMasterData
async function getProductAliasMasterData (state, {programId, batches, productIds} = {}) {
  let allProducts
  if (productIds) {
    allProducts = await getProductsByIds(state, productIds)
  } else if (programId) {
    allProducts = await listProductsForPrograms(state, [programId])
  } else {
    allProducts = await listAllProducts(state)
  }

  // NOTE: batches can be undefined, then it lists all
  const allBatches = await listBatches(state, { ids: batches })
  return {allProducts, allBatches}
}
