const PGAdapter = require('./../common/pg-adapter')
const fs = require('fs')
const path = require('path')

class PresentationCostBasisPGAdapter extends PGAdapter {
  constructor ({pgConnection, username, logger}) {
    super(pgConnection, 'real.presentation_cost_basis', username, ['presentation_id', 'date'], 'presentation_id')
    this.logger = logger
  }

  /*
    `presentation_cost_basis` is calculated by iteratively building a model of
    aggregating all clients' shelves accross the country, using daily buyprices
    from buypricedaily and deliveries tables.
    This overloads the `POST` method against this endpoint to delete any
    presentation cost basis rows that might exist for the {date, presentation_id}
    and recalculate them using the latest buyprices
  */
  async create ({sku, date}) {
    this.logger.debug('regenerating presentation_cost_basis')
    if (sku && date) {
      await this.pgConnection.query(`
        UPDATE real.presentation_cost_basis_base
        SET _deleted=true
        WHERE date >= $1
        AND presentation_id = (
          SELECT presentation_id
          FROM avocado.data_productsku
          WHERE old_sku_id = $2
        )
      `, [date, sku])
      await this.pgConnection.query(`
        DELETE
        FROM real.presentation_cost_basis
        WHERE date >= $1
        AND presentation_id = (
          SELECT presentation_id
          FROM avocado.data_productsku
          WHERE old_sku_id = $2
        )
      `, [date, sku])
    }

    const response = []

    // these scripts are run on every pipeline run also so referencing
    // them here rather than duplicating
    const scripts = [
      'api/sql/cost_basis/presentation_cost_basis_incremental.sql',
      // regenerate tables which will have been cascade dropped
      'api/sql/price_calculator/suggestions.sql'
    ]

    for (let i = 0; i < scripts.length; i++) {
      const buffer = await fs.promises.readFile(path.resolve(__dirname, '../../../' + scripts[i]))
      const query = buffer && buffer.toString()
      buffer && response.push(await this.pgConnection.query(query))
    }
    this.logger.debug('done regenerating presentation_cost_basis')
    // todo: sku is a bogus return value needed by RA. should update the data-provider there
    // not return junk here
    return { id: sku || '', count: response.reduce((a, c) => a + (c.rowCount || 0), 0) }
  }
  async delete () {
    throw new Error('buyprice_daily cannot be modified directly')
  }
  async update () {
    throw new Error('buyprice_daily cannot be modified directly')
  }
}

module.exports = { PresentationCostBasisPGAdapter }
