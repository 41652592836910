import React, { useRef, useState } from 'react'
import { Redirect, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'

import { findShipments } from '../../../shipments-reducer'
import { hasFeature } from '../../../../../../van-shared/utils/features'
import { withApi } from '../../../../../../common/ApiProvider'
import { withUser } from '../../../../../../common/AuthenticationProvider'
import withConfig from '../../../../../../van-shared/hoc/withConfig'
import ConfirmPickUp from './ConfirmPickUp'

const ConfirmPickUpContainer = ({
  api,
  user,
  config,
  history
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  if (!location.state) {
    return <Redirect to='/' />
  }

  const driverName = get(user, 'fullName', '')

  const [formData] = useState({
    warehouseManagerName: '',
    signature: '',
    driverName
  })

  const formRef = useRef()
  const { packedDeliveries, selectedDeliveries } = location.state
  const situation = packedDeliveries.length === selectedDeliveries.length ? 'ready' : 'caution'

  const onProceed = async () => {
    const formValidationErrors = await formRef.current.validate()
    const fields = formRef.current.getFields()
    const payload = {
      ...fields,
      shipments: selectedDeliveries
    }

    if (!formValidationErrors) {
      // Advance selected shipments
      try {
        const updatedSnapshots = await api.shipment.bulkAdvanceSnapshots(payload)
        if (updatedSnapshots && updatedSnapshots.length) {
          const userLocation = user.location
          const withListInGeoLocation = hasFeature(config.features, 'shipments:listInGeoLocation')
          const useRouteSpecificFilter = true
          const options = { noExternalShipments: true }

          // Update store
          await dispatch(
            findShipments(
              userLocation,
              withListInGeoLocation,
              useRouteSpecificFilter,
              options
            )
          )
        }
        history.push('/shipments/list')
      } catch (e) {
        throw new Error(`Error found: ${e}`)
      }
    }
  }

  return (
    <ConfirmPickUp
      formRef={formRef}
      formData={formData}
      history={history}
      situation={situation}
      selectedDeliveries={selectedDeliveries}
      onProceed={onProceed}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig
)

export default withHOCs(ConfirmPickUpContainer)
