const XLSX = require('@sheet/core')
const format = require('date-fns/format')
const orderBy = require('lodash/orderBy')
const { getServiceForLocationId } = require('../../service/tools')
const smartId = require('../../tools/smart-id')
const { ORDER_TYPES } = require('../constants')

const generateSheetData = (data, locationId) => {
  let rows = data.rows
  rows = orderBy(rows, ['routeName'], ['asc'])
  const priceType = data.orderType === ORDER_TYPES.resupply ? 'Buy Price' : 'Sell Price'

  const location = getServiceForLocationId(locationId)
  const {country} = smartId.parse(locationId)

  const companyName = country === 'ng'
    ? 'Field Technology Development Partners Nigeria, LTD'
    : 'Field Technology Development Partners, LTD'

  const aoa = [
    ['F/ELD'],
    [companyName, '', 'Order created on:', format(new Date(data.createdAt), 'DD-MM-YYYY')],
    [`Market: ${location.name}`, '', 'Order valid till:', format(new Date(data.deliveryDate), 'DD-MM-YYYY')],
    [`Supplier: ${data.supplier.name}`, '', 'Reference ID:', data.orderId],
    [''],
    ['Item', 'SKU Id', priceType, 'Quantity', 'Subtotal']
  ]
  rows.forEach(row => {
    aoa.push([row.productName, row.code, row.buyPrice, row.quantity, row.subTotal])
  })
  aoa.push(['', '', '', '', '', 'Total', data.total])
  aoa.push([''])
  aoa.push(['TERMS & CONDITIONS'])
  aoa.push(['Kindly cite our order Reference ID in your invoice.'])
  aoa.push([''])
  if (data.creator) {
    aoa.push([`Order Created By ${data.creator.fullName}`])
  }

  const ws = XLSX.utils.aoa_to_sheet(aoa)
  ws['A1'].s = { bold: true, sz: 36, name: 'Arial Nova' }
  if (rows.length > 0) {
    ws['A2'].s = { bold: true, sz: 12 }

    ws['A3'].s = { bold: true, sz: 12 }
    ws['A4'].s = { bold: true, sz: 12 }

    XLSX.utils.sheet_set_range_style(ws, 'A6:G6', {
      bold: true,
      sz: 12
    })
  }

  const boldTitles = ['Total', 'TERMS & CONDITIONS']
  for (const title of boldTitles) {
    const row = Object.keys(ws).find(v => ws[v].v === title)
    if (row) {
      ws[row].s = { bold: true, sz: 12 }
    }
  }

  // set the column width
  ws['!cols'] = [
    {wpx: 330},
    {wpx: 265},
    {wpx: 140},
    {wpx: 200} // "pixels"
  ]
  return {
    ws,
    name: data.supplier.name
  }
}

const generateExcelSheet = (result, supplierId, locationId) => {
  const sheetsList = []
  if (supplierId) {
    sheetsList.push(generateSheetData(result[supplierId], locationId))
  } else {
    sheetsList.push(...Object.values(result).map(val => generateSheetData(val, locationId)))
  }

  const wb = XLSX.utils.book_new()
  for (const sheet of sheetsList) {
    const sheetName = sheet.name.substring(0, 31) //  Sheet names cannot exceed 31 chars
    XLSX.utils.book_append_sheet(wb, sheet.ws, sheetName)
  }
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    cellStyles: true,
    type: 'array'
  })
  return new global.Blob([wbout], {type: 'application/octet-stream'})
}

module.exports = generateExcelSheet
