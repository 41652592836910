const keyBy = require('lodash/keyBy')
const get = require('lodash/get')
const uuid = require('uuid/v4')
const { subWeeks } = require('date-fns')

const { periodIdToDate } = require('../../report/tools/ids')
const docToVanRecord = require('../../shipment/tools/doc-to-van-record')
const docToStockCountRecord = require('../..//tools/doc-to-stock-count-record')

const { Checkpoint, withChanges } = require('../../common/changes-feed')
const { callInBatches } = require('../../common/call-in-batches')
const getRestPayload = require('./tools/get-rest-payload')
const { isBasicTierService } = require('../../tools')

const PROGRAM_ID = 'program:shelflife'
const REPORTS_CHECKPOINT_ID = 'stock-situation-reports'
const SHIPMENTS_CHECKPOINT_ID = 'stock-situation-shipments'

const MAX_REQUEUES = 3

const periodIdToYearweek = periodId => periodId.split(/\D+/).join('.')

/* Extract stock summary from couchdb and load into postgres
 *
 * This provides methods to run the stock situation export
 * either based on changes from couchdb or manually and
 * loads the results of the export into postgres via avocado.
 *
 * The methods used by the kinesis ETL flow are:
 * - `extractAndQueue`
 *   Read stock counts and shipments from couchdb changes
 *   and generate export params to queue up for the stock
 *   situation export performed by `transformAndLoad`.
 *
 * - `transformAndLoad`
 *   Runs a stock situation export for every queue item passed,
 *   transforms the result and posts it to avocado.
 *
 * The standalone method `runForLocationsAtDate` can be used
 * to manually run the `transformAndLoad` step for some
 * locations and a single reporting period.
 */
class StockSummaryToAvocado {
  constructor (api, logger, reportsDB, shipmentsDB) {
    this.api = api
    this.logger = logger
    this.reportsDB = reportsDB
    this.shipmentsDB = shipmentsDB
  }

  async extractAndQueue (writeToQueue) {
    let items = []
    const changesStats = await withChanges(
      [
        {
          db: this.reportsDB,
          checkpointId: REPORTS_CHECKPOINT_ID,
          feedOptions: { batchSize: 100, maxChanges: 400, includeDeleted: true }
        },
        {
          db: this.shipmentsDB,
          checkpointId: SHIPMENTS_CHECKPOINT_ID,
          feedOptions: { batchSize: 100, maxChanges: 400, includeDeleted: true }
        }
      ],
      async ([reportChanges, shipmentChanges]) => {
        const reportDocs = []
        for (const c of reportChanges) {
          if (c.doc && c.doc.type === 'stockCount') {
            reportDocs.push(c.doc)
          } else if (c.deleted && !c.doc) {
            this.logger.warn('stockCount deletion found:', c.id, 'does not have stubs, please clean up avocado database manually')
          }
        }
        const shipmentDocs = []
        for (const c of shipmentChanges) {
          if (c.doc && (c.doc.type === 'snapshot' || c.doc.type === 'change')) {
            shipmentDocs.push(c.doc)
          } else if (c.deleted && !c.doc) {
            this.logger.warn('shipment deletion found:', c.id, 'does not have stubs, please clean up avocado database manually')
          }
        }
        this.logger.info('found report docs', reportDocs.length)
        this.logger.info('found shipment docs', shipmentDocs.length)

        items = await this._changesToQueueItems(reportDocs, shipmentDocs)
        this.logger.info('generated params for', items.length, 'exports')

        if (writeToQueue) {
          await writeToQueue(items)
        }
      }
    )
    this.logger.info(changesStats)
    return items
  }

  async _changesToQueueItems (reportDocs, shipmentDocs) {
    const {
      services, servicesById, locations, locationsById
    } = await this._getRelatedEntities()

    this.logger.info('found', services.length, 'services', Object.keys(servicesById))
    this.logger.info('found', locations.length, 'locations')

    const reportsForShipments = await this._findReportsForShipmentDocs(locationsById, servicesById, shipmentDocs)
    this.logger.info('found', reportsForShipments.length, 'stock counts for shipments')

    const allReportDocs = reportDocs.concat(reportsForShipments)
    return this._makeExportParamsForReports(services, servicesById, allReportDocs)
  }

  async transformAndLoad (exportParams, writeToQueue) {
    const transactionId = uuid()
    const locationIds = [...exportParams.reduce(
      (set, e) => set.add(e.locationId), new Set()
    )]
    const periods = [...exportParams.reduce(
      (set, e) => set.add(e.periodId), new Set()
    )]

    const locations = await this.api.location.getByIds(locationIds)
    const locationsById = keyBy(locations, '_id')

    this.logger.info('exports', exportParams.map(p => p.locationId + ' - ' + p.periodId))
    this.logger.info('loading products for periods', periods)

    // Fetch the products once to pass them
    // later to the export. Otherwise the products will
    // be loaded on each run of the export.
    let productsByPeriod = {}
    const dates = periods.map(p => periodIdToDate(p).toJSON())
    if (dates.length > 0) {
      const productsByDate = await this.api.product.listAllForDates({ dates })
      for (let i = 0; i < periods.length; ++i) {
        productsByPeriod[periods[i]] = productsByDate[i]
      }
    }

    // run the export for a location/period and post rows to rest endpoint
    const transformAndLoadLocation = async ({ locationId, serviceId, periodId }) => {
      // find the location
      const location = locationsById[locationId]
      if (!location) {
        // If we don't find the location, we just skip
        this.logger.warn('Location not found', locationId, 'skipping export for period', periodId)
        return
      }
      // Occasionally we run into locations that don't have a uuid,
      // which means they cannot be linked to a location in postgres
      // and would make posting the export result to avocado fail.
      if (!location.additionalData.uuid) {
        this.logger.warn('Location is missing uuid', locationId, 'skipping export for period', periodId)
        return
      }

      // run the stock situation export
      let exportData
      try {
        this.logger.info('starting stock situation export for:', locationId, periodId, serviceId)
        exportData = await this.api.stock.exportStockSituationData(
          locationId, serviceId, periodId, {
            locations,
            products: productsByPeriod[periodId],
            includeChildren: false,
            locale: 'en-US',
            adjustments: true
          }
        )
        this.logger.info('finished stock situation export for:', locationId, periodId)
      } catch (err) {
        this.logger.error('failed to export stock situation report for:', locationId, periodId)
        throw err
      }

      // transform stock situation export data to rows for the rest api
      const transformedRows = this._arraysToObjects(exportData)
      const payloadRows = []
      for (const transformedRow of transformedRows) {
        const dbRow = getRestPayload(transformedRow, transactionId, location, serviceId)
        if (dbRow.sku === 'n/a') {
          this.logger.warn('missing product in couch - row will not be exported', locationId, periodId, dbRow)
        } else {
          payloadRows.push(dbRow)
        }
      }

      // post rows to rest endpoint
      const yearweek = periodIdToYearweek(periodId)
      const translatedLocationId = locationId.replace(/^country:/, '')
      this.logger.info('posting rows to rest endpoint', translatedLocationId, yearweek, payloadRows.length)
      try {
        await this.api.stock.restAdapter.createStockSummary(translatedLocationId, yearweek, payloadRows)
      } catch (err) {
        this.logger.info('failed to post stock situation rows to rest api for', locationId, periodId, err)
        throw err
      }
    }

    // Run the export and post the result to avocado in batches
    const promiseCreators = exportParams.map(params => async () => {
      try {
        return await transformAndLoadLocation(params)
      } catch (e) {
        if (!writeToQueue) {
          // No queue function provided, no requeue
          throw e
        } else {
          // The transform and load failed, we are going to requeue the export
          // until we hit the max requeue limit for this.
          this.logger.info('transform and load failed', e)
          if (params.requeueCount > MAX_REQUEUES) {
            this.logger.warn('failed too many times, not requeueing', params)
          } else {
            this.logger.info('requeueing', params)
            return writeToQueue([{
              ...params,
              requeueCount: params.requeueCount ? params.requeueCount + 1 : 1
            }])
          }
        }
      }
    })
    return callInBatches(promiseCreators, { batchSize: 5 })
  }

  // now the class is double wrong,
  // its neither the stock summary anymore (its stock events)
  // nor it syncs to avocado (syncs to RDS directly)
  // ¯\_(ツ)_/¯
  async transformAndLoadEvents (exportParams, writeToQueue) {
    const transactionId = uuid()
    const locationIds = [...exportParams.reduce(
      (set, e) => set.add(e.locationId), new Set()
    )]

    const locations = await this.api.location.getByIds(locationIds)
    const locationsById = keyBy(locations, '_id')

    this.logger.info('exports', exportParams.map(p => p.locationId + ' - ' + p.periodId))

    // run the export for a location/period and post rows to rest endpoint
    const transformAndLoadLocation = async ({ locationId, serviceId, periodId }) => {
      // find the location
      const location = locationsById[locationId]
      if (!location) {
        // If we don't find the location, we just skip
        this.logger.warn('Location not found', locationId, 'skipping export for period', periodId)
        return
      }
      // Occasionally we run into locations that don't have a uuid,
      // which means they cannot be linked to a location in postgres
      // and would make posting the export result to avocado fail.
      if (!location.additionalData.uuid) {
        this.logger.warn('Location is missing uuid', locationId, 'skipping export for period', periodId)
        return
      }

      // run the stock situation export
      let exportData
      try {
        this.logger.info('starting stock situation export for:', locationId, periodId, serviceId)
        exportData = await this.api.stock.stockSituationEvent(
          locationId, serviceId, periodId, {
            locations,
            includeChildren: false,
            locale: 'en-US',
            adjustments: true
          }
        )
        this.logger.info('finished stock situation export for:', locationId, periodId)
      } catch (err) {
        this.logger.warn('failed to export stock situation report for:', locationId, periodId)
        throw err
      }

      // transform stock situation export data to rows for the rest api
      // Stock Situation 2 returns objects
      const locationUuid = get(location, 'additionalData.uuid')
      // TODO: rename location_id to location_fsid

      const payloadRows = exportData.map(row => {
        return {
          ...row,
          transaction_id: transactionId,
          location_id: locationUuid,
          service_id: serviceId,
          data_source: 'event'
        }
      })
      this.logger.info('PayLoad', JSON.stringify(payloadRows, null, 2))

      // post rows to rest endpoint
      const yearweek = periodIdToYearweek(periodId)
      this.logger.info('inserting rows into RDS', locationUuid, yearweek, payloadRows.length)
      try {
        await this.api.stock.pgAdapter.createStockSummaryEvents(locationUuid, yearweek, payloadRows)
      } catch (err) {
        this.logger.info('failed to insert stock situation rows into RDS for', locationId, periodId, err)
        throw err
      }
    }

    // Run the export and post the result to avocado in batches
    const promiseCreators = exportParams.map(params => async () => {
      try {
        return await transformAndLoadLocation(params)
      } catch (e) {
        if (!writeToQueue) {
          // No queue function provided, no requeue
          throw e
        } else {
          // The transform and load failed, we are going to requeue the export
          // until we hit the max requeue limit for this.
          this.logger.info('transform and load failed', e)
          if (params.requeueCount > MAX_REQUEUES) {
            this.logger.warn('failed too many times, not requeueing', params)
          } else {
            this.logger.info('requeueing', params)
            return writeToQueue([{
              ...params,
              requeueCount: params.requeueCount ? params.requeueCount + 1 : 1
            }])
          }
        }
      }
    })
    return callInBatches(promiseCreators, { batchSize: 5 })
  }

  async resetCheckpoints () {
    const reportsCheckpoint = new Checkpoint(this.reportsDB, REPORTS_CHECKPOINT_ID)
    const shipmentsCheckpoint = new Checkpoint(this.shipmentsDB, SHIPMENTS_CHECKPOINT_ID)
    try {
      await reportsCheckpoint.reset()
      await shipmentsCheckpoint.reset()
    } catch (err) {
      this.logger.warn('failed to reset checkpoints')
      throw err
    }
  }

  async runForLocationsAtDate (date, locationIds = null) {
    if (!date) {
      throw new Error('`date` is required')
    }
    const {
      services, locations
    } = await this._getRelatedEntities(locationIds)

    const program = services[0].program
    const period = await this.api.report.period.get({program, date, isEffectiveDate: true})

    const exportParams = []
    for (const l of locations) {
      const params = this._makeExportParams(services, l._id, period.id)
      if (!params) {
        this.logger.warn('Could not make export params for', l._id, period.id)
        continue
      }
      exportParams.push(params.value)
    }
    await this.transformAndLoad(exportParams)
  }

  async _getRelatedEntities (locationIds = null) {
    // TODO: use cache if possible
    const services = await this.api.service.getAll(PROGRAM_ID)
    const servicesById = keyBy(services, 'id')
    const locations = await (
      locationIds != null ? this.api.location.getByIds(locationIds) : this.api.location.listAll()
    )
    const locationsById = keyBy(locations, '_id')
    return {
      services,
      servicesById,
      locations,
      locationsById
    }
  }

  _makeExportParams (services, locationId, periodId) {
    for (const service of services) {
      for (const serviceLocationId of service.locations) {
        if (locationId.startsWith(serviceLocationId)) {
          return {
            key: locationId + ':' + periodId,
            value: {
              locationId,
              serviceId: service.id,
              periodId
            }
          }
        }
      }
    }
  }

  async _findReportsForShipmentDocs (locationsById, servicesById, docs) {
    // Build a list of `{ endDate, locationId }` tuples for findReports.
    // We want to find reports for received snapshots and shipment adjustments.
    const params = []
    for (const doc of docs) {
      let endDates
      let record
      try {
        record = docToVanRecord(doc)
      } catch (e) {
        this.logger.warn('Error creating snapshot', doc._id)
        continue
      }
      if (doc.type === 'change' && doc._id.includes('adjustment')) {
        endDates = [record.createdAt, record.effectiveAt]
      } else if ((doc.type === 'snapshot' && doc._id.includes('received'))) {
        endDates = [record.createdAt]
      } else {
        continue
      }
      for (const endDate of endDates) {
        const startDate = subWeeks(endDate, 4).toJSON()
        const origin = locationsById[record.origin.id]
        if (!origin) {
          this.logger.info('Could not find origin location of', record.id)
        }
        const destination = locationsById[record.destination.id]
        if (!destination) {
          this.logger.info('Could not find destination location of', record.id)
        }
        for (const location of [origin, destination]) {
          if (location == null) {
            continue
          }
          const serviceId = get(location, 'programs.0.services.0.id')
          const service = servicesById[serviceId]
          if (!service) {
            this.logger.info('Error could not find service', serviceId, 'of', record.id)
            continue
          }
          params.push({
            location,
            startDate,
            endDate,
            service
          })
        }
      }
    }
    const reports = []
    for (const { startDate, endDate, location, service } of params) {
      const reportRows = await this.api.report.findForLocation({
        location,
        service,
        startDate,
        endDate,
        entityOptions: { rawRows: true }
      })
      const rows = reportRows.map(r => r.doc).filter(d => !!d)
      this.logger.info('found', rows.length, 'reports for', location._id, 'between', startDate, 'and', endDate)
      if (rows.length === 0) {
        continue
      }

      // take the most recent report
      let report = rows[0]
      for (const r of rows) {
        if (r.submittedAt > report.submittedAt) {
          report = r
        }
      }
      reports.push(report)
    }

    return reports
  }

  _makeExportParamsForReports (services, servicesById, docs) {
    const exportParamsMap = new Map()

    for (const doc of docs) {
      const service = servicesById[doc.serviceId]
      if (!service) {
        this.logger.warn(`no service found for doc ${doc._id}`)
        continue
      }
      let stockCountRecord
      try {
        stockCountRecord = docToStockCountRecord(doc, service)
      } catch (error) {
        // lots of docs on dev data come here
        this.logger.warn(`could not docToStockCountRecord for doc ${doc._id}`, error)
        continue
      }
      const {
        date: { reportingPeriod },
        location: { id: locationId }
      } = stockCountRecord
      let params
      if (isBasicTierService(service.id)) {
        params = {
          key: locationId + ':' + reportingPeriod,
          value: {
            locationId,
            serviceId: service.id,
            periodId: reportingPeriod
          }
        }
      } else {
        params = this._makeExportParams(services, locationId, reportingPeriod)
      }
      if (!params) {
        this.logger.warn('Failed to make export params for stock count', doc.id)
        continue
      }
      exportParamsMap.set(params.key, params.value)
    }
    return [...exportParamsMap.values()]
  }

  _arraysToObjects (stockSituationExport) {
    const [headers] = stockSituationExport
    return stockSituationExport.slice(1)
      .map((row) => {
        return headers
          .reduce((acc, headerName, index) => {
            acc[headerName] = row[index]
            return acc
          }, {})
      })
  }
}

module.exports = StockSummaryToAvocado
// Need to manipulate those in testing
module.exports.REPORTS_CHECKPOINT_ID = REPORTS_CHECKPOINT_ID
module.exports.SHIPMENTS_CHECKPOINT_ID = SHIPMENTS_CHECKPOINT_ID
