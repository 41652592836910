/*
 * Converts a topup request (which is an order)
 * into the shipment format so we can render it
 *
 * in the shipments list and in the shipments detail views
 * (for retailers)
 */
const { createDestinationShipments } = require('../write/create-group-shipments')
const createShipmentSnapshot = require('../../../shipment/shipment-create-snapshot')
const docsToShipmentRecords = require('../../../shipment/tools/docs-to-shipment-records')
const { DIRECT_ORDER_TYPES } = require('../../../allocation/config.js')
const PLANNING_TYPES = require('../../../shipment/tools/planning-types')
const { ORDER_TYPES } = require('../../constants')

const fakeDeliveryDate = (date = new Date().toJSON()) => {
  return date.split('T')[0]
}

async function orderAsShipment (state, order, packPoints, includeZero, allowTranslateProducts = true) {
  const hasClosedStatus = order.closedStatus != null
  const defaultPackPoint = packPoints[0]

  let orderSnapshot
  let shipmentStatus
  if (order.status === 'request') {
    orderSnapshot = order.snapshotsByStatus.request[0]
    shipmentStatus = 'request'
  } else if (order.status === 'new') {
    orderSnapshot = (order.snapshotsByStatus.new.find(order => packPoints.includes(order.supplierId)) || order.snapshotsByStatus.new[0])
    shipmentStatus = 'in-process'
    if (order.closedStatus === 'cancelled') {
      shipmentStatus = 'cancelled'
    }
  } else {
    // For some reason we have an order status we did not expect in here
    console.warn(`Found order with unexpected status ${order.status} ${order.snapshotId}`)
    return
  }

  let originId = orderSnapshot.supplierId
  if (!originId) {
    // create an originId with spd:unknown, but in the same market/state as destination
    orderSnapshot.supplierId = defaultPackPoint
    originId = defaultPackPoint
  }

  const allProducts = await state.dal.product.getByIds(state, Object.keys(order.products))

  // Create an order shipment snapshot, which is an incomplete shipment snapshot
  const [orderShipmentSnapshot] = createDestinationShipments({
    orderSnapshots: [{
      ...orderSnapshot,
      status: shipmentStatus,
      deliveryDate: fakeDeliveryDate(order.deliveryDate),
      funderId: order.funderId
    }],
    closedAt: order.createdAt,
    products: allProducts,
    defaultSupplier: defaultPackPoint,
    includeZero,
    allowTranslateProducts
  })

  if (!orderShipmentSnapshot) {
    console.warn(`orderShipmentSnapshot does not exists`)
    return
  }

  let planningType
  if (order.orderType === 'topup') {
    planningType = PLANNING_TYPES.TOPUP
  } else if (order.orderType === ORDER_TYPES.purchase) {
    planningType = PLANNING_TYPES.PURCHASE
  } else if (order.orderType === ORDER_TYPES.immediate_purchase) {
    planningType = PLANNING_TYPES.IMMEDIATE_PURCHASE
  } else {
    planningType = PLANNING_TYPES.ROUTINE
  }

  const shipmentSnapshot = await createShipmentSnapshot(
    state, {
      ...orderShipmentSnapshot,
      planningType,
      status: shipmentStatus,
      dryRun: true
    }
  )
  // Create a shipment from the shipment snapshot
  const [shipment] = docsToShipmentRecords(originId, [shipmentSnapshot])

  if (!hasClosedStatus) {
    delete shipment.origin
    delete shipment.shipmentNo
    delete shipment.date
    // Orders with 'request' status will have a placeholder quantity of 1
    // for Pay As You Sell products. We don't want the outside to see that.
    // Orders with 'new' status will have forecast quantities and should keep those.
    if (order.status === 'request') {
      for (const product of Object.values(shipment.counts)) {
        if (product.paymentType === DIRECT_ORDER_TYPES.PAY_AS_YOU_SELL) {
          delete product.quantity
        }
      }
    }
  }

  shipment.orderId = orderSnapshot._id
  shipment.orderDeliveryDate = order.deliveryDate
  shipment.orderCreatedDate = order.createdAt
  shipment.deliveryFee = order.deliveryFee

  return shipment
}
exports.orderAsShipment = orderAsShipment
