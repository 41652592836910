const PGAdapter = require('./../../common/pg-adapter')
const DATA_ORDER_TABLE_NAME = 'avocado.data_order'
const DATA_ORDER_COLUMNS = [
  'id',
  'order_id',
  'sub_order_id',
  'group_id',
  'funder_id',
  'order_type',
  'status',
  'destination_id',
  'created_at',
  'created_by',
  'updated_at',
  'updated_by',
  'closed_at',
  'closed_by',
  'closed_status',
  'delivery_date',
  'is_complete',
  'rds_created_at',
  'adjusted_at',
  'approved_at',
  'received_at',
  'program_id',
  'supplier_id',
  'delivery_fee',
  'payment_choice',
  'route_id'
]

class DataOrderAdapter extends PGAdapter {
  constructor (pgConnection, username, logger) {
    super(
      pgConnection,
      DATA_ORDER_TABLE_NAME,
      username,
      DATA_ORDER_COLUMNS,
      'order_id',
      null,
      logger
    )
  }

  async getOrder ({
    orderId
  }) {
    if (!orderId) {
      throw new Error(`orderId is required`)
    }

    let query = `
      SELECT *
      FROM ${DATA_ORDER_TABLE_NAME}
      WHERE order_id = $1
    `
    const { rows } = await this.pgConnection.query(query, [orderId])
    return rows
  }

  // This is built specifically for the psm order export
  async listOrdersExport ({
    created_at__gte: greaterThan,
    created_at__lte: lessThan,
    search: destinationId,
    program_id__in: programs = [],
    funder_id__in: funders = [],
    ordering,
    limit,
    offset = 0
  }) {
    try {
      let query = `
        SELECT 
          o.*,
          json_agg(
            json_build_object(
              'sku', ol.sku,
              'original', ol.original,
              'adjusted', ol.adjusted,
              'approved', ol.approved,
              'received', ol.received,
              'allocation_type', ol.allocation_type,
              'comments_facility', ol.comments_facility,
              'comments_requester', ol.comments_requester,
              'comments_warehouse', ol.comments_warehouse
            )
          ) AS lines
        FROM avocado.data_order o
        INNER JOIN avocado.data_orderline ol -- We use inner join to exclude order rows without orderlines
          ON o.id = ol.order_id
        WHERE o.created_at >= $1
          AND o.created_at <= $2
      `

      const queryParams = [
        greaterThan,
        lessThan
      ]

      // Add destinationId (search) if provided
      if (destinationId) {
        query += ` AND destination_id ILIKE '%'||$${queryParams.length + 1}||'%'`
        queryParams.push(destinationId)
      }

      // Add programs filter if not empty
      if (programs && programs.length > 0) {
        query += ` AND program_id = ANY($${queryParams.length + 1}::text[])`
        queryParams.push(programs)
      }

      // Add funders filter if not empty
      if (funders && funders.length > 0) {
        query += ` AND funder_id = ANY($${queryParams.length + 1}::text[])`
        queryParams.push(funders)
      }

      // Add GROUP BY and ORDER BY clauses
      query += `
        GROUP BY 
          o.id, o.sub_order_id, o.group_id, o.funder_id, o.program_id, o.order_type, 
          o.status, o.destination_id, o.created_at, o.created_by, o.updated_at, 
          o.updated_by, o.closed_at, o.closed_by, o.closed_status, o.adjusted_at, 
          o.approved_at, o.received_at, o.delivery_date, o.is_complete
        ORDER BY o.destination_id
        LIMIT $${queryParams.length + 1} OFFSET $${queryParams.length + 2};
      `

      // Add limit and offset to the params array
      queryParams.push(limit, offset)

      // Execute the query
      const result = await this.pgConnection.query(query, queryParams)

      return {
        results: result.rows,
        next: result.rows.length === limit
          ? {
            created_at__gte: greaterThan,
            created_at__lte: lessThan,
            search: destinationId,
            program_id__in: programs,
            funder_id__in: funders,
            ordering,
            limit,
            offset: offset + limit
          }
          : null
      }
    } catch (err) {
      console.error('Error executing query:', err)
      throw err
    }
  }
}

module.exports = DataOrderAdapter
