const { moveShipmentStatus } = require('./move-shipment-status')
const updateSnapshot = require('../../../shipment/shipment-update-snapshot')
const { SUBORDER_STATUS } = require('../../constants')
const keyBy = require('lodash/keyBy')
const { parse } = require('../../../tools/smart-id')
const tools = require('../../tools')

exports.advanceShipmentSnapshot = async function (
  state,
  mainApi,
  { suborderId, productQuantities, additionalData = {}, receiptData = {}, shipmentNumber }
) {
  const { closedStatus: subOrderStatus } = receiptData

  // Validate product quantities
  if (!productQuantities) {
    throw createError('No `productQuantities` provided in the payload', 400)
  }

  // Validate product quantities
  if (!shipmentNumber) {
    throw createError('No `shipmentNumber` provided in the payload', 400)
  }

  // Validate suborder status
  if (!SUBORDER_STATUS[subOrderStatus]) {
    throw createError(`Invalid suborder status: "${subOrderStatus}"`, 400)
  }

  // Fetch suborder
  const subOrder = await state.dal.order.getSuborder(state, { suborderId })
  if (!subOrder) {
    throw createError(`No uncompleted suborder with id "${suborderId}" found`, 404)
  }

  // Fetch subOrder shipment snapshots
  const shipmentSnapshots = await state.dal.order.getShipmentsOnOrder(state, subOrder._id)
  const status = SUBORDER_STATUS[subOrderStatus]

  // Find existing snapshots for the shipment number
  const previousSnapshots = findShipmentSnapshotsByNumber(shipmentSnapshots, shipmentNumber)
    .sort((a, b) => new Date(a.createdAt).toJSON() - new Date(b.createdAt))

  const existingSnapshot = findSnapshotByStatus(previousSnapshots, status)

  // Fetch products and generate shipment counts
  const productIds = Object.keys(productQuantities)
  const allProducts = productIds.length ? await state.dal.product.getByIds(state, productIds) : []
  const productsById = keyBy(allProducts, '_id')
  const snapshotCount = tools.createShipmentCounts({ orderSnapshotProducts: productQuantities, productsById, useAmount: true })

  // Create or update shipment snapshot
  let snapshot
  if (existingSnapshot) {
    snapshot = await updateSnapshot(state, {
      ...existingSnapshot,
      counts: snapshotCount,
      snapshotId: existingSnapshot._id
    })
  } else {
    snapshot = await moveShipmentStatus(state, {
      suborderId,
      status,
      priorStateSnapshot: previousSnapshots[previousSnapshots.length - 1],
      shipmentCount: snapshotCount,
      shipmentNumber: null,
      externalShipmentId: null
    })
  }

  return { id: snapshot.id }
}

// Helper functions

function findShipmentSnapshotsByNumber (shipmentSnapshots, shipmentNumber) {
  const snapshots = shipmentSnapshots.filter(snapshot => snapshot.externalShipmentId === shipmentNumber && snapshot.type === 'snapshot')
  if (snapshots.length === 0) {
    throw createError(`Shipment ${shipmentNumber} does not exist`, 400)
  }
  return snapshots
}

function findSnapshotByStatus (shipmentSnapshots, status) {
  return shipmentSnapshots.find(snapshot => parse(snapshot._id).status === status && snapshot.type === 'snapshot')
}

function createError (message, status) {
  const error = new Error(message)
  error.status = status
  return error
}
